<template>
  <v-row class="home no-gutters">
    <v-col cols="12" class="class-fr-brdr reduce-padding">
      <div class="header">
        <div class="cinv-logo"></div>
        <div class="cinv-text">CINV Risk Assessment</div>
      </div>
      <div>
        <v-row no-gutters>
          <v-col lg="8" cols="12">
            <div class="rightsection">
              <h1 class="heading">CINV</h1>
              <div class="smalltext">
                Chemotherapy Induced Nausea and Vomiting
              </div>
              <p class="use-text">
                <b
                  >Updated per the 2023 MASCC/ESMO Guidelines, this tool
                  estimates your patient’s risk for CINV.</b
                >
              </p>
              <p class="prevented-text">
                CINV can be prevented with the correct antiemetic drug
                combination.
              </p>
              <div class="home-patient-md sm-screen"></div>
              <div class="checkboxes-sec cinv-checkbox">
                <input
                  @change="removeError"
                  type="checkbox"
                  name="heartDisease"
                  v-model="userData.declaration"
                  id="heartDisease"
                  value="heartDisease"
                />
                <label for="heartDisease">
                  <div class="checkbox-label">
                    I declare that I am a HealthCare professional.
                  </div>
                </label>
                <div class="valid-text">
                  <div v-if="errorFlag">
                    <i>User must check this box to continue.</i>
                  </div>
                </div>
              </div>
              <div class="button-div">
                <v-btn @click="start()" class="start-button"
                  ><b>Get Started</b></v-btn
                >
              </div>
            </div>
          </v-col>
          <v-col lg="4" cols="12">
            <div class="home-patient lg-screen"></div>
          </v-col>
        </v-row>
        <v-row no-gutters class="footer-area">
          <v-col cols="12" md="11" lg="10" class="reduce-padding">
            <div class="footer">
              <p class="footer-text">
                This educational tool is derived from a number of published
                references and is based on MASCC emetogenicity classification
                (Jordan, et al. 2017). The tool was developed from 1198 patients
                who received 4197 cycles of chemotherapy. It has undergone
                internal validation and it has to be prospectively validated in
                a new sample of patients. {{ "\xa0" }}The health information
                contained herein is provided for educational purposes only and
                is not meant to be a substitute for the advice of other
                healthcare professionals. All decisions regarding patient care
                must be made with a healthcare professional considering the
                unique characteristics of the patient.
                <a
                  @click="tagNavigation('footer','home')"
                  target="_blank"
                  class="footer-link"
                  href="https://www.sciencedirect.com/science/article/pii/S0923753419324299?via%3Dihub"
                >
                  https://academic.oup.com/annonc/article/28/6/1260/3112573
                </a>
              </p>
            </div>
          </v-col>
          <v-col cols="12" lg="2" class="reduce-padding">
            <div class="halftriangle"></div>
            <div class="hensinn-text">© {{ CurrYear }} Helsinn</div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable */
 import taggingMixin from '../mixins/taggingMixin';
 import scrollMixin from "../mixins/scrollMixin";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "home",
   mixins: [scrollMixin, taggingMixin],
  mounted: function () {
    this.scrollToTop();
    const d = new Date();
    this.CurrYear = d.getFullYear();
  },
  components: {},

  data() {
    return {
      errorFlag: false,
      CurrYear: 2022,
    };
  },
  computed: {
    ...mapGetters("userData", ["userData"]),
  },

  methods: {
    start: function () {
      if (this.userData.declaration == true) {
         this.tagNavigation('getStarted', 'home');
        this.$router.push("chemoEmtoLevel");
      } else {
        this.errorFlag = true;
      }
    },

    removeError: function () {

      if (this.userData.declaration) {
        this.errorFlag = false;
        this.tagNavigation('declare', 'home');
      }
    },
  },
};
</script>

<style style lang="scss" scoped>
@import "../assets/styles/_variables.scss";

.home {
  margin: 0;
  font-family: "Arial Narrow", Arial, sans-serif !important;

  .v-label {
    font-size: 22px;
    font-family: "Arial Narrow", Arial, sans-serif !important;
  }
  #cinv4 .tool-border {
    height: auto !important;
  }

  .col-8 {
    width: 60% !important;
    max-width: 60% !important;
    padding-right: 0 !important;
  }

  .col-4 {
    width: 40% !important;
    max-width: 40% !important;
    padding-left: 0 !important;
  }

  .img-setting {
    width: 121%;
    height: 469px;
  }

  #hide-for-desktop {
    display: none !important;
  }
  #hide-for-mble {
    display: block;
  }

  .header {
    background-color: #01619c;
    background-image: url("../images/icon-svg/top-gradient.svg");
    color: white;
    font-size: 44px;
    display: flex;
    justify-content: center;
    padding: 18px;
    height: 100px;
    width: 100%;
    font-weight: bold;
    font-style: italic;
    font-family: "Arial Narrow", Arial, sans-serif !important;
    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.5);
    @media (max-width: $screen-md-max) {
      font-size: 40px;
      display: flex;
      justify-content: center;
      padding: 7px 0 0 0;
      height: auto;
    }
    @media (max-width: $screen-sm-max) {
      background-image: none;

      padding: 20px 0 19px;
      font-size: 26px;
    }
  }
  .cinv-text {
    font-family: "Arial Narrow", Arial, sans-serif !important;
    font-style: italic;
    padding-left: 20px;
    @media (max-width: $screen-md-max) {
      padding-left: 11px;
    }

    @media (max-width: $screen-sm-max) {
      width: 168px;
      line-height: 32px;
    }
  }
  .rightsection {
    background-color: #0078ae;
    padding: 16px 40px 20px;
    height: 460px;
    font-size: 24px;
    color: white;
    text-align: center;
    @media (min-width: $screen-lg-min) {
      max-width: 569px;
    }
    @media (max-width: $screen-md-max) {
      height: auto;
    }
    @media (max-width: $screen-sm-max) {
      padding: 35px 15px 20px 15px;
    }
    @media (max-width: $screen-xs-max) {
      padding: 27px 8px 26px 8px !important;
    }
  }
  .use-text {
    line-height: 32px;
    margin: 9px auto 0 auto;
    @media (max-width: $screen-xs-max) {
      line-height: 30px;
    }
  }
  .prevented-text {
    margin: 0 10px;
    line-height: 32px;
    @media (max-width: $screen-xs-max) {
      line-height: 30px;
      margin: 20px 0 0;
    }
  }
  .checkbox-div {
    margin-top: 25px;
  }

  .heading {
    font-size: 58px;
    display: inline-block;
    margin: 0;
  }
  .start-button,
  .start-button:focus,
  .start-button:hover,
  .start-button:active {
    background-color: #ffffff !important;
    color: #0078ae !important;
    margin: auto;
    border-radius: 6px;
    width: 261px;
    height: 67px;
    font-size: 31px;
    outline: 0 !important;
    border: 2px solid #7fbbd6 !important;
    box-shadow: none !important;
    cursor: pointer;
    text-transform: none;
    letter-spacing: 0px;
  }

  .button-div {
    margin-top: 22px;
    @media (max-width: $screen-md-max) {
      margin-bottom: 25px;
    }
    @media (max-width: $screen-xs-max) {
      text-align: center;
    }
  }

  .text {
    font-family: Arialnarrow;
    padding: 19px;
  }

  .smalltext {
    font-size: 22px;
    display: inline-block;
    width: 248px;
    line-height: 26.4px;
    text-align: left;
    padding-left: 18px;
    @media (max-width: $screen-xs-max) {
      width: 190px;
      font-size: 20px;
    }
  }
  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    background-color: #ffffff;
    border-radius: 5px;
    display: inline-block;
    border: 2px solid #7fbbd6;
  }
  .declare {
    display: inline-block;
    width: 400px;
    display: inline-box;
    padding: 1px;
    vertical-align: middle;
    margin-top: 10px;
    font-size: 22px;
    margin-left: 20px;
    text-align: left;
  }

  .home-patient {
    background-image: url("../images/icons/home_patient.webp");
    height: 460px;
    background-repeat: no-repeat;
    width: 392px;
    float: right;
    margin-right: 0;
    background-size: contain;
  }

  .home-patient-md {
    background-image: url("../images/icons/home_patient_md.webp");
    height: 284px;
    background-repeat: no-repeat;
    width: 340px;
    float: none;
    margin: 24px auto 24px auto;
    background-size: contain;
    @media (max-width: $screen-xs-max) {
      height: 242px;
      width: 290px;
    }
  }

  .sm-screen {
    display: none;
    @media (max-width: $screen-md-max) {
      display: block;
    }
  }

  .lg-screen {
    display: inline-block;
    @media (max-width: $screen-md-max) {
      display: none;
    }
  }

  .halftriangle {
    background-image: url("../images/icons/triangle1.png");
    height: 194px;
    width: 255px;
    margin: -86px 0 auto auto;
    float: right;

    @media (max-width: $screen-md-max) {
      margin: -188px 0 auto auto;
    }
    @media (max-width: $screen-sm-max) {
      height: 168px;
      width: 245px;
      margin: -166px 0 auto auto;
    }

    @media (max-width: $screen-xs-max) {
      height: 148px;
      width: 225px;
      margin: -146px 0 auto auto;
    }
  }
  .footer-text {
    padding: 24px 40px 24px 27px !important;
    margin-right: 20px !important;
    margin-bottom: 0 !important;
    @media (max-width: $screen-md-max) {
      padding-right: 68px !important;
    }
    @media (max-width: $screen-sm-max) {
    margin-right: 0 !important;
      padding: 17px 130px 18px 10px !important;
    }

    @media (max-width: $screen-xs-max) {
      padding: 17px 97px 18px 10px !important;
    }

    @media (max-width: 355px) {
      padding: 17px 67px 18px 10px !important;
    }
  }

  .footer-area {
    background-color: #ebecec;
  }
  .footer {
    background-color: #ebecec;
    line-height: 15px;
    font-size: 11px;
  }
  .footer-link,
  .footer-link:focus,
  .footer-link:hover,
  .footer-link:active {
    color: $textPrimary !important;
    cursor: pointer;
    text-decoration: underline;
  }
}


.hensinn-text {
    margin: -40px 28px auto auto;
    font-size: 14px;
    text-align: right;
    float: right;

    @media (max-width: $screen-md-max) {
      margin: -23px -231px auto auto;
    }

    @media (max-width: $screen-xs-max) {
      margin: -20px -219px auto auto;
    }
  }
  .valid-text {
    height: 25px;
    font-size: 17px;
    color: yellow;
    margin-left: 88px;
    margin-top: -5px;
    position: relative;
    display: block;
    padding: 0;
    text-align: left;
    line-height: 1;
    font-weight: 100;
    @media (max-width: $screen-md-max) {
      margin-left: 178px;
    }
    @media (max-width: $screen-sm-max) {
      margin-right: 0;
      text-align: left;
      margin-left: 106px;
    }
    @media (max-width: $screen-xs-max) {
      text-align: left !important;
      margin: 6px 0 0 53px;
    }
  }
.cinv-logo {
    background-image: url("../images/icons/logo.png");
    width: 73px;
    height: 69px;
    margin-left: 10px;
    @media (max-width: $screen-sm-max) {
      margin-top: -5px;
    }
  }
  .user .hover {
    width: 960px;
    border: 1px solid #ffffff;
  }

  .border-classh .col-12,
  .class-fr-brdr {
    border: 1px solid #000;
    padding: 0;
  }

  .header {
    border-bottom: 1px solid #fff !important;
  }

  .btn.get-strted {
    margin-top: -15px !important;
  }


input[type="checkbox"] {
  display: none !important;
  opacity: 0;
}
.checkboxes-sec {
  margin-top: 28px;
}

.cinv-checkbox {
}
.checkbox-label {
  display: table-cell;
  padding-left: 20px;
  vertical-align: middle;
  font-size: 22px;
  @media (max-width: $screen-xs-max) {
    padding-left: 9px;
    font-size: 20px;
    text-align: left !important;
  }
}

label {
  cursor: pointer;
  display: block;
  line-height: 1.7;
  margin-bottom: 0;
  position: relative;
  max-height: 45px;
  padding-left: 25px;
  @media (max-width: $screen-md-max) {
    font-size: 22px;
    padding-left: 116px;
  }
  @media (max-width: $screen-sm-max) {
    height: 41px;
    padding-left: 40px;
  }
  @media (max-width: $screen-xs-max) {
    padding-left: 0;
    display: table;
    line-height: 1.1;
  }
}

input[type="checkbox" i] {
  display: none !important;
}
label::before {
  content: "";
  background-image: url("../images/icon-svg/checkbox-unchecked.svg");
  display: table-cell;
  height: 45px;
  width: 45px;
  background-repeat: no-repeat;
  vertical-align: middle;
  @media (max-width: $screen-sm-max) {
    height: 45px;
    width: 45px;
  }
  @media (max-width: $screen-xs-max) {
    height: 45px;
    width: 45px;
  }
}

input[type="checkbox"]:checked + label:before {
  content: "";
  background-image: url("../images/icon-svg/checkbox-checked.svg");
  line-height: 15px;
  text-align: center;
  height: 45px;
  width: 45px;
  background-repeat: no-repeat;
  @media (max-width: $screen-sm-max) {
    height: 45px;
    width: 45px;
  }
}
</style>
