class UserData {
  constructor() {
    this.namespaced = true;
    this.state = {
      userData: {
          chemoType: '',
          selectedMedicines: [],
          currResultsIndex: -1,
          prevList: [],
          declaration: false,
          testme: 'sdsasdas',
          highestPriority: 0,
          isEmailSent: false,
          showInfoModal: false,
          chemoDays: null,
          riskProfile: {
            gender: '',
            age: '',
            cinvDevelopmentRisk:'',
            morningSickness: '',
            sleepTime: '',
            nonPrescribedDrugs: '',
            nauseaVomiting: '',
            platinumBased: '',
          },
          risk: 0,
          emetoLevel: 1,
          drugList: '',
          priorityDial: 0,
      }
    };
    this.getters = {
      userData: state => {
        return state.userData;
      },
    };
    this.mutations = {
    };
    this.actions = {
    };
  }
}

export default UserData;
