/* eslint-disable */

export default {
  data() {
      return {
          tool: {
              'home': {
                  pageTitle: 'Launch',
                  actions: {
                          getStarted: {
                              name: 'Get Started',
                              event: 'e_navigation'
                          },
                          declare: {
                              name: 'I declare that I am a HealthCare professional',
                              event: 'e_navigation'
                          },
                          footer: {
                              name: 'Footer Link',
                              event: 'e_navigation'
                          }
                      }

              },

              'chemoEmtoLevel': {
                  pageTitle: 'Chemotherapy Emetogenicity Level',
                  actions: {
                      back: {
                          name: 'BACK',
                          event: 'e_navigation'
                      },
                      next: {
                          name: 'NEXT',
                          event: 'e_navigation'
                      },
                      info: {
                          name: 'More Information',
                          event: 'e_navigation'
                      },
                      chemoType: {
                          name: 'Type of Chemotherapy',
                          event:'e_userdata'
                      },
                      cycle: {
                          name: 'Current Cycle Number',
                          event:'e_userdata'
                      },
                      del: {
                          name: 'Remove Drug Icon',
                          event: 'e_navigation'
                      },
                      mascc: {
                          name: 'MASCC/ESMO Link',
                          event: 'e_navigation'
                      },
                      nccn: {
                          name: 'NCCN Link',
                          event: 'e_navigation'
                      },
                      asco: {
                          name: 'ASCO Link',
                          event: 'e_navigation'
                      },
                  }
              },


              'riskProfile': {
                  pageTitle: 'Patient Emetogenicity Risk Profile',
                  actions: {
                      back: {
                          name: 'BACK',
                          event: 'e_navigation'
                      },
                      next: {
                          name: 'NEXT',
                          event: 'e_navigation'
                      },
                      moreinfo: {
                          name: 'More Information',
                          event: 'e_navigation'
                      },
                      age: {
                          name: 'Age',
                          event:'e_userdata'
                      },
                      gender: {
                          name: 'Gender',
                          event:'e_userdata'
                      },
                      expect: {
                          name: 'Expect to develop CINV',
                          event:'e_userdata'
                      },
                      sickness: {
                          name: 'Morning sickness',
                          event:'e_userdata'
                      },
                      sleep: {
                          name: '7 or more hours sleep',
                          event:'e_userdata'
                      },
                      antiemetics: {
                          name: 'Non-prescribed antiemetics',
                          event:'e_userdata'
                      },
                      prior: {
                          name: 'Nausea or a vomiting episode in the prior cycle',
                          event:'e_userdata'
                      },
                      anthracycline: {
                          name: 'Anthracycline or Platinum based chemotherapy',
                          event:'e_userdata'
                      },
                      mascc: {
                          name: 'MASCC/ESMO Link',
                          event: 'e_navigation'
                      },
                      nccn: {
                          name: 'NCCN Link',
                          event: 'e_navigation'
                      },
                      asco: {
                          name: 'ASCO Link',
                          event: 'e_navigation'
                      },
                      antiemeticsIcon: {
                          name: 'Non-prescribed Antiemetics Pop-up',
                          event:'e_navigation'
                      },
                  }
              },

              'antiemetics': {
                  pageTitle: 'Non-Prescription Antiemetics',
                  actions: {
                      close: {
                          name: 'Close icon',
                          event: 'e_navigation'
                      },
                  }
              },

              'recommendation': {
                  pageTitle: 'Recommendation',
                  actions: {
                      back: {
                          name: 'BACK',
                          event: 'e_navigation'
                      },
                      next: {
                          name: 'NEXT',
                          event: 'e_navigation'
                      },
                      info: {
                          name: 'More Information',
                          event: 'e_navigation'
                      },
                      emet: {
                          name: 'Chemotherapy Emetogenicity Level',
                          event:'e_results'
                      },
                      risk: {
                          name: 'Patient Emetogenicity Risk %',
                          event:'e_results'
                      },
                      riskLevel: {
                          name: 'Patient Emetogenicity Risk Level',
                          event:'e_results'
                      },
                      emetRecom: {
                          name: 'Recommendation based on Emetogenicity Level',
                          event:'e_results'
                      },
                      riskRecom: {
                          name: 'Recommendation based on Risk %',
                          event:'e_results'
                      },
                      mascc: {
                          name: 'MASCC/ESMO Link',
                          event: 'e_navigation'
                      },
                      nccn: {
                          name: 'NCCN Link',
                          event: 'e_navigation'
                      },
                      asco: {
                          name: 'ASCO Link',
                          event: 'e_navigation'
                      },
                  }
              },


              'patientsReport': {
                  pageTitle: 'Patient’s Report',
                  actions: {
                      back: {
                          name: 'BACK',
                          event: 'e_navigation'
                      },
                      new: {
                          name: 'NEW PATIENT',
                          event: 'e_navigation'
                      },
                      exit: {
                          name: 'EXIT',
                          event: 'e_navigation'
                      },
                      info: {
                          name: 'More Information',
                          event: 'e_navigation'
                      },
                      print: {
                          name: 'View or Print Report',
                          event:'e_report'
                      },
                      save: {
                          name: 'Save PDF',
                          event:'e_report'
                      },
                      email: {
                          name: 'Email PDF',
                          event:'e_navigation'
                      },

                  }
              },

              'moreInfo': {
                 pageTitle: 'More Information',
                  actions:
                      {
                          back: {
                          name: 'BACK',
                          event: 'e_navigation'
                          },
                          mascc: {
                          name: 'MASCC/ESMO Link',
                          event: 'e_navigation'
                          },
                      },

              },
              'email': {
                 pageTitle: 'Email Your Patient’s Summary',
                  actions:
                      {
                        back: {
                          name: 'BACK',
                          event: 'e_navigation'
                        },
                        submit: {
                          name: 'SUBMIT - Email PDF',
                          event: 'e_report'
                        },
                       continue: {
                          name: 'CONTINUE',
                          event: 'e_navigation'
                        },
                      },
              },
          }
      };
  },

  methods: {
    tagNavigation: function(action, screen) {
      if (this.tool[screen] !== undefined) {
      dataLayer.push({
                'event' : this.tool[screen].actions[action].event,
                'screen' : this.tool[screen].pageTitle,
                'buttonText' : this.tool[screen].actions[action].name,
                });

      }
    },


    tagUserInput: function(action, screen, value) {
      if (this.tool[screen] !== undefined) {
      dataLayer.push({
                'event' : this.tool[screen].actions[action].event,
                'screen' : this.tool[screen].pageTitle,
                'DataField' : this.tool[screen].actions[action].name,
                'DataValue' : value,
                });

      }
    },

    tagPageViews: function(url, path) {
        var screen = path.split("/")[1];
        screen = screen==undefined || screen==""? "home" : screen;
       dataLayer.push({
           'event': 'pageview',
           'page_title': this.tool[screen].pageTitle,
           'page_location': url.replace(/#.*$/, '')+'#/'+ path.split("/")[1],
                });

    },

    chemoHighRisk: function(chemo, riskProfile, riskPercentage) {
      // DataLayer push for chemo risk
      dataLayer.push({
      'event' : 'event_chemo_highRisk',
      'chemo' : chemo,
      'riskProfile' : riskProfile,
      'riskPercentage' : riskPercentage,
      });
  },

  chemoRiskVsPatientRisk: function(chemoPerc, riskProfileText, riskPercentage) {
      // DataLayer push for patient risk
      dataLayer.push({
      'event' : 'event_chemo_risk_vs_patient_risk',
      'ChemoRiskPercentage' : chemoPerc,
      'PatientRisk' : riskProfileText,
      'PatientRiskPercentage' : riskPercentage,
      });
   },

  }
};
