<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <v-row class="cancel-btn-area" no-gutters>
            <v-col class="info-section div-lg" cols="10">
              <div class="top-section">
                <div class="info-btn">
                  <div class="info-logo"></div>
                </div>

                <div class="heading-txt-outer-div">
                  <div class="heading-text">Non-Prescription Antiemetics</div>
                </div>
              </div>
            </v-col>
            <v-col class="div-lg" cols="2">
              <div class="cancel-btn" @click="closeDiscussions">
                <div class="cancel-img"></div>
              </div>
            </v-col>

            <v-col class="div-xs" cols="10"> </v-col>
            <v-col class="div-xs" cols="2">
              <div class="cancel-btn" @click="closeDiscussions">
                <div class="cancel-img"></div>
              </div>
            </v-col>

            <v-col class="info-section div-xs" cols="12">
              <div class="top-section">
                <div class="info-btn">
                  <div class="info-logo"></div>
                </div>

                <div class="heading-txt-outer-div">
                  <div class="heading-text">Non-Prescription Antiemetics</div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <div class="description-text">
              This question relates to patient self-medication once they leave
              the clinic. <span class="double-spacing"></span>Examples of
              non-prescription antiemetics include dimenhydrinate, herbal
              remedies, ginger, Pepto Bismol<sup>®</sup> or other.
            </div>
          </v-row>
        </div>
      </div>
    </div>
  </transition>
</template>
  <script>
/* eslint-disable */

import taggingMixin from "../mixins/taggingMixin";
import scrollMixin from "../mixins/scrollMixin";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "showInfoModal",
  components: {},
  mixins: [scrollMixin, taggingMixin],
  mounted: function () {
    this.scrollToTop();
    this.tagNavigation("antiemeticsIcon", "riskProfile");
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters("userData", ["userData"]),
  },

  created() {},

  methods: {
    ...mapMutations("userData", ["saveUserData"]),

    closeDiscussions: function () {
      this.tagNavigation("close", "antiemetics");
      this.userData.showInfoModal = false;
    },
  },
};
</script>

  <style lang="scss" >
@import "../assets/styles/_variables.scss";

.modal-container {
  font-family: "Arial Narrow", arial, serif;
  width: 695px !important;
  height: 215px !important;
  border: 1px solid #0078ae !important;
  border-radius: 5px !important;
  padding: 0 !important;
  background-color: #fff;
  margin: auto;
  @media (max-width: $screen-sm-max) {
    width: 471px !important;
    height: auto !important;
  }
  @media (max-width: $screen-xs-max) {
    width: auto !important;
    height: auto !important;
    margin: 0px 20px !important;
  }

  .cancel-btn-area {
  }

  .cancel-img {
    background-image: url("../images/icons/Asset-5.png");
    background-repeat: no-repeat;
    cursor: pointer;
    height: 40px;
    width: 21px;
    margin: 6px auto;
    @media (max-width: $screen-xs-max) {
      margin: 3px auto;
    }
  }
  .cancel-btn {
    background-color: #0078ae;
    width: 56px;
    height: 56px;
    text-align: center;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #0078ae;
    float: right;
    @media (max-width: $screen-xs-max) {
      width: 50px;
      height: 50px;
    }
  }
  .info-logo {
    background-image: url("../images/icon-svg/info-logo-prescribe-box.svg");
    background-repeat: no-repeat;
    cursor: pointer;
    height: 50px;
    width: 50px;
    @media (max-width: $screen-sm-max) {
      height: 44px;
      width: 44px;
    }
    @media (max-width: $screen-xs-max) {
      height: 32px;
      width: 32px;
    }
  }
  .heading-text {
    font-size: 30px;
    color: #0078ae;
    font-weight: 700;
    @media (max-width: $screen-sm-max) {
      font-size: 25px;
    }
    @media (max-width: $screen-xs-max) {
      font-size: 20px;
    }
  }
  .heading-txt-outer-div {
    display: inline-block;
    margin: 4px 19px 0px;
    vertical-align: top;

    @media (max-width: $screen-sm-max) {
      margin: 4px 0px 0px 8px;
    }

    @media (max-width: $screen-xs-max) {
      margin: 1px 0px 0px 8px;
    }
  }
  .info-btn {
    display: inline-block;
  }
  .info-section {
    margin-top: 25px;
  }
  .div-lg {
    display: block;

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  .div-xs {
    display: none;

    @media (max-width: $screen-xs-max) {
      display: block;
    }
  }
  .top-section {
    padding-left: 117px;
    @media (max-width: $screen-sm-max) {
      padding-left: 15px;
    }
    @media (max-width: $screen-xs-max) {
      padding-left: 8px;
    }
  }
  .description-text {
    font-size: 20px;
    color: #000000;
    line-height: 1.2;
    margin: 26px 34px;
    text-align: center;
    @media (max-width: $screen-sm-max) {
      margin: 15px 15px 26px;
    }
    @media (max-width: $screen-xs-max) {
      font-size: 18px;
    margin: 15px 8px 26px;
    }
  }
  .double-spacing {
    margin-left: 3px;
  }
}
</style>
