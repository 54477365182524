<template>
  <v-row class="risk-dial" no-gutters>
    <v-col cols="12">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 305 157" style="enable-background:new 0 0 305 157;" xml:space="preserve">

        <!--*********** DIAL ***********-->
        <g id="dial">

          <!--*********** DIAL SLICES ***********-->
          <path id="red" style="opacity:0.35;fill:#C1272D;" d="M131.08,146.73l128.54,0.26c0,0,1.86-23.44-14.51-60L131.08,146.73z"/>
          <path id="orange" style="opacity:0.35;fill:#E5AC00;" d="M51.41,42.16l79.17,105.43l113.1-58.93
          C234.07,61.5,154.81-26.2,51.41,42.16z"/>
          <path id="yellow" style="opacity:0.35;fill:#FFFF00;" d="M11.59,105.57l120.15,41.26L54.85,46.72
          C54.85,46.72,23.61,62.27,11.59,105.57z"/>
          <path id="green" style="opacity:0.35;fill:#8CC63F;" d="M132.2,146.99L9.27,103.84c0,0-7.62,29.97-2.75,43.15h125.92"/>

          <!--*********** SELECTED DIAL SLICES ***********-->
          <!--<g v-if="risk==1">-->
          <!--  <path id="minimal_highlighted" style="opacity:0.35;fill:#8CC63F;" d="M132.54,146.63L9.61,103.84c0,0-7.62,29.72-2.75,42.79h125.92"/>-->
          <!--</g>-->
          <g v-if="risk==1" id="minimal">
            <path id="minimal_highlighted" style="opacity:0.35;fill:#8CC63F;" d="M132.54,146.63L9.61,103.84c0,0-7.62,29.72-2.75,42.79h125.92"/>
          </g>

          <g v-if="risk==2" id="low">
            <path id="low_highlighted" style="opacity:0.35;fill:#FFFF00;" d="M10.93,104.21l120.15,41.26L54.19,45.36
            C54.19,45.36,22.96,60.91,10.93,104.21z"/>
          </g>

          <g v-if="risk==3 || risk==4" id="moderate">
            <path id="moderate_highted" style="opacity:0.35;fill:#E5AC00;" d="M50.9,41.2l80.08,105.43l114.4-58.93
            C235.66,60.54,155.49-27.15,50.9,41.2z"/>
          </g>

          <g v-if="risk==5" id="high">
            <path id="high_highlighted" style="opacity:0.35;fill:#C1272D;" d="M131.42,146.83l128.54,0.26c0,0,1.86-23.44-14.51-60
            L131.42,146.83z"/>
          </g>

          <!--*********** DIAL NUMBERS ***********-->
          <g id="numbers">
            <path d="M14.73,136.1c0.1-1.04,0.7-1.83,1.8-2.38s2.93-0.68,5.51-0.43c2.57,0.26,4.34,0.76,5.31,1.5c0.97,0.75,1.4,1.64,1.3,2.67
            c-0.1,1.04-0.7,1.84-1.79,2.38c-1.09,0.54-2.93,0.68-5.54,0.42c-2.56-0.25-4.32-0.76-5.29-1.5
            C15.06,138.03,14.63,137.13,14.73,136.1z M16.9,136.31c-0.04,0.41,0.21,0.76,0.74,1.06s1.84,0.55,3.92,0.76
            c2.08,0.21,3.41,0.22,3.99,0.03c0.58-0.19,0.89-0.48,0.93-0.89c0.04-0.41-0.21-0.77-0.74-1.06c-0.53-0.29-1.84-0.54-3.91-0.75
            c-2.08-0.21-3.41-0.22-3.99-0.04C17.25,135.6,16.94,135.9,16.9,136.31z"/>
            <path d="M33.36,115.26l-0.65,1.96l-9.39-3.13c0.61,1.02,0.96,2.08,1.04,3.17l-2.26-0.75c-0.05-0.62-0.27-1.33-0.67-2.11
            c-0.4-0.78-0.94-1.42-1.64-1.9l0.53-1.59L33.36,115.26z"/>
            <path d="M23.13,103.78c0.42-0.96,1.23-1.54,2.43-1.72c1.21-0.18,3,0.24,5.38,1.26c2.37,1.02,3.91,2.04,4.6,3.04
            c0.7,1,0.84,1.99,0.42,2.94c-0.42,0.96-1.22,1.54-2.42,1.72c-1.2,0.19-3-0.24-5.41-1.28c-2.36-1.02-3.89-2.03-4.58-3.04
            C22.85,105.72,22.71,104.73,23.13,103.78z M25.13,104.64c-0.16,0.38-0.03,0.79,0.38,1.23c0.42,0.45,1.59,1.08,3.51,1.91
            c1.92,0.83,3.18,1.24,3.79,1.24c0.61,0,0.99-0.19,1.15-0.57c0.16-0.38,0.04-0.79-0.38-1.23s-1.59-1.08-3.5-1.9
            c-1.92-0.83-3.18-1.24-3.79-1.24C25.68,104.07,25.29,104.26,25.13,104.64z"/>
            <path d="M46.09,86.66l1.99,1.41l-4.2,5.92c-0.71-0.6-1.28-1.3-1.73-2.1c-0.45-0.81-0.89-2.07-1.32-3.79
            c-0.35-1.37-0.62-2.24-0.81-2.63c-0.27-0.58-0.64-1.02-1.09-1.35c-0.49-0.35-0.93-0.5-1.33-0.47s-0.72,0.22-0.96,0.56
            c-0.53,0.75-0.24,1.56,0.89,2.45l-1.4,1.53c-1.06-0.91-1.69-1.81-1.86-2.69c-0.17-0.88,0.04-1.75,0.63-2.59
            c0.66-0.92,1.46-1.4,2.42-1.42c0.96-0.03,1.84,0.25,2.65,0.82c0.46,0.32,0.86,0.7,1.2,1.12c0.34,0.42,0.64,0.91,0.9,1.47
            s0.52,1.37,0.78,2.43c0.25,0.99,0.42,1.63,0.52,1.92s0.22,0.54,0.35,0.77L46.09,86.66z"/>
            <path d="M40.6,75.5c0.67-0.8,1.62-1.12,2.83-0.95c1.21,0.17,2.81,1.09,4.79,2.75c1.98,1.66,3.16,3.07,3.54,4.23
            c0.38,1.16,0.23,2.14-0.44,2.94c-0.67,0.8-1.61,1.12-2.81,0.96c-1.2-0.16-2.81-1.09-4.82-2.77c-1.97-1.65-3.14-3.06-3.52-4.22
            C39.79,77.27,39.94,76.3,40.6,75.5z M42.28,76.89c-0.26,0.31-0.26,0.74,0.01,1.29c0.27,0.55,1.21,1.49,2.81,2.84
            s2.69,2.1,3.28,2.27c0.58,0.17,1.01,0.1,1.27-0.21c0.27-0.32,0.26-0.75-0.01-1.29c-0.27-0.54-1.21-1.49-2.81-2.83
            c-1.6-1.34-2.69-2.1-3.28-2.28C42.97,76.5,42.54,76.57,42.28,76.89z"/>
            <path d="M61.15,69.03l1.37-1.49c0.44,0.47,0.89,0.75,1.32,0.84c0.44,0.09,0.81,0.01,1.12-0.23c0.33-0.27,0.49-0.65,0.47-1.15
            c-0.02-0.5-0.26-1.03-0.7-1.59c-0.42-0.52-0.85-0.84-1.31-0.96c-0.46-0.12-0.84-0.06-1.16,0.19c-0.21,0.16-0.41,0.41-0.62,0.74
            l-1.16-1.81c0.43-0.32,0.67-0.71,0.72-1.14c0.05-0.43-0.11-0.87-0.46-1.31c-0.29-0.37-0.61-0.59-0.95-0.66
            c-0.34-0.07-0.65,0-0.92,0.22c-0.27,0.22-0.41,0.53-0.42,0.93c-0.01,0.4,0.16,0.85,0.5,1.35l-1.75,0.87
            c-0.62-1.1-0.89-2.06-0.79-2.86c0.1-0.8,0.49-1.48,1.17-2.02c0.77-0.61,1.61-0.81,2.51-0.61c0.91,0.21,1.63,0.66,2.19,1.35
            c0.37,0.47,0.6,0.97,0.7,1.51c0.1,0.54,0.05,1.11-0.13,1.72c0.58-0.25,1.2-0.26,1.85-0.05c0.66,0.21,1.25,0.65,1.79,1.33
            c0.78,0.98,1.15,2.01,1.1,3.1c-0.05,1.09-0.46,1.94-1.24,2.56c-0.74,0.59-1.58,0.82-2.53,0.69C62.86,70.4,61.97,69.9,61.15,69.03z
            "/>
            <path d="M65.22,53.32c0.87-0.59,1.86-0.63,2.98-0.13c1.12,0.5,2.4,1.82,3.85,3.96c1.45,2.14,2.19,3.82,2.24,5.04
            c0.04,1.22-0.37,2.12-1.23,2.71c-0.87,0.59-1.86,0.64-2.97,0.15c-1.11-0.49-2.4-1.82-3.87-3.99c-1.44-2.13-2.18-3.81-2.22-5.03
            C63.95,54.8,64.36,53.9,65.22,53.32z M66.45,55.12c-0.34,0.23-0.45,0.64-0.34,1.24c0.11,0.6,0.75,1.77,1.92,3.5
            c1.17,1.73,2.01,2.76,2.53,3.09c0.51,0.33,0.94,0.37,1.28,0.14c0.34-0.23,0.46-0.65,0.34-1.25c-0.11-0.6-0.76-1.76-1.92-3.49
            c-1.17-1.73-2.01-2.76-2.52-3.09C67.22,54.93,66.79,54.88,66.45,55.12z"/>
            <path d="M91.92,54.5l-1.06-2.54l-4.08,1.7l-0.89-2.12l0.97-9.82l1.61-0.67l3.35,8.01l1.23-0.52l0.89,2.13l-1.23,0.52l1.06,2.54
            L91.92,54.5z M89.97,49.82l-1.8-4.32l-0.48,5.27L89.97,49.82z"/>
            <path d="M94.92,38.75c1-0.33,1.96-0.1,2.9,0.69c0.94,0.78,1.81,2.41,2.62,4.87c0.8,2.46,1.06,4.28,0.77,5.46
            c-0.29,1.19-0.93,1.94-1.92,2.27c-1,0.33-1.96,0.1-2.9-0.67c-0.93-0.77-1.81-2.41-2.63-4.9c-0.8-2.44-1.06-4.26-0.76-5.44
            C93.29,39.83,93.93,39.07,94.92,38.75z M95.6,40.82c-0.39,0.13-0.61,0.5-0.67,1.1c-0.06,0.61,0.24,1.91,0.89,3.89
            c0.65,1.99,1.18,3.21,1.58,3.66c0.4,0.45,0.8,0.62,1.19,0.49c0.39-0.13,0.62-0.5,0.67-1.1c0.05-0.61-0.24-1.9-0.89-3.88
            c-0.65-1.99-1.18-3.21-1.58-3.67C96.39,40.85,96,40.69,95.6,40.82z"/>
            <path d="M115.91,44.18l2.02-0.52c0.12,0.59,0.35,1.04,0.69,1.34c0.34,0.31,0.68,0.44,1.04,0.4c0.42-0.05,0.76-0.31,1.01-0.78
            c0.25-0.47,0.33-1.16,0.22-2.06c-0.1-0.85-0.32-1.46-0.67-1.84s-0.75-0.53-1.22-0.48c-0.59,0.07-1.08,0.46-1.48,1.18l-1.71-0.11
            l0.23-7.2l5.43-0.63l0.28,2.44l-3.87,0.45l-0.07,2.35c0.43-0.34,0.88-0.54,1.36-0.6c0.85-0.1,1.6,0.17,2.27,0.79
            c0.85,0.8,1.35,1.92,1.52,3.37c0.14,1.19-0.05,2.33-0.57,3.41c-0.52,1.08-1.37,1.68-2.54,1.82c-0.95,0.11-1.78-0.12-2.5-0.69
            C116.67,46.24,116.18,45.36,115.91,44.18z"/>
            <path d="M127.55,32.92c1.05-0.04,1.91,0.45,2.6,1.46c0.68,1.01,1.07,2.81,1.17,5.4c0.09,2.58-0.16,4.4-0.77,5.46
            c-0.61,1.06-1.43,1.61-2.48,1.65c-1.05,0.04-1.91-0.44-2.6-1.45c-0.68-1-1.07-2.81-1.17-5.43c-0.09-2.57,0.17-4.38,0.77-5.44
            C125.68,33.5,126.51,32.95,127.55,32.92z M127.63,35.09c-0.41,0.01-0.72,0.31-0.95,0.88c-0.22,0.57-0.3,1.9-0.22,3.99
            c0.08,2.09,0.24,3.41,0.51,3.96c0.26,0.55,0.6,0.81,1.01,0.8c0.41-0.01,0.73-0.31,0.95-0.88s0.29-1.89,0.22-3.98
            c-0.07-2.09-0.24-3.41-0.5-3.96C128.38,35.34,128.05,35.08,127.63,35.09z"/>
            <path d="M154.2,38.34l-2.02-0.05c0.07-1.02-0.24-1.58-0.92-1.7c-0.44-0.07-0.86,0.12-1.23,0.57c-0.38,0.46-0.73,1.44-1.06,2.96
            c0.32-0.34,0.65-0.58,0.99-0.72c0.34-0.14,0.71-0.18,1.09-0.11c0.83,0.14,1.5,0.66,1.99,1.57c0.49,0.91,0.62,2.03,0.4,3.37
            c-0.23,1.43-0.75,2.49-1.54,3.19c-0.79,0.7-1.67,0.97-2.64,0.81c-1.06-0.18-1.86-0.85-2.39-2.02c-0.53-1.17-0.59-2.97-0.19-5.41
            c0.41-2.48,1.07-4.2,1.97-5.16c0.91-0.97,1.93-1.36,3.07-1.17c0.79,0.13,1.4,0.52,1.85,1.16
            C154.04,36.29,154.24,37.19,154.2,38.34z M148.64,43.24c-0.14,0.85-0.09,1.53,0.14,2.03c0.23,0.5,0.54,0.78,0.93,0.85
            c0.38,0.06,0.73-0.07,1.04-0.41c0.31-0.33,0.54-0.93,0.68-1.78c0.15-0.88,0.12-1.55-0.09-2c-0.21-0.45-0.51-0.71-0.91-0.77
            c-0.39-0.07-0.75,0.07-1.09,0.42S148.77,42.47,148.64,43.24z"/>
            <path d="M160.51,36.31c1.02,0.25,1.71,0.96,2.09,2.12c0.38,1.16,0.25,3-0.37,5.51c-0.62,2.51-1.37,4.19-2.25,5.04
            c-0.88,0.85-1.82,1.15-2.83,0.9c-1.02-0.25-1.72-0.96-2.1-2.11c-0.38-1.15-0.26-3,0.37-5.54c0.62-2.5,1.37-4.17,2.24-5.02
            C158.56,36.36,159.5,36.06,160.51,36.31z M159.99,38.43c-0.4-0.1-0.78,0.09-1.15,0.58c-0.37,0.49-0.81,1.74-1.31,3.77
            c-0.5,2.03-0.71,3.34-0.6,3.94c0.1,0.6,0.35,0.95,0.75,1.05c0.4,0.1,0.79-0.09,1.16-0.58c0.37-0.49,0.8-1.74,1.3-3.76
            c0.5-2.03,0.71-3.35,0.61-3.95C160.65,38.88,160.4,38.53,159.99,38.43z"/>
            <path d="M178.59,45.21l1.05-2.2l6.39,3.04l-0.82,1.72c-0.84,0.41-1.81,1.09-2.93,2.05c-1.11,0.96-2.15,2.06-3.1,3.31
            s-1.7,2.43-2.24,3.57l-1.8-0.86c0.88-1.73,1.99-3.35,3.34-4.86c1.35-1.5,2.8-2.75,4.36-3.75L178.59,45.21z"/>
            <path d="M191.24,48.68c0.91,0.52,1.38,1.4,1.43,2.62c0.04,1.22-0.58,2.95-1.87,5.2c-1.29,2.24-2.47,3.65-3.55,4.22
            s-2.07,0.6-2.97,0.08c-0.91-0.52-1.39-1.39-1.43-2.6c-0.05-1.21,0.58-2.95,1.89-5.23c1.28-2.23,2.46-3.63,3.54-4.2
            S190.34,48.16,191.24,48.68z M190.16,50.57c-0.35-0.2-0.78-0.12-1.27,0.24c-0.49,0.37-1.26,1.45-2.3,3.26
            c-1.04,1.81-1.6,3.02-1.67,3.62c-0.07,0.6,0.08,1.01,0.43,1.21c0.36,0.21,0.78,0.13,1.27-0.24c0.49-0.37,1.25-1.45,2.29-3.26
            c1.04-1.81,1.6-3.02,1.67-3.63C190.66,51.18,190.52,50.77,190.16,50.57z"/>
            <path d="M205.08,66.15c-0.2-0.54-0.24-1.07-0.12-1.6c0.12-0.52,0.4-1.04,0.84-1.53c0.71-0.81,1.5-1.26,2.36-1.35
            c0.86-0.09,1.67,0.19,2.41,0.85c0.79,0.7,1.17,1.48,1.13,2.36c-0.03,0.87-0.38,1.68-1.02,2.41c-0.44,0.5-0.92,0.84-1.44,1.04
            c-0.52,0.19-1.04,0.23-1.56,0.12c0.26,0.64,0.32,1.27,0.17,1.9c-0.14,0.62-0.48,1.25-1.03,1.86c-0.56,0.64-1.17,1.1-1.82,1.38
            c-0.65,0.29-1.29,0.38-1.92,0.29s-1.19-0.36-1.69-0.8c-0.78-0.69-1.18-1.54-1.2-2.55c-0.02-1.01,0.4-1.99,1.24-2.95
            c0.54-0.62,1.12-1.05,1.75-1.29C203.81,66.05,204.45,66,205.08,66.15z M203.13,68.73c-0.49,0.55-0.75,1.08-0.78,1.57
            c-0.04,0.5,0.1,0.88,0.42,1.16c0.32,0.28,0.71,0.37,1.17,0.27c0.46-0.1,0.95-0.43,1.45-1c0.43-0.49,0.66-0.97,0.69-1.45
            c0.03-0.48-0.11-0.86-0.43-1.14c-0.32-0.28-0.71-0.37-1.18-0.27C203.99,67.96,203.55,68.25,203.13,68.73z M207.15,64.45
            c-0.35,0.4-0.53,0.79-0.54,1.16c-0.01,0.38,0.13,0.68,0.41,0.93c0.28,0.25,0.61,0.35,0.98,0.29c0.37-0.05,0.74-0.28,1.09-0.68
            c0.34-0.39,0.51-0.77,0.52-1.14c0-0.37-0.13-0.68-0.4-0.92c-0.29-0.25-0.62-0.35-0.99-0.3C207.84,63.85,207.49,64.07,207.15,64.45
            z"/>
            <path d="M217.38,68.99c0.73,0.75,0.95,1.72,0.66,2.91c-0.29,1.19-1.37,2.68-3.22,4.49c-1.85,1.8-3.38,2.83-4.57,3.09
            c-1.19,0.26-2.15,0.01-2.88-0.73c-0.73-0.75-0.95-1.72-0.67-2.9c0.29-1.18,1.37-2.68,3.25-4.51c1.84-1.79,3.36-2.82,4.56-3.08
            C215.69,68,216.65,68.24,217.38,68.99z M215.82,70.51c-0.28-0.29-0.71-0.33-1.28-0.11c-0.57,0.21-1.61,1.05-3.11,2.51
            c-1.5,1.46-2.36,2.47-2.59,3.03c-0.23,0.56-0.2,0.99,0.08,1.28c0.29,0.3,0.72,0.34,1.29,0.12c0.57-0.22,1.6-1.05,3.1-2.51
            c1.5-1.46,2.37-2.47,2.6-3.03C216.14,71.24,216.11,70.81,215.82,70.51z"/>
            <path d="M221.43,90.42l1.32,1.54c-0.8,0.63-1.02,1.23-0.65,1.8c0.25,0.39,0.67,0.57,1.25,0.54c0.58-0.03,1.55-0.42,2.9-1.19
            c-0.46-0.01-0.86-0.1-1.19-0.27c-0.34-0.17-0.61-0.42-0.83-0.75c-0.44-0.69-0.49-1.52-0.15-2.5c0.34-0.97,1.09-1.82,2.22-2.55
            c1.22-0.78,2.35-1.11,3.41-1c1.06,0.11,1.85,0.58,2.37,1.4c0.59,0.92,0.62,1.96,0.11,3.13c-0.52,1.17-1.81,2.42-3.89,3.75
            c-2.12,1.36-3.83,2.02-5.16,2c-1.32-0.03-2.3-0.53-2.94-1.52c-0.44-0.69-0.57-1.4-0.38-2.15
            C219.99,91.92,220.53,91.17,221.43,90.42z M228.78,91.28c0.72-0.46,1.19-0.95,1.41-1.46c0.22-0.51,0.22-0.93,0.01-1.27
            c-0.21-0.32-0.54-0.49-1-0.5c-0.46-0.01-1.06,0.22-1.8,0.7c-0.74,0.48-1.21,0.94-1.41,1.4c-0.19,0.46-0.18,0.86,0.04,1.2
            c0.21,0.33,0.56,0.5,1.04,0.53C227.55,91.9,228.12,91.7,228.78,91.28z"/>
            <path d="M236.96,95.76c0.49,0.92,0.43,1.92-0.18,2.97c-0.61,1.06-2.06,2.2-4.35,3.41c-2.28,1.21-4.03,1.78-5.25,1.69
            c-1.22-0.08-2.07-0.59-2.56-1.51c-0.49-0.92-0.44-1.91,0.17-2.97c0.6-1.05,2.06-2.2,4.38-3.43c2.27-1.21,4.01-1.77,5.23-1.68
            C235.61,94.33,236.47,94.84,236.96,95.76z M235.04,96.78c-0.19-0.36-0.59-0.52-1.2-0.47c-0.61,0.05-1.84,0.56-3.68,1.54
            c-1.85,0.98-2.96,1.71-3.34,2.19s-0.47,0.9-0.28,1.26c0.19,0.37,0.59,0.52,1.2,0.47c0.61-0.05,1.83-0.57,3.67-1.54
            c1.85-0.98,2.96-1.71,3.34-2.19C235.14,97.57,235.23,97.15,235.04,96.78z"/>
            <path d="M233.17,123.38l-0.56-1.99l9.52-2.7c-1.08-0.5-1.96-1.17-2.65-2.03l2.29-0.65c0.39,0.49,0.97,0.94,1.74,1.36
            c0.77,0.42,1.58,0.64,2.43,0.65l0.46,1.61L233.17,123.38z"/>
            <path d="M248.1,127.12c0.2,1.03-0.15,1.96-1.05,2.79c-0.9,0.83-2.62,1.49-5.16,1.98c-2.54,0.49-4.38,0.51-5.52,0.07
            c-1.14-0.44-1.81-1.17-2.01-2.19c-0.2-1.03,0.15-1.96,1.03-2.79c0.89-0.83,2.62-1.49,5.19-1.99c2.52-0.49,4.36-0.51,5.5-0.07
            S247.91,126.1,248.1,127.12z M245.97,127.54c-0.08-0.4-0.42-0.67-1.01-0.8c-0.6-0.13-1.92,0-3.97,0.39s-3.33,0.77-3.83,1.11
            c-0.5,0.34-0.71,0.72-0.63,1.12c0.08,0.41,0.42,0.68,1.01,0.81c0.6,0.13,1.92,0,3.97-0.4c2.05-0.4,3.33-0.76,3.84-1.11
            S246.05,127.95,245.97,127.54z"/>
            <path d="M249.5,136.53c0.11,1.04-0.32,1.94-1.29,2.69c-0.97,0.75-2.74,1.26-5.31,1.52c-2.57,0.26-4.4,0.13-5.5-0.41
            c-1.1-0.54-1.7-1.33-1.81-2.36c-0.11-1.04,0.32-1.94,1.27-2.69c0.96-0.75,2.74-1.26,5.34-1.53c2.56-0.26,4.38-0.13,5.48,0.41
            S249.39,135.49,249.5,136.53z M247.33,136.75c-0.04-0.41-0.35-0.7-0.94-0.89c-0.58-0.18-1.91-0.17-3.99,0.04
            c-2.08,0.21-3.38,0.47-3.91,0.77c-0.53,0.3-0.77,0.65-0.73,1.06c0.04,0.41,0.35,0.71,0.94,0.89c0.58,0.18,1.91,0.17,3.98-0.05
            c2.08-0.21,3.39-0.47,3.92-0.77C247.13,137.52,247.37,137.17,247.33,136.75z"/>
          </g>

          <!--*********** DIAL OUTLINE ***********-->
          <g id="dial_outline">
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-27.4667" y1="74.6948" x2="273.2283" y2="74.6948">
              <stop  offset="0.1002" style="stop-color:#499623"/>
              <stop  offset="0.1161" style="stop-color:#76AC22"/>
              <stop  offset="0.1332" style="stop-color:#9FC022"/>
              <stop  offset="0.1507" style="stop-color:#C1D122"/>
              <stop  offset="0.1685" style="stop-color:#DBDE21"/>
              <stop  offset="0.1868" style="stop-color:#EDE721"/>
              <stop  offset="0.2058" style="stop-color:#F8EC21"/>
              <stop  offset="0.2265" style="stop-color:#FCEE21"/>
              <stop  offset="0.2305" style="stop-color:#FBEC20"/>
              <stop  offset="0.2857" style="stop-color:#F1D012"/>
              <stop  offset="0.3412" style="stop-color:#EBBC08"/>
              <stop  offset="0.3969" style="stop-color:#E6B002"/>
              <stop  offset="0.4528" style="stop-color:#E5AC00"/>
              <stop  offset="0.6119" style="stop-color:#E5AA00"/>
              <stop  offset="0.6891" style="stop-color:#E6A200"/>
              <stop  offset="0.7486" style="stop-color:#E89500"/>
              <stop  offset="0.799" style="stop-color:#EB8300"/>
              <stop  offset="0.8438" style="stop-color:#EF6B00"/>
              <stop  offset="0.8845" style="stop-color:#F34D00"/>
              <stop  offset="0.9213" style="stop-color:#F92A00"/>
              <stop  offset="0.9556" style="stop-color:#FF0300"/>
              <stop  offset="0.9578" style="stop-color:#FF0000"/>
              <stop  offset="1" style="stop-color:#FF0000"/>
            </linearGradient>
            <path style="fill:url(#SVGID_1_);" d="M294.06,94.66c-6.67-17.24-16.91-32.92-29.9-46.25c-12.57-12.91-27.72-23.62-44.69-31.46
            C203.97,9.8,186.93,5.04,168.94,3.23c-5.4-0.54-10.88-0.83-16.44-0.83c-11.93,0-23.54,1.29-34.67,3.73
            C100.58,9.9,84.49,16.42,70.11,25.17c-14.44,8.78-27.15,19.8-37.55,32.52C22.13,70.44,14.02,84.91,8.81,100.55
            c-4.33,12.99-6.66,26.8-6.66,41.11c0,1.79,0.05,3.56,0.12,5.33H7.4c-0.13-2.21-0.21-4.43-0.21-6.66c0-13.13,2.36-25.75,6.7-37.52
            c5.45-14.76,14.02-28.17,24.98-39.57c10.69-11.11,23.65-20.31,38.21-26.96c13.62-6.22,28.63-10.21,44.49-11.46
            c3.56-0.28,7.15-0.43,10.78-0.43c11.51,0,22.66,1.45,33.25,4.14c14.21,3.62,27.41,9.49,39.12,17.19
            c12.24,8.05,22.85,18.08,31.28,29.6c8.01,10.93,14.05,23.2,17.66,36.37c2.51,9.16,3.85,17.32,3.85,27.2c0,2.24-0.08,5.9-0.21,8.1
            h45.41c0.07-1.77,0.12-3.54,0.12-5.33C302.85,125.17,299.74,109.35,294.06,94.66z"/>
          </g>

          <!--*********** DIAL CENTER ***********-->
          <g id="dial_center" style="opacity:0.5;">
            <path style="fill:#EBF7FC;" d="M139.42,71.24c-38.9,0-70.43,36.85-70.43,75.75h140.87C209.85,108.09,178.32,71.24,139.42,71.24"/>
          </g>

          <!--*********** DIAL NEEDLE ***********-->
          <g v-if="risk==1" id="minimal">
            <g id="needle_minimal">
              <path style="stroke:#000000;stroke-width:0.8934;stroke-miterlimit:10;" d="M126.12,139.82c2.3-5.32,8.49-7.77,13.81-5.47
              c5.32,2.31,7.77,8.49,5.47,13.81c-2.3,5.32-8.49,7.77-13.81,5.47C126.26,151.33,123.81,145.14,126.12,139.82z"/>
              <g>
                <path style="fill:#1A1A1A;" d="M138.53,144.43c-0.48,2.2-2.41,3.64-4.3,3.22L45.9,131.81c-1.53-0.34-2.81-0.49-2.52-1.8
                c0.29-1.31,1.51-0.91,3.04-0.58l89.54,10.28C137.87,140.12,139.01,142.24,138.53,144.43z"/>
              </g>
              <path style="fill:#FFFFFF;stroke:#000000;stroke-width:0.5425;stroke-miterlimit:10;" d="M129.9,141.46
              c1.4-3.23,5.16-4.72,8.39-3.32c3.23,1.4,4.72,5.15,3.32,8.39c-1.4,3.23-5.15,4.72-8.39,3.32
              C129.99,148.45,128.5,144.69,129.9,141.46z"/>
            </g>
          </g>

          <g v-if="risk==2" id="low">
            <g id="needle_low">
              <path style="stroke:#000000;stroke-width:0.8934;stroke-miterlimit:10;" d="M128.98,135.97c4.43-3.75,11.06-3.19,14.8,1.24
              c3.75,4.43,3.19,11.06-1.24,14.8c-4.43,3.75-11.06,3.19-14.8-1.24C123.99,146.35,124.55,139.72,128.98,135.97z"/>
              <g>
              <path style="fill:#1A1A1A;" d="M138.04,145.62c-1.41,1.75-3.78,2.19-5.29,0.97L60.68,93.16c-1.22-0.99-2.3-1.68-1.45-2.73
              c0.84-1.05,1.75-0.15,2.98,0.83l75.65,48.99C139.37,141.46,139.45,143.87,138.04,145.62z"/>
              </g>
              <path style="fill:#FFFFFF;stroke:#000000;stroke-width:0.5425;stroke-miterlimit:10;" d="M131.64,139.12
              c2.69-2.27,6.72-1.94,8.99,0.75c2.27,2.69,1.94,6.71-0.75,8.99s-6.71,1.94-8.99-0.75C128.62,145.42,128.95,141.4,131.64,139.12z"/>
            </g>
          </g>

          <g v-if="risk==3" id="moderate">
            <g id="needle_moderate">
              <path style="stroke:#000000;stroke-width:0.8934;stroke-miterlimit:10;" d="M141.6,135.01c4.96,3,6.55,9.46,3.55,14.42
              c-3,4.96-9.46,6.55-14.42,3.55c-4.96-3-6.55-9.46-3.55-14.42C130.18,133.59,136.64,132,141.6,135.01z"/>
              <g>
                <path style="fill:#1A1A1A;" d="M135.35,146.68c-2.11-0.78-3.28-2.88-2.61-4.7l27.63-85.37c0.54-1.47,0.86-2.71,2.13-2.25
                c1.26,0.46,0.7,1.62,0.16,3.09l-22.28,87.33C139.72,146.61,137.46,147.46,135.35,146.68z"/>
              </g>
              <path style="fill:#FFFFFF;stroke:#000000;stroke-width:0.5425;stroke-miterlimit:10;" d="M139.47,138.54
              c3.01,1.82,3.98,5.75,2.16,8.76c-1.82,3.01-5.74,3.98-8.76,2.16c-3.01-1.82-3.98-5.74-2.16-8.76
              C132.53,137.68,136.45,136.71,139.47,138.54z"/>
            </g>
          </g>

          <g v-if="risk==4" id="moderateCarb">
            <g id="needle_carb">
              <path style="stroke:#000000;stroke-width:0.8934;stroke-miterlimit:10;" d="M145.83,139.88c2.27,5.34-0.21,11.51-5.55,13.78
              c-5.34,2.27-11.51-0.21-13.78-5.55c-2.27-5.34,0.21-11.51,5.55-13.78C137.39,132.06,143.56,134.54,145.83,139.88z"/>
              <g>
                <path style="fill:#1A1A1A;" d="M133.94,145.7c-1.26-1.86-0.97-4.25,0.63-5.34l72.32-53.11c1.3-0.88,2.29-1.7,3.04-0.58
                c0.75,1.12-0.38,1.72-1.68,2.6l-69.12,57.84C137.52,148.19,135.2,147.56,133.94,145.7z"/>
              </g>
            <path style="fill:#FFFFFF;stroke:#000000;stroke-width:0.5425;stroke-miterlimit:10;" d="M142.03,141.5
            c1.38,3.24-0.13,6.99-3.37,8.37c-3.24,1.38-6.99-0.13-8.37-3.37s0.13-6.99,3.37-8.37C136.91,136.75,140.65,138.25,142.03,141.5z"
            />
            </g>
          </g>

          <g v-if="risk==5" id="high">
            <g id="needle_high">
              <path style="stroke:#000000;stroke-width:0.8934;stroke-miterlimit:10;" d="M146.56,145.51c-0.84,5.74-6.17,9.71-11.91,8.88
              c-5.74-0.84-9.72-6.17-8.88-11.91c0.84-5.74,6.17-9.71,11.91-8.88C143.42,134.44,147.4,139.77,146.56,145.51z"/>
              <path style="fill:#1A1A1A;" d="M133.38,144.29c-0.11-2.25,1.38-4.14,3.32-4.23l89.4-7.72c1.57-0.07,2.84-0.26,2.9,1.08
                c0.06,1.34-1.22,1.27-2.79,1.35l-89.13,13.42C135.14,148.28,133.48,146.54,133.38,144.29z"/>
              <path style="fill:#FFFFFF;stroke:#000000;stroke-width:0.5425;stroke-miterlimit:10;" d="M142.48,144.92
              c-0.51,3.49-3.75,5.9-7.23,5.39c-3.49-0.51-5.9-3.75-5.39-7.23c0.51-3.49,3.75-5.9,7.23-5.39
              C140.57,138.19,142.98,141.43,142.48,144.92z"/>
            </g>
          </g>

        </g>
      </svg>
    </v-col>
  </v-row>
</template>

<script>
// import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "riskDial",
  mixins: [],
  components: {  },

 data() {
    return {

    };
  },

   props: {
      risk: {
          type: Number,
          default: 1
      },
  },
  computed: {
    // ...mapGetters('userData', ['userData']),
  },

  mounted: function () {
    // this.markSectionComplete({section:'cancer'});
  },

  methods: {

    show: function(buttonsArray, button) {
        return !(buttonsArray.indexOf(button) == -1);
    },



  },

};
</script>


<style lang="scss" scoped>

.risk-dial {
  height: 157px;
  width: 305px;
}

</style>
