<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <v-row no-gutters>
            <div class="heading-main-modal">
              Thank you,<br>
  your email has been sent!
            </div>
            <div class="continue-btn" @click="closeDiscussions">CONTINUE</div>
          </v-row>
        </div>
      </div>
    </div>
  </transition>
  </template>
  <script>
  /* eslint-disable */

  import taggingMixin from '../mixins/taggingMixin';
    import scrollMixin from '../mixins/scrollMixin';
    import { mapGetters, mapMutations } from 'vuex';

  export default {
    name: "emailSentPopup",
    components: {
      },
    mixins: [scrollMixin, taggingMixin],
     mounted: function() {
        this.scrollToTop();
      },

    data() {
      return {

      };
    },

    computed: {
        ...mapGetters('userData', ['userData']),
    },

    created() {


    },

    methods: {
       ...mapMutations('userData', ['saveUserData']),
          closeDiscussions: function() {
     this.userData.isEmailSent = false;
        this.tagNavigation('continue', 'email');
          this.$router.go(-1);
      },

  }
  };
  </script>

  <style lang="scss" scoped>

  @import "../assets/styles/_variables.scss";

.modal-container {
  margin: 0px auto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #fff;
  border: 1px solid #0078ae;
  border-radius: 12px;
  width: 441px !important;
  opacity: 1;
  height: 222px !important;
  padding: 38px 16px 27px !important;
  text-align: center;

  @media (max-width: $screen-md-max) {
    // width: 554px;
    margin: 0 auto !important;
  }
  @media (max-width: $screen-sm-max) {
    width: 410px;
  }

  @media (max-width: $screen-xs-max) {
    width: 295px !important;
    height: auto;
    padding: 28px 10px 37px;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
 // -webkit-transform: scale(1.1);
 // transform: scale(1.1);
 // -ms-transform: scale(1.1);
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}


  </style>
