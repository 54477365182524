 <template>
  <v-row class="cinv-header no-gutters">
    <v-col
      class="order-md-1 order-2 reduce-padding-all"
      lg="9"
      md="7"
      cols="12"
    >
      <div class="page-title">{{ pageTitle }}
        <div v-if="displayInfo" class="image-div">
        <div @click="info" class="info-image"></div>
      </div></div>

    </v-col>
    <v-col class="right-column order-md-2 order-1" lg="3" md="5" cols="12">
      <v-row class="no-gutters">
        <v-col cols="12">
          <div class="logo"></div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import taggingMixin from "../mixins/taggingMixin";

export default {
  name: "cinvHeader",
  mixins: [taggingMixin],
  components: {},

  data() {
    return {};
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    displayInfo: {
      type: Boolean,
    },
  },
  methods: {
    info: function () {
       dataLayer.push({
                'event' : 'e_navigation',
                'screen' : this.pageTitle,
                'buttonText' : 'More Information',
                });
      this.$router.push("moreInfo");
    },
  },
};
</script>


<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";

.cinv-header {
  background-color: #0078ae;
  height: 91px;
  margin: auto;

  @media (max-width: $screen-sm-max) {
    height: auto;
  }

  .image-div {
    display: inline-block;
    width: 36px;
  }

  .info-image {
    background-image: url("../images/icon-svg/header_info.svg");
    background-repeat: no-repeat;
    cursor: pointer;
    height: 36px;
    width: 36px;
    margin-left: 7px;
    @media (max-width: $screen-md-max) {
      margin-left: 4px;
    }
    @media (max-width: $screen-sm-max) {
      margin-left: 7px;

    }
    @media (max-width: $screen-xs-max) {
      margin-left: 0;
height: 32px;
    width: 32px;
    margin-top: 2px;
    }
  }
  .page-title {
    color: white;
    font-size: 34px;
    font-weight: 700;
    padding: 20px;

    @media (max-width: $screen-md-max) {
      font-size: 25px;
      padding: 19px 0 25px 11px;
    }

    @media (max-width: $screen-sm-max) {
      padding: 0 15px 22px;
      text-align: center;
    }

    @media (max-width: $screen-xs-max) {
      line-height: 1.2;
      padding: 0 8px 20px;
    }
  }

  .logo {
    background-image: url("../images/icon-svg/cinvlogo.svg");
    background-repeat: no-repeat;
    float: right;
    height: 48px;
    margin: 9px 15px 0 auto;
    width: 266px;

    @media (max-width: $screen-md-max) {
      margin: 9px 1px 0 auto;
    }

    @media (max-width: $screen-sm-max) {
      float: none;
      margin: 10px auto 7px;
    }
  }
}
</style>
