<template>
  <v-row class="cinv-navigation" no-gutters :class="{ 'grey-bg': colorBg }">
    <v-col cols="12">
      <v-row no-gutters>
        <!--left-->
        <v-col
          class="left-sec order-lg-1 order-md-1 order-3"
          :class="{ 'order-1': ispatienPage }"
          lg="2"
          md="3"
          cols="12"
        >
          <v-row class="left-nav-sec" no-gutters>
            <v-col class="left-btn reduce-padding">
              <button
                @click="back(toScreen[0])"
                v-show="
                  show(navButtons, 'back') || show(navButtons, 'previous')
                "
                class="back-btn btn"
              >
                <span class="back-icon"></span>
                <span class="btn-text">BACK</span>
              </button>
            </v-col>
          </v-row>
        </v-col>
        <!--mid-->
        <v-col class="mid-sec order-lg-2 order-1" lg="8" cols="12" md="6">
          <v-row class="mid-nav-sec" no-gutters>
            <v-col class="reduce-padding">
              <div class="mid-text" :class="{ 'blue-txt': blueTxt }">
                <div v-show="show(navButtons, 'risk1')" class=" ">
                  <sup>*</sup> Indicates the risk of emesis in absence of
                  antiemetic prophylaxis.
                </div>
                <div
                  v-show="show(navButtons, 'links')"
                  :class="{
                    'center-text':
                      !show(navButtons, 'risk1') && !show(navButtons, 'risk2'),
                  }"
                  class="visit-txt"
                >
                  Please visit
                  <a
                    @click="tagNav('mascc')"
                    target="_blank"
                    href="https://www.esmoopen.com/article/S2059-7029(23)01436-9/fulltext"
                    >MASCC/ESMO</a
                  >
                  and
                  <a
                    @click="tagNav('asco')"
                    target="_blank"
                    href="https://ascopubs.org/doi/10.1200/JCO.20.01296?url_ver=Z39.88-2003&rfr_id=ori:rid:crossref.org&rfr_dat=cr_pub%20%200pubmed"
                    >ASCO</a
                  >
                  for complete emetogenicity classication.
                </div>

                <div
                  v-show="show(navButtons, 'links2')"
                  :class="{
                    'center-text':
                      !show(navButtons, 'risk1') && !show(navButtons, 'risk2'),
                  }"
                  class="visit-txt"
                >
                  Please visit
                  <a
                    @click="tagNav('mascc')"
                    target="_blank"
                    href="https://www.esmoopen.com/article/S2059-7029(23)01436-9/fulltext"
                    >MASCC/ESMO</a
                  >
                  and
                  <a
                    @click="tagNav('asco')"
                    target="_blank"
                    href="https://ascopubs.org/doi/10.1200/JCO.20.01296?url_ver=Z39.88-2003&rfr_id=ori:rid:crossref.org&rfr_dat=cr_pub%20%200pubmed"
                    >ASCO</a
                  >
                  for complete CINV recommendations.
                </div>

                <div v-show="show(navButtons, 'risk2')" class=" ">
                  <sup>*</sup> Indicates the risk of emesis in absence of
                  antiemetic prophylaxis.
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <!--right-->
        <v-col class="right-sec order-md-3 order-2" lg="2" md="3" cols="12">
          <v-row class="right-nav-sec" no-gutters>
            <v-col cols="12" class="main-btns-section reduce-padding">
              <button
                @click="next(toScreen[1])"
                v-show="show(navButtons, 'next')"
                class="forward-btn btn"
              >
                <span class="btn-text nxt-text">NEXT</span>
                <span class="next-icon"></span>
              </button>

              <div
                class="new-section"
                v-show="show(navButtons, 'new') || show(navButtons, 'exit')"
              >
                <button
                  @click="newPatient()"
                  v-show="show(navButtons, 'new')"
                  class="new-btn btn"
                >
                  NEW PATIENT
                </button>
                <button
                  @click="exit()"
                  v-show="show(navButtons, 'exit')"
                  class="exit-btn btn"
                >
                  EXIT
                </button>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import taggingMixin from "../mixins/taggingMixin";
import scrollMixin from "../mixins/scrollMixin";

import { mapMutations } from "vuex";
export default {
  name: "cinvNavigation",
  mixins: [taggingMixin, scrollMixin],

  components: {},

  data() {
    return {};
  },

  props: {
    navButtons: {
      type: Array,
    },
    btnClickHandler: {
      type: Function,
    },
    toScreen: {
      type: Array,
      default: () => [" ", " "],
    },
    tagHandler: {
      type: Function,
    },
    colorBg: {
      type: Boolean,
    },
    blueTxt: {
      type: Boolean,
      default: false,
    },
    ispatienPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // ...mapGetters('userData', ['userData']),
  },

  mounted: function () {
    // Send page views
    var url = window.location.href;
    this.tagPageViews(url, this.$route.path);
  },

  methods: {
    ...mapMutations("userData", ["resetSections"]),
    show: function (buttonsArray, button) {
      return !(buttonsArray.indexOf(button) == -1);
    },

    newPatient: function () {
      this.tagNavigation("new", this.$route.path.split("/")[1]);
      this.resetSections();
      this.$router.push("chemoEmtoLevel");
    },

    exit: function () {
      this.tagNavigation("exit", this.$route.path.split("/")[1]);
      const newloc=this.$route.path.split("/")[0];
      window.location.reload();
      window.location=newloc;
    },

    next: function (screen) {
      if (this.btnClickHandler === undefined || this.btnClickHandler()) {
        this.tagNavigation("next", this.$route.path.split("/")[1]);
        this.$router.push(screen);
      }
    },

    back: function (screen) {
      if (this.show(this.navButtons, "back")) {
        setTimeout(() => {
          this.tagNavigation("back", this.$route.path.split("/")[1]);
          console.log("in back: screen: ", screen);
          this.$router.push(screen);
        }, 0);
      } else {
        this.tagNavigation("back", this.$route.path.split("/")[1]);
        this.$router.go(-1);
      }
    },

    tagNav: function (action) {
      this.tagNavigation(action, this.$route.path.split("/")[1]);
    },
  },
};
</script>


<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";

.cinv-navigation {
  height: 55px;

  @media (max-width: $screen-md-max) {
    height: auto;
  }

  @media (max-width: $screen-sm-max) {
    padding: 15px 15px 10px;
  }

  @media (max-width: $screen-xs-max) {
    padding: 15px 7px 10px;
  }
}

.btn,
.btn:focus,
.btn:hover,
.btn:active {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  cursor: pointer;
  border-radius: 8px;
  width: 165px;
  height: 52px;
  background-color: #0078ae;
  color: #fff;
  font-size: 26px;
  margin-top: 5px;

  @media (max-width: $screen-sm-max) {
    margin: 10px 0 0;
    width: 100%;
    padding: 0 5px;
    line-height: 1.2;
  }
}

.back-btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important; // padding-left: 5px !important;
  @media (max-width: $screen-sm-max) {
    border-radius: 8px !important;
  }
}

.forward-btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  float: right;

  @media (max-width: $screen-sm-max) {
    border-radius: 8px !important;
    float: none;
  }
}

.new-btn,
.new-btn:focus,
.new-btn:hover,
.new-btn:active {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-weight: 700;
  margin: 5px 11px 0 auto; // padding: 6px 7px;
  width: 195px;

  @media (max-width: $screen-sm-max) {
    // @media (max-width: $screen-xs-max) {
    border-radius: 8px !important;
    float: none;
    margin: 10px 11px 0 auto;
    width: 100%;
  }
  @media (max-width: $screen-xs-max) {
    margin: 5px auto 0;
  }
}

.exit-btn,
.exit-btn:focus,
.exit-btn:hover,
.exit-btn:active {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  float: right;
  font-weight: 700;
  width: 135px;

  @media (max-width: $screen-sm-max) {
    width: 165px; //     }
    // @media (max-width: $screen-xs-max) {
    border-radius: 8px !important;
    float: none;
    width: 100%;
  }
}

.new-section {
  float: right;
  margin: 0 0 0 auto;
  width: 341px;

  @media (max-width: $screen-sm-max) {
    width: 367px; // }
    // @media (max-width: $screen-xs-max) {
    width: 100%;
  }
}

.back-icon {
  background-image: url("../images/icon-svg/backicon.svg");
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 32px;
  width: 28px;
  vertical-align: middle;
}

.next-icon {
  background-image: url("../images/icon-svg/nexticon.svg");
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 32px;
  width: 28px;
  vertical-align: middle;
}

.btn-text {
  display: inline-block;
  font-weight: 700;
  margin-left: 15px;
  vertical-align: middle;
  line-height: 1;

  @media (max-width: $screen-sm-max) {
    // line-height: 0.95;
  }
}

.nxt-text {
  margin-right: 18px;
  vertical-align: middle;
  margin-left: 17px;

  @media (max-width: $screen-sm-max) {
    margin-left: 0 !important;
  }
}

.blue-txt {
  color: #000 !important;

  a,
  a:hover,
  a:focus,
  a:active {
    color: #000 !important;
    cursor: pointer;
    text-decoration: underline;
  }
}

.visit-txt {
  margin-top: 2px;
}

.mid-text {
  color: $textPrimary;
  font-family: $Primary-fontName;
  font-size: 15px; // line-height: 1;
  text-align: center;
  line-height: 1.3;
  padding-top: 3px;

  @media (max-width: $screen-md-max) {
    font-size: 14px;
    line-height: 1.2;
  }

  sup {
    top: -4px;
    right: -3px;
  }

  a,
  a:hover,
  a:focus,
  a:active {
    color: $textPrimary;
    cursor: pointer;
    text-decoration: underline;
  }
}

.center-text {
  padding-top: 18px;
} // ::ng-deep .order-md-2 {
//      @media (max-width: $screen-md-max) {
//     order: 1 !important;
//      }
// }
.order-1 {
  order: 1 !important;
}

.grey-bg {
  background-color: #ebecec;
}
</style>
