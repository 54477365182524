<template>
  <v-row class="patients-report" no-gutters>
    <v-col class="tool-border" cols="12">
      <v-col class="reduce-padding-all" cols="12">
        <cinv-header :page-title="pageTitle" :display-info="true"></cinv-header>
      </v-col>
      <v-col class="page-background reduce-padding" cols="12">
        <v-row class="page-content" no-gutters>
          <v-col class="reduce-padding" cols="12">
            <v-row class="body-sec" no-gutters>
              <v-col class="content-section reduce-padding" cols="12">
                <div class="text">
                  <p>
                    <b
                      >Choose from the options below to view, print, save or
                      email a detailed color PDF of your patient's results.</b
                    >
                  </p>
                </div>

                <v-row class="main-body" no-gutters>
                  <v-col md="7" cols="12">
                    <v-row class="table button-divs" no-gutters>
                      <div
                        class="email"
                        @click="generateViewPdf(userData)"
                      ></div>
                      <v-btn
                        v-if="!generatingViewPdf && !pdfViewReady"
                        class="print-button"
                        @click="generateViewPdf(userData)"
                      >
                        <b>View or Print Report</b>
                      </v-btn>
                      <v-btn
                        v-if="generatingViewPdf"
                        class="pdf-button spinner-btn btn2-gen"
                        disabled
                      >
                        <div class="topgenbtn">
                          <v-spinner small></v-spinner> Generating Report...
                        </div>
                      </v-btn>
                      <v-btn
                        v-if="pdfViewReady && !generatingViewPdf"
                        class="pdf-button btn2-gen"
                        @click="viewPDF()"
                      >
                        <div class="topgenbtn">Click to view now</div>
                      </v-btn>
                    </v-row>
                    <v-row class="table button-divs" no-gutters>
                      <div class="pdf" @click="generatePdf(userData)"></div>
                      <v-btn
                        v-if="!generatingPdf && !pdfReady"
                        class="pdf-button"
                        @click="generatePdf(userData)"
                      >
                        Save PDF
                      </v-btn>
                      <v-btn
                        v-if="generatingPdf"
                        class="pdf-button spinner-btn"
                        disabled
                      >
                        <v-spinner small></v-spinner> Generating Report...
                      </v-btn>
                      <v-btn
                        v-if="pdfReady && !generatingPdf"
                        class="pdf-button"
                        @click="downloadaPdf()"
                      >
                        DOWNLOAD REPORT
                      </v-btn>
                    </v-row>
                    <v-row class="table button-divs" no-gutters>
                      <div class="mail" @click="email()"></div>
                      <v-btn @click="email()" class="email-button">
                        <b>Email PDF</b>
                      </v-btn>
                    </v-row>
                  </v-col>
                  <v-col md="5" cols="12">
                    <div class="report"></div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <cinv-navigation
          :ispatien-page="true"
          :nav-buttons="['back', 'new', 'exit']"
          :to-screen="['recommendation', ' ']"
        ></cinv-navigation>
      </v-col>
    </v-col>
  </v-row>
</template>


<script>
/* eslint-disable */
import taggingMixin from "../mixins/taggingMixin";
import scrollMixin from "../mixins/scrollMixin";
import cinvHeader from "../components/cinvHeader.vue";
import cinvNavigation from "../components/cinvNavigation.vue";
import { mapGetters, mapMutations } from "vuex";
import LZString from "lz-string";
export default {
  name: "patientsReport",
  mixins: [scrollMixin, taggingMixin],
  components: {
    cinvHeader: cinvHeader,
    cinvNavigation: cinvNavigation,
  },

  data() {
    return {
      pageTitle: "Patient’s Report",
      generatingPdf: false,
      pdfReady: false,
      generatingViewPdf: false,
      pdfViewReady: false,
    };
  },

  computed: {
    ...mapGetters("userData", ["userData"]),
  },

  mounted: function () {
    this.scrollToTop();
  },
  methods: {
    ...mapMutations("userData", ["markSectionComplete"]),
    email: function () {
      this.tagNavigation("email", this.$route.path.split("/")[1]);
      this.$router.push("email");
    },

    generatePdf: function (userData) {
      this.generatingPdf = true;
      const data = LZString.compressToEncodedURIComponent(
        JSON.stringify(userData)
      );

      // if a URL was given in the config use that otherwise create it based on the current location
      var reportUrl = import.meta.env.VITE_REPORT_URL;
      if (reportUrl !== undefined) {
        reportUrl = "{0}#?u={1}".format(reportUrl);
      } else {
        const url = window.location.href;
        const parts = url.split("://");
        const schema = parts[0];
        const uri = parts[1].replace(/#.*$/, "").replace(/\/[^\/]*$/, "");

        reportUrl = `${schema}://${uri}/report.html?u=${data}`;
        console.log(reportUrl);
        const renderConfig = {
          url: reportUrl,
          paper_size: {
            width: 8.5,
            height: 11,
          },
          margin: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          print_background: true,
        };

        const serverUrl = import.meta.env.VITE_API_URL || "";

        const requestOpts = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(renderConfig),
        };

        fetch(`${serverUrl}/v1/render`, requestOpts)
          .then((res) => res.json())
          .then((data) => {
            this.generatingPdf = false;
            this.pdfReady = true;
            this.key = data.result;
          })
          .catch((error) => {
            this.generatingPdf = false;
            this.pdfReady = false;
            alert(`Error generating PDF. ${error}`);
          });
      }
    },

    downloadaPdf: function () {
      const serverUrl = import.meta.env.VITE_API_URL || "";

      window.open(
        `${serverUrl}/v1/render/${this.key}?filename=report.pdf&save=true`,
        "_blank"
      );
       this.tagNavigation("save", this.$route.path.split("/")[1]);
      this.pdfReady = false;
    },

    generateViewPdf: function (userData) {
      this.generatingViewPdf = true;
      const data = LZString.compressToEncodedURIComponent(
        JSON.stringify(userData)
      );

      // if a URL was given in the config use that otherwise create it based on the current location
      var reportUrl = import.meta.env.VITE_REPORT_URL;
      if (reportUrl !== undefined) {
        reportUrl = "{0}#?u={1}".format(reportUrl);
      } else {
        const url = window.location.href;
        const parts = url.split("://");
        const schema = parts[0];
        const uri = parts[1].replace(/#.*$/, "").replace(/\/[^\/]*$/, "");

        reportUrl = `${schema}://${uri}/report.html?u=${data}`;
        console.log(reportUrl);
        const renderConfig = {
          url: reportUrl,
          paper_size: {
            width: 8.5,
            height: 11,
          },
          margin: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          print_background: true,
        };

        const serverUrl = import.meta.env.VITE_API_URL || "";

        const requestOpts = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(renderConfig),
        };

        fetch(`${serverUrl}/v1/render`, requestOpts)
          .then((res) => res.json())
          .then((data) => {
            this.generatingViewPdf = false;
            this.pdfViewReady = true;
            this.key = data.result;
          })
          .catch((error) => {
            this.generatingViewPdf = false;
            this.pdfViewReady = false;
            alert(`Error generating PDF. ${error}`);
          });
      }
      // this.generatingViewPdf = false;
    },
    viewPDF: function () {
      const serverUrl = import.meta.env.VITE_API_URL || "";
      const apiKey = import.meta.env.VITE_API_KEY || "";

      window.open(
        `${serverUrl}/v1/render/${this.key}?filename=report.pdf`,
        "_blank"
      );
      this.tagNavigation("print", this.$route.path.split("/")[1]);

      this.pdfViewReady = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";

.patients-report {
  font-family: "Arial Narrow", Arial, sans-serif !important;
  text-transform: none !important;

  .page-content {
    @media (max-width: $screen-md-max) {
      height: 520px;
    }
    @media (max-width: $screen-sm-max) {
      height: auto;
    }
  }

  .text {
    font-size: 26px;
    color: #0078ae;

    text-align: center;
    padding: 62px;
    margin: auto;
    margin: 0 108px;
    line-height: 30px;
    @media (max-width: $screen-md-max) {
      margin-left: 18px;
      margin-right: 18px;
      padding: 40px;
    }
    @media (max-width: $screen-sm-max) {
      padding: 25px 15px;
      margin-left: 0;
      margin-right: 0;
    }
    @media (max-width: $screen-xs-max) {
      padding: 35px 5px 16px;
      font-size: 22px;
      line-height: 1.13;
    }
  }

  .btn2-gen {
    margin: -14px 0 3px !important;
  }

  .spinner-border {
    margin: 0 0 4px !important;
  }
  .email {
    background-image: url("../images/icons/email.png");
    width: 60px;
    height: 51px;
    display: inline-block;
    margin-left: 217px;
    margin-right: 26px;
    background-repeat: no-repeat;
    margin-top: -15px;
    @media (max-width: $screen-md-max) {
      margin-left: 104px;
    }

    @media (max-width: $screen-sm-max) {
      margin-left: 5px;
    }
  }
  .print-button {
    text-transform: none !important;

    display: inline-block;
    width: 220px;
    height: 53px;
    color: white;
    background-color: #0078ae;
    border-radius: 6px;
    font-size: 22px;
    margin: -14px 7px 3px 0;
    padding: 3px;
    float: right;
    font-family: "Arial Narrow", Arial, sans-serif !important;
    letter-spacing: 0px;
    border: 3px #7fbbd6;
  }
  .pdf {
    text-transform: none !important;
    font-family: "Arial Narrow", Arial, sans-serif !important;

    background-image: url("../images/icons/pdf.png");
    width: 52px;
    height: 53px;
    display: inline-block;
    margin-left: 224px;
    margin-right: 26px;
    margin-top: 10px;
    @media (max-width: $screen-md-max) {
      margin-left: 112px;
    }
    @media (max-width: $screen-sm-max) {
      margin-left: 13px;
    }
  }
  .pdf-button {
    display: inline-block;
    width: 220px;
    height: 53px;
    color: white;
    background-color: #0078ae;
    border-radius: 6px;
    font-size: 22px;
    margin: 8px 7px 3px 0;
    padding: 3px;
    float: right;
    border: 3px #7fbbd6;
    font-weight: bold;
    font-family: "Arial Narrow", Arial, sans-serif !important;
    text-transform: none !important;
    letter-spacing: 0px;
  }
  .main-body {
    @media (max-width: $screen-md-max) {
      margin-top: 30px;
    }
  }
  .mail {
    background-image: url("../images/icons/mail.png");
    width: 71px;
    height: 53px;
    display: inline-block;
    margin-left: 211px;
    margin-right: 20px;
    margin-top: 10px;
    background-repeat: no-repeat;
    text-transform: none !important;
    font-family: "Arial Narrow", Arial, sans-serif !important;

    @media (max-width: $screen-md-max) {
      margin-left: 100px;
    }
    @media (max-width: $screen-sm-max) {
      margin-left: 0;
    }
  }

  .page-background {
    padding: 0 !important;
  }
  .email-button {
    display: inline-block;
    width: 220px;
    height: 53px;
    color: white;
    background-color: #0078ae;
    border-radius: 6px;
    font-size: 22px;
    margin: 8px 7px 3px 0;
    padding: 3px;
    font-family: "Arial Narrow", Arial, sans-serif !important;
    float: right;
    border: 3px #7fbbd6;
    text-transform: none !important;
    letter-spacing: 0px;
  }
  .report {
    background-image: url("../images/icon-svg/updated-report-img.svg");
    height: 260px;
    width: 210px;
    background-repeat: no-repeat;
    display: block;
    margin: -31px 0px 2px -6px;
    @media (max-width: $screen-md-max) {
      margin-left: 10px;
      margin-bottom: 50px;
      margin-top: -26px;
    }
    @media (max-width: $screen-sm-max) {
      margin: 44px auto 28px;
    }
  }

  .button-divs {
    @media (max-width: $screen-sm-max) {
      width: 328px;
      margin: 0 auto;
      padding: 5px;
    }
  }
}
</style>
