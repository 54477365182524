<template>
  <v-row class="chemo" no-gutters>
    <v-col class="tool-border" cols="12">
      <v-col class="reduce-padding-all" cols="12">
        <cinv-header :page-title="pageTitle" :displayInfo="true"></cinv-header>
      </v-col>
      <v-col class="page-background reduce-padding-all" cols="12">
        <v-row class="page-content" no-gutters>
          <v-col class="" cols="12">
            <v-row class="body-sec" no-gutters>
              <v-col class="" cols="12">
                <v-row no-gutters>
                  <v-col lg="8" cols="12">
                    <v-row class="selection-area" no-gutters>
                      <div class="heading">
                        Based on 2023 MASCC/ESMO Guidelines:
                      </div>
                      <v-row class="patient-type" no-gutters>
                        <v-row class="patient" no-gutters>
                          <div class="alphabet-A"><b>A)</b></div>
                          <div class="Select-agents">
                            <b>
                              Please select the type of chemotherapy the patient
                              is scheduled to receive.{{ "\xa0" }} Select upto 4
                              agents.</b
                            >
                          </div>
                        </v-row>

                        <div class="drugs-section" :class="{'haserror' :'submitted && userData.selectedMedicines.length == 0'}">
                          <div>
                            <div class="dropdown-div">
                              <div
                                class="dropdown-textbox-div"
                                style="border: none"
                              >
                                <input
                                  class="dropdown-textbox blockClickEvent"
                                  :placeholder="placeholder"
                                  type="text"
                                  v-model="Inputtext"
                                  @change="searchMedicine()"
                                  @focus="showDropdown()"
                                />
                              </div>
                              <div
                                class="dropdown-button blockClickEvent"
                                @click="showDropdownOnclick()"
                              ></div>
                              <div
                                class="done-button blockClickEvent"
                                v-if="showList"
                                @click="done"
                              >
                                DONE
                              </div>
                            </div>
                            <div class="dropdown-listview blockClickEvent">
                              <ul
                                id="listviewUl"
                                class="dropdown-list blockClickEvent"
                                v-show="showList"
                              >
                                <li
                                  v-for="(
                                    typestring, index
                                  ) in chemotypesDropdown"
                                  v-bind:key="index"
                                  class="blockClickEvent"
                                >
                                  <input
                                    :class="typestring.medicineClass"
                                    class="blockClickEvent"
                                    type="checkbox"
                                    :id="index"
                                    :ref="'ref' + index"
                                    :value="typestring.medicineName"
                                    @click="
                                      clickedMedicine(
                                        typestring.medicineName,
                                        typestring.medicineRange,
                                        typestring.medicineClass,
                                        typestring.medicineNameSmall,
                                        typestring.medicineRank
                                      )
                                    "
                                  />
                                  <label :for="index" class="blockClickEvent">
                                    <div class="lbl-text blockClickEvent">
                                      {{ typestring.medicineName }}
                                    </div>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="error-outer med-error-outer">
                            <div
                              class="error-msg medicine-error"
                              v-show="
                                submitted &&
                                userData.selectedMedicines.length == 0
                              "
                            >
                              Valid entry is required.
                            </div>
                          </div>
                        </div>
                      </v-row>
                      <v-col class="md-display" cols="12">
                        <v-row class="five-divs" no-gutters>
                          <div class="guideline color-chart-section">
                            <b>MASCC/ESMO guideline Emetogenicity Level*</b>
                          </div>
                          <div
                            class="high color-chart-section"
                            :class="{
                              'red-opacity':
                                (showList && currPriority !== 5) ||
                                (!showList && highestPriority !== 5),
                            }"
                          >
                            <div class="left-text">
                              <b>High Emetogenic Chemotherapy</b>
                            </div>
                            <div class="number">&gt; 90%</div>
                          </div>
                          <div
                            class="moderate color-chart-section"
                            :class="{
                              'orange-opacity':
                                (showList &&
                                  currPriority !== 3 &&
                                  currPriority !== 4) ||
                                (!showList &&
                                  highestPriority !== 3 &&
                                  highestPriority !== 4),
                            }"
                          >
                            <div class="left-text">
                              <b>Moderate Emetogenic Chemotherapy</b>
                            </div>
                            <div class="number">30 - 90%</div>
                          </div>
                          <div
                            class="low color-chart-section"
                            :class="{
                              'yellow-opacity':
                                (showList && currPriority !== 2) ||
                                (!showList && highestPriority !== 2),
                            }"
                          >
                            <div class="left-text">
                              <b>Low Emetogenic Chemotherapy </b>
                            </div>
                            <div class="number">10 - 30%</div>
                          </div>
                          <div
                            class="minimal color-chart-section"
                            :class="{
                              'green-opacity':
                                (showList && currPriority !== 1) ||
                                (!showList && highestPriority !== 1),
                            }"
                          >
                            <div class="left-text">
                              <b>Minimal Emetogenic Chemotherapy </b>
                            </div>
                            <div class="number">&lt; 10%</div>
                          </div>
                        </v-row>
                      </v-col>
                      <v-col class="cycle" cols="12">
                        <div
                          class="b-text"
                          :class="{
                            haserror:
                              submitted && this.selectedOption == 'null',
                          }"
                        >
                          <div class="alphabet-B"><b> B) </b></div>
                          <div class="cycle-number">
                            <b> Current Cycle Number:</b>
                          </div>
                        </div>
                        <div class="select-container" :class="{'haserror' :'this.errorDays'}">
                          <cinvSelect1
                            :options="dropdownOptions"
                            v-model="selectedOption"
                            @change="handleDropdownChange"
                            placeholder="Select..."
                          ></cinvSelect1>
                        </div>
                        <div class="error-outer days-error-outer">
                          <div
                            class="error-msg days-error"
                            v-if="this.errorDays"
                          >
                            {{ errorDays }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <div class="arrows-div">
                    <div
                      v-show="currPriority == 5 && showList"
                      class="arrows red-arrow"
                    ></div>
                    <div
                      v-show="
                        showList && (currPriority == 4 || currPriority == 3)
                      "
                      class="arrows orange-arrow"
                    ></div>
                    <div
                      v-show="currPriority == 2 && showList"
                      class="arrows yellow-arrow"
                    ></div>
                    <div
                      v-show="currPriority == 1 && showList"
                      class="arrows green-arrow"
                    ></div>
                  </div>

                  <v-col lg="4" class="lg-display">
                    <v-row class="five-divs" no-gutters>
                      <div class="guideline color-chart-section">
                        <b>MASCC/ESMO guideline Emetogenicity Level*</b>
                      </div>
                      <div
                        class="high color-chart-section"
                        :class="{
                          'red-opacity':
                            (showList && currPriority !== 5) ||
                            (!showList && highestPriority !== 5),
                        }"
                      >
                        <div class="left-text">
                          <b>High Emetogenic Chemotherapy</b>
                        </div>
                        <div class="number">&gt; 90%</div>
                      </div>
                      <div
                        class="moderate color-chart-section"
                        :class="{
                          'orange-opacity':
                            (showList &&
                              currPriority !== 3 &&
                              currPriority !== 4) ||
                            (!showList &&
                              highestPriority !== 3 &&
                              highestPriority !== 4),
                        }"
                      >
                        <div class="left-text">
                          <b>Moderate Emetogenic Chemotherapy</b>
                        </div>
                        <div class="number">30 - 90%</div>
                      </div>
                      <div
                        class="low color-chart-section"
                        :class="{
                          'yellow-opacity':
                            (showList && currPriority !== 2) ||
                            (!showList && highestPriority !== 2),
                        }"
                      >
                        <div class="left-text">
                          <b>Low Emetogenic Chemotherapy </b>
                        </div>
                        <div class="number">10 - 30%</div>
                      </div>
                      <div
                        class="minimal color-chart-section"
                        :class="{
                          'green-opacity':
                            (showList && currPriority !== 1) ||
                            (!showList && highestPriority !== 1),
                        }"
                      >
                        <div class="left-text">
                          <b>Minimal Emetogenic Chemotherapy </b>
                        </div>
                        <div class="number">&lt; 10%</div>
                      </div>
                    </v-row>
                  </v-col>
                  <v-row no-gutters>
                    <div class="your-selection">YOUR SELECTIONS:</div>
                    <div class="result-area">
                      <div class="result-area-inner">
                        <v-row class="drugs-div" no-gutters>
                          <div
                            v-for="(med, i) in userData.selectedMedicines"
                            v-bind:key="i"
                            class="flex-container"
                          >
                            <div
                              class="del-icon flex-item"
                              @click="
                                removeMedicine(
                                  med.medicine,
                                  med.medicineRange,
                                  med.medicineClass
                                )
                              "
                            ></div>
                            <div class="drug-name flex-item">
                              {{ med.medicine }}
                            </div>
                          </div>
                        </v-row>
                      </div>
                      <div class="selection-bottom-text">
                        Based on 2023 MASCC/ESMO Guidelines, the emetogenicity
                        level of the regimen is related to the highest
                        emetogenic agent selected.
                      </div>
                    </div>
                  </v-row>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <cinvNavigation
          :navButtons="['back', 'links', 'risk2', 'next']"
          :toScreen="['/', 'riskProfile']"
          :btnClickHandler="this.handleSubmit"
        ></cinvNavigation>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable */
import scrollMixin from "../mixins/scrollMixin";
import cinvHeader from "../components/cinvHeader.vue";
import cinvNavigation from "../components/cinvNavigation.vue";
import cinvSelect1 from "../components/cinvSelect1.vue";
import taggingMixin from "../mixins/taggingMixin";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "chemoEmtoLevel",
  mixins: [taggingMixin, scrollMixin],
  components: {
    cinvHeader: cinvHeader,
    cinvNavigation: cinvNavigation,
    cinvSelect1: cinvSelect1,
  },

  data() {
    return {
      pageTitle: "Chemotherapy Emetogenicity Level",
      dropdownOptions: ["1", "2", "3", "4", "5", "6", "7+"],
      selectedOption: null,
      drugsList: [
        ["5-Fluorouracil", "Low", "check53", "5-Fluorouracil", 2],
        ["Aflibercept", "Low", "check33", "Aflibercept", 2],
        ["Alemtuzumab", "Moderate", "check7", "Alemtuzumab", 3],
        ["Amivantamab", "Low", "check34", "Amivantamab", 2],
        [
          "Anthracycline/cyclophosphamide combination",
          "High",
          "check0",
          "Anthracy./cyclophos. comb.",
          5,
        ],
        ["Arsenic trioxide", "Moderate", "check8", "Arsenic trioxide", 3],
        [
          "Asparaginase (calaspargase pegol)",
          "Minimal",
          "check83",
          "Asparaginase (cal. pegol)",
          1,
        ],
        ["Atezolizumab", "Minimal", "check84", "Atezolizumab", 1],
        [
          "Axicabtagene-ciloleucel",
          "Low",
          "check35",
          "Axicabtagene-ciloleucel",
          2,
        ],
        ["Avelumab", "Minimal", "check85", "Avelumab", 1],
        ["Azacitidine", "Moderate", "check9", "Azacitidine", 3],
        [
          "Belantamab-mafodotin",
          "Minimal",
          "check86",
          "Belantamab-mafodotin",
          1,
        ],
        ["Belinostat", "Low", "check36", "Belinostat", 2],
        ["Bendamustine", "Moderate", "check10", "Bendamustine", 3],
        ["Bevacizumab", "Minimal", "check87", "Bevacizumab", 1],
        ["Bleomycin", "Minimal", "check88", "Bleomycin", 1],
        ["Blinatumomab", "Low", "check37", "Blinatumomab", 2],
        ["Bortezomib", "Low", "check38", "Bortezomib", 2],
        ["Brentuximab-vedotin", "Low", "check39", "Brentuximab-vedotin", 2],
        ["Cabazitaxel", "Low", "check40", "Cabazitaxel", 2],
        ["Carboplatin", "Moderate", "check11", "Carboplatin", 4],
        ["Carmustine", "High", "check1", "Carmustine", 5],
        ["Carfilzomib", "Low", "check41", "Carfilzomib", 2],
        ["Catumaxumab", "Low", "check42", "Catumaxumab", 2],
        ["Cemiplimab", "Minimal", "check89", "Cemiplimab", 1],
        ["Cetuximab", "Low", "check43", "Cetuximab", 2],
        [
          "Chlormethine (mechlorethamine)",
          "High",
          "check2",
          "Chlormethine (mechlor.)",
          5,
        ],

        ["Cisplatin", "High", "check3", "Cisplatin", 5],
        [
          "Cladribine (2-chlorodeoxyadenosine)",
          "Minimal",
          "check90",
          "Cladribine (2-chlorodeoxyadenosine)",
          1,
        ],
        ["Clofarabine", "Moderate", "check12", "Clofarabine", 3],
        ["Copanlisib", "Low", "check44", "Copanlisib", 2],
        [
          "Cyclophosphamide <1500 mg/m²",
          "Moderate",
          "check13",
          "Cyclophos. <1500 mg/m²",
          3,
        ],
        [
          "Cyclophosphamide ≥ 1500 mg/m²",
          "High",
          "check4",
          "Cyclophos. ≥ 1500 mg/m²",
          5,
        ],
        [
          "Cytarabine ≤ 1000 mg/m²",
          "Low",
          "check45",
          "Cytarabine ≤ 1000 mg/m²",
          2,
        ],
        [
          "Cytarabine > 1000 mg/m²",
          "Moderate",
          "check14",
          "Cytarabine > 1000 mg/m²",
          3,
        ],
        [
          "Cytarabine/daunorubicm liposomal",
          "Moderate",
          "check15",
          "Cytarabine/daunorub. lipo.",
          3,
        ],
        ["Dacarbazine", "High", "check5", "Dacarbazine", 5],
        ["Daratumumab", "Minimal", "check91", "Daratumumab", 1],
        ["Daunorubicin", "Moderate", "check16", "Daunorubicin", 3],
        ["Decitabine", "Low", "check46", "Decitabine", 2],
        ["Dinutuximab beta", "Moderate", "check17", "Dinutuximab beta", 3],
        ["Docetaxel", "Low", "check47", "Docetaxel", 2],
        ["Dostarlimab", "Minimal", "check92", "Dostarlimab", 1],
        ["Doxorubicin", "Moderate", "check18", "Doxorubicin", 3],
        [
          "Doxorubicin peg-liposomal",
          "Low",
          "check48",
          "Doxorubicin peg-lipo.",
          2,
        ],
        ["Durvalumab", "Minimal", "check93", "Durvalumab", 1],
        ["Elotuzumab", "Low", "check49", "Elotuzumab", 2],
        ["Emapalumab", "Minimal", "check94", "Emapalumab", 1],
        ["Enfortumab-vedotin", "Low", "check50", "Enfortumab-vedotin", 2],
        ["Epirubicin", "Moderate", "check19", "Epirubicin", 3],
        ["Eribulin", "Low", "check51", "Eribulin", 2],
        ["Etoposide", "Low", "check52", "Etoposide", 2],
        ["Fludarabine", "Minimal", "check95", "Fludarabine", 1],
        ["Gemcitabine", "Low", "check54", "Gemcitabine", 2],
        ["Gemtuzumab-ozogamicin", "Low", "check55", "Gemtuzumab-ozogamicin", 2],
        ["Idarubicin", "Moderate", "check20", "Idarubicin", 3],
        ["Ifosfamide", "Moderate", "check21", "Ifosfamide", 3],
        ["lnotuzumab-ozogamicin", "Low", "check56", "lnotuzumab-ozogamicin", 2],
        ["Ipilimumab", "Minimal", "check96", "Ipilimumab", 1],
        ["Irinotecan", "Moderate", "check22", "Irinotecan", 3],
        [
          "lrinotecan peg-liposomal",
          "Moderate",
          "check23",
          "lrinotecan peg-liposomal",
          3,
        ],
        ["lsatuximab", "Low", "check57", "lsatuximab", 2],
        ["Ixabepilone", "Low", "check58", "Ixabepilone", 2],
        [
          "Loncastuximab-tesirine",
          "Low",
          "check59",
          "Loncastuximab-tesirine",
          2,
        ],
        ["Lurbinectedin", "Low", "check24", "Lurbinectedin", 3],
        ["Margetuximab", "Low", "check60", "Margetuximab", 2],
        ["Melphalan-flufenamide", "Low", "check61", "Melphalan-flufenamide", 2],
        ["Methotrexate", "Low", "check62", "Methotrexate", 2],
        [
          "Mirvetuximab-soravtansine",
          "Low",
          "check63",
          "Mirvetuximab-soravtansine",
          2,
        ],
        ["Mitomycin", "Low", "check64", "Mitomycin", 2],
        ["Mitoxantrone", "Low", "check65", "Mitoxantrone", 2],
        ["Mosunetuzumab", "Minimal", "check97", "Mosunetuzumab", 1],
        ["Moxetumomab-pasudotox", "Low", "check66", "Moxetumomab-pasudotox", 2],
        ["Naxitamab", "Moderate", "check25", "Naxitamab", 3],
        ["Necitumumab", "Low", "check67", "Necitumumab", 2],
        ["Nelarabine", "Low", "check68", "Nelarabine", 2],
        ["Nivolumab", "Minimal", "check98", "Nivolumab", 1],
        ["Obinutuzumab", "Minimal", "check99", "Obinutuzumab", 1],
        ["Ofatumumab", "Minimal", "check100", "Ofatumumab", 1],
        ["Oxaliplatin", "Moderate", "check26", "Oxaliplatin", 3],
        ["Paclitaxel", "Low", "check69", "Paclitaxel", 2],
        [
          "Paclitaxel nab-albumin",
          "Low",
          "check70",
          "Paclitaxel nab-albumin",
          2,
        ],
        ["Panitumumab", "Low", "check71", "Panitumumab", 2],
        ["Pembrolizumab", "Minimal", "check101", "Pembrolizumab", 1],
        ["Pemetrexed", "Low", "check72", "Pemetrexed", 2],
        ["Pertuzumab", "Low", "check73", "Pertuzumab", 2],
        ["Pixantrone", "Minimal", "check102", "Pixantrone", 1],
        [
          "Polatuzumab-vedotin",
          "Minimal",
          "check103",
          "Polatuzumab-vedotin",
          1,
        ],
        ["Pralatrexate", "Minimal", "check104", "Pralatrexate", 1],
        ["Ramucirumab", "Minimal", "check105", "Ramucirumab", 1],
        ["Rituximab", "Minimal", "check106", "Rituximab", 1],
        ["Romidepsin", "Moderate", "check27", "Romidepsin", 3],
        [
          "Sacituzumab-govitecan",
          "Moderate",
          "check28",
          "Sacituzumab-govitecan",
          4,
        ],
        ["Streptozocin", "High", "check6", "Streptozocin", 5],
        ["Tafasitamab", "Low", "check74", "Tafasitamab", 2],
        ["Tagraxofusp", "Low", "check75", "Tagraxofusp", 2],
        ["Teclistamab", "Low", "check76", "Teclistamab", 2],
        ["Temsirolimus", "Low", "check77", "Temsirolimus", 2],
        ["Temozolomide", "Moderate", "check29", "Temozolomide", 3],
        ["Thiotepa", "Moderate", "check30", "Thiotepa", 3],
        ["Tisagenlecleucel", "Low", "check78", "Tisagenlecleucel", 2],
        ["Tisotumab-vedotin", "Low", "check79", "Tisotumab-vedotin", 2],
        ["Topotecan", "Low", "check80", "Topotecan", 2],
        ["Trabectedin", "Moderate", "check31", "Trabectedin", 3],
        ["Trastuzumab", "Minimal", "check107", "Trastuzumab", 1],
        [
          "Trastuzumab-deruxtecan",
          "Moderate",
          "check32",
          "Trastuzumab-deruxtecan",
          4,
        ],
        ["Trastuzumab-emtansine", "Low", "check81", "Trastuzumab-emtansine", 2],
        ["Tremelimumab", "Minimal", "check108", "Tremelimumab", 1],
        ["Vinflunine", "Low", "check82", "Vinflunine", 2],
        ["Vinblastine", "Minimal", "check109", "Vinblastine", 1],
        ["Vincristine", "Minimal", "check110", "Vincristine", 1],
        ["Vinorelbine", "Minimal", "check111", "Vinorelbine", 1],
      ],
      chemotypesDropdown: [],
      Inputtext: "",
      showList: false,
      noMedicinesSelected: false,
      OverrideText: "Override?",
      redOpacitylevel: "more-opacity",
      orangeOpacitylevel: "more-opacity",
      yellowOpacitylevel: "more-opacity",
      greenOpacitylevel: "more-opacity",
      redOpacitylevelLight: "less-opacity",
      orangeOpacitylevelLight: "less-opacity",
      yellowOpacitylevelLight: "less-opacity",
      greenOpacitylevelLight: "less-opacity",
      RiskByChemoPage: true,
      viewlistoverride: false,
      removeallMedicines: false,
      highestPriority: 0,
      currPriority: 0,
      errorFlag: false,
      submitted: false,
      druglistchanged: false,
      placeholder: "Scroll or start typing here to search the list...",
      errorDays: null,
    };
  },

  computed: {
    ...mapGetters("userData", ["userData"]),
  },

  mounted: function () {
    this.loadData();
    this.scrollToTop();
    for (var i = 0; i < this.drugsList.length; i++) {
      var obj = {
        medicineName: this.drugsList[i][0],
        medicineClass: "check" + i.toString(),
        medicineRange: this.drugsList[i][1],
        medicineNameSmall: this.drugsList[i][3],
        medicineRank: this.drugsList[i][4],
      };
      this.chemotypesDropdown.push(obj);
    }
    this.drugsList = [];
    this.drugsList = this.chemotypesDropdown;

    if (
      this.userData.selectedMedicines === undefined ||
      this.userData.selectedMedicines.length === 0
    ) {
      this.userData.selectedMedicines = [];
      this.highestPriority = 1;
      this.isCarboplatin = false;
    } else {
      this.Inputtext =
        this.userData.selectedMedicines.length === 4
          ? "You’ve selected 4 agents."
          : "";
      this.removeallMedicines = true;
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.updateViewAfterChange();

    this.$nextTick(function () {
      document.querySelector(".page-content").addEventListener("click", () => {
        if (!event.target.classList.contains("blockClickEvent")) {
          this.$nextTick(function () {
            this.showList = false;
            this.setInputText();
          });
        }
      });
    });
  },
  methods: {
    ...mapMutations("userData", ["saveUserData"]),

    handleDropdownChange() {
      if (!this.selectedOption) {
        this.errorDays = "Valid entry is required.";
      } else {
        this.errorDays = null;
      }
    },

    setInputText: function () {
      this.Inputtext =
        this.userData.selectedMedicines.length === 4
          ? "You’ve selected 4 agents."
          : "";
    },

    done: function () {
      this.showList = false;
      this.setInputText();
    },

    handleResize: function () {
      setTimeout(() => {
        if (this.Inputtext !== "" && this.Inputtext !== undefined) {
          if (window.innerWidth < 960) {
            this.Inputtext =
              this.userData.selectedMedicines.length === 4
                ? "You’ve selected 4 agents."
                : this.userData.selectedMedicines[
                    this.userData.selectedMedicines.length - 1
                  ].medicinesm;
          } else {
            this.Inputtext =
              this.userData.selectedMedicines.length === 4
                ? "You’ve selected 4 agents."
                : this.userData.selectedMedicines[
                    this.userData.selectedMedicines.length - 1
                  ].medicine;
          }
        }
        this.placeholder =
          window.innerWidth < 960
            ? "Scroll or type to search the list..."
            : "Scroll or start typing here to search the list...";
      }, 200);
    },

    handleSubmit(e) {
      this.submitted = true;
      var drugList = "";
      for (var j = 0; j < this.userData.selectedMedicines.length; j++) {
        drugList = drugList + this.userData.selectedMedicines[j].medicine;
        if (j < this.userData.selectedMedicines.length - 1) {
          drugList = drugList + ", ";
        }
      }
        if(this.druglistchanged ){ this.tagUserInput('chemoType', 'chemoEmtoLevel', drugList)};
       if(this.userData.chemoDays !== this.selectedOption){  this.tagUserInput('cycle', 'chemoEmtoLevel', this.selectedOption)};
      this.saveData();

      if (!this.selectedOption || this.userData.selectedMedicines.length == 0) {
        this.handleDropdownChange();
       this.scrollToError();
        return false;
      } else {
        return true;
      }
    },

    saveData: function () {
      this.userData.chemoDays = this.selectedOption;
      this.userData.highestPriority = this.highestPriority;
    },

    loadData: function () {
      this.selectedOption = this.userData.chemoDays;
      this.highestPriority = this.userData.highestPriority;
    },

    // set checkboxes values
    refreshListData: function () {
      setTimeout(() => {
        for (var i = 0; i < this.chemotypesDropdown.length; i++) {
          if (
            document.getElementsByClassName(
              this.chemotypesDropdown[i].medicineClass
            )[0] !== undefined
          ) {
            document.getElementsByClassName(
              this.chemotypesDropdown[i].medicineClass
            )[0].checked = false;
          }
        }
        for (var j = 0; j < this.userData.selectedMedicines.length; j++) {
          // gives error
          try {
            if (this.userData.selectedMedicines[j] !== undefined) {
              document.getElementsByClassName(
                this.userData.selectedMedicines[j].medicineClass
              )[0].checked = true;
            }
          } catch (err) {
            console.log(err.message);
          }
        }
      }, 200);
    },

    showDropdown: function () {
      this.Inputtext = "";
      this.searchMedicine();
      this.showList = true;
    },

    showDropdownOnclick: function () {
      this.Inputtext = "";
      this.showList = !this.showList;
      this.searchMedicine();
      if (!this.showList) {
        this.setInputText();
      }
    },

    removeMedicine: function (medName, medRange, className) {
      this.druglistchanged = true;
      for (var i = 0; i < this.userData.selectedMedicines.length; i++) {
        if (this.userData.selectedMedicines[i].medicineClass === className) {
          this.userData.selectedMedicines.splice(i, 1);
          if (this.userData.selectedMedicines.length === 0) {
            this.Inputtext = "";
          } else {
            this.setInputText();
          }
          this.highestPriority = 0;
          this.updateArrowsAndMedicines();
          document.getElementsByClassName(className)[0].checked = false;
          return true;
        }
      }
      this.updateArrowsAndMedicines();
    },

    clickedMedicine: function (
      medName,
      medRange,
      className,
      medNameSmall,
      medRank
    ) {
      for (var i = 0; i < this.userData.selectedMedicines.length; i++) {
        if (this.userData.selectedMedicines[i].medicineClass === className) {
          this.userData.selectedMedicines.splice(i, 1);
          if (this.userData.selectedMedicines.length === 0) {
            this.Inputtext = "";
          } else {
            this.Inputtext =
              window.innerWidth <= 767
                ? this.userData.selectedMedicines[
                    this.userData.selectedMedicines.length - 1
                  ].medicinesm
                : this.userData.selectedMedicines[
                    this.userData.selectedMedicines.length - 1
                  ].medicine;
          }
          this.highestPriority = 0;
          this.updateArrowsAndMedicines();
          return true;
        }
      }

      if (this.userData.selectedMedicines.length === 4) {
        document.getElementsByClassName(className)[0].checked = false;
        this.Inputtext = "You’ve selected 4 agents.";
        this.showList = false;
        return 0;
      } else {
        this.Inputtext = window.innerWidth <= 767 ? medNameSmall : medName;
        var medicinename = {
          medicine: medName,
          medicineClass: className,
          medicineRange: medRange,
          medicinesm: medNameSmall,
          medicineRank: medRank,
          rangeOverride: false,
        };
        this.chemotypesDropdown = this.drugsList;
        this.refreshListData();
        this.userData.selectedMedicines.push(medicinename);
        this.noMedicinesSelected = false;
        this.OverrideText = "Override?";
        this.removeallMedicines = true;
      }
      this.updateArrowsAndMedicines();
    },

    // point to highest risk med for results
    findResultsIndexFunc: function (rank) {
      this.userData.currResultsIndex =
        this.userData.selectedMedicines.findIndex(
          (item) => item.medicineRank === rank
        );
    },

    // for current selected med
    findIndexFunc: function (range) {
      this.userData.currResultsIndex =
        this.userData.selectedMedicines.findIndex(
          (item) => item.medicineRange === range
        );
      this.currPriority =
        this.userData.selectedMedicines[
          this.userData.currResultsIndex
        ].medicineRank;
    },

    updateArrowsAndMedicines: function () {
      if (this.userData.selectedMedicines.length === 0) {
        this.currPriority = 0;
        this.highestPriority = 0;
        this.OverrideText = "Override?";
        this.removeallMedicines = false;
      }

      for (
        var medicineNo = 0;
        medicineNo < this.userData.selectedMedicines.length;
        medicineNo++
      ) {
        if (
          this.userData.selectedMedicines[medicineNo].medicineRange === "High"
        ) {
          this.highestPriority =
            this.userData.selectedMedicines[medicineNo].medicineRank;
          this.userData.highestPriority = this.highestPriority;
          this.findResultsIndexFunc(
            this.userData.selectedMedicines[medicineNo].medicineRank
          );
          this.findIndexFunc(
            this.userData.selectedMedicines[medicineNo].medicineRange
          );
        } else if (
          this.userData.selectedMedicines[medicineNo].medicineRange ===
          "Moderate"
        ) {
          if (
            this.highestPriority <
            this.userData.selectedMedicines[medicineNo].medicineRank
          ) {
            this.highestPriority =
              this.userData.selectedMedicines[medicineNo].medicineRank;
            this.userData.highestPriority = this.highestPriority;
            this.findResultsIndexFunc(
              this.userData.selectedMedicines[medicineNo].medicineRank
            );
          }
          this.findIndexFunc(
            this.userData.selectedMedicines[medicineNo].medicineRange
          );
        } else if (
          this.userData.selectedMedicines[medicineNo].medicineRange === "Low"
        ) {
          if (
            this.highestPriority <
            this.userData.selectedMedicines[medicineNo].medicineRank
          ) {
            this.highestPriority =
              this.userData.selectedMedicines[medicineNo].medicineRank;
            this.userData.highestPriority = this.highestPriority;
            this.findResultsIndexFunc(
              this.userData.selectedMedicines[medicineNo].medicineRank
            );
          }
          this.findIndexFunc(
            this.userData.selectedMedicines[medicineNo].medicineRange
          );
        } else if (
          this.userData.selectedMedicines[medicineNo].medicineRange ===
          "Minimal"
        ) {
          if (
            this.highestPriority <
            this.userData.selectedMedicines[medicineNo].medicineRank
          ) {
            this.highestPriority =
              this.userData.selectedMedicines[medicineNo].medicineRank;
            this.userData.highestPriority = this.highestPriority;
            this.findResultsIndexFunc(
              this.userData.selectedMedicines[medicineNo].medicineRank
            );
          }
          this.findIndexFunc(
            this.userData.selectedMedicines[medicineNo].medicineRange
          );
        }
      }
    },

    overrideClick: function () {},

    changeMedicineRange: function (medicineOldRange) {
      this.druglistchanged = true;
      if (medicineOldRange === "High") {
        this.highMedicine = "white-opac";
        this.redOpacitylevel = "more-opacity";
        this.redOpacitylevelLight = "less-opacity";
      } else if (medicineOldRange === "Moderate") {
        this.moderateMedicine = "white-opac";
        this.orangeOpacitylevel = "more-opacity";
        this.orangeOpacitylevelLight = "less-opacity";
      } else if (medicineOldRange === "Low") {
        this.normalMedicine = "yellow-opac";
        this.yellowOpacitylevel = "more-opacity";
        this.yellowOpacitylevelLight = "less-opacity";
      } else if (medicineOldRange === "Minimal") {
        this.minimalMedicine = "white-opac";
        this.greenOpacitylevel = "more-opacity";
        this.greenOpacitylevelLight = "less-opacity";
      }
    },

    highCheckboxClick: function () {
      if (this.userData.selectedMedicines.length !== 0) {
        var lastMedicineNo = this.userData.selectedMedicines.length;
        this.changeMedicineRange(
          this.userData.selectedMedicines[lastMedicineNo - 1].medicineRange
        );
        this.userData.selectedMedicines[
          lastMedicineNo - 1
        ].rangeOverride = true;
        this.userData.selectedMedicines[lastMedicineNo - 1].medicineRange =
          "High";
        this.OverrideText = "> 90%";
        this.userData.selectedMedicines[
          lastMedicineNo - 1
        ].rangeOverride = true;
        this.updateArrowsAndMedicines();
        this.viewlistoverride = false;
      }
    },

    moderateCheckboxClick: function () {
      if (this.userData.selectedMedicines.length !== 0) {
        var lastMedicineNo = this.userData.selectedMedicines.length;
        this.changeMedicineRange(
          this.userData.selectedMedicines[lastMedicineNo - 1].medicineRange
        );
        this.userData.selectedMedicines[lastMedicineNo - 1].medicineRange =
          "Moderate";
        this.OverrideText = "30-90%";
        this.userData.selectedMedicines[
          lastMedicineNo - 1
        ].rangeOverride = true;
        this.updateArrowsAndMedicines();
        this.viewlistoverride = false;
      }
    },

    normalCheckboxClick: function () {
      if (this.userData.selectedMedicines.length !== 0) {
        var lastMedicineNo = this.userData.selectedMedicines.length;
        this.changeMedicineRange(
          this.userData.selectedMedicines[lastMedicineNo - 1].medicineRange
        );
        this.userData.selectedMedicines[lastMedicineNo - 1].medicineRange =
          "Low";
        this.OverrideText = "10-30%";
        this.userData.selectedMedicines[
          lastMedicineNo - 1
        ].rangeOverride = true;
        this.updateArrowsAndMedicines();
        this.viewlistoverride = false;
      }
    },

    minimalCheckboxClick: function () {
      if (this.userData.selectedMedicines.length !== 0) {
        var lastMedicineNo = this.userData.selectedMedicines.length;
        this.changeMedicineRange(
          this.userData.selectedMedicines[lastMedicineNo - 1].medicineRange
        );
        this.userData.selectedMedicines[lastMedicineNo - 1].medicineRange =
          "Minimal";
        this.OverrideText = "< 10%";
        this.userData.selectedMedicines[
          lastMedicineNo - 1
        ].rangeOverride = true;
        this.updateArrowsAndMedicines();
        this.viewlistoverride = false;
      }
    },

    searchMedicine: function () {
      var userinput;
      this.chemotypesDropdown = [];
      userinput = new RegExp(this.Inputtext, "i");
      if (this.Inputtext !== "") {
        for (var x = 0; x < this.drugsList.length; x++) {
          if (this.drugsList[x].medicineName.match(userinput)) {
            this.chemotypesDropdown.push(this.drugsList[x]);
          }
        }
      } else {
        for (var j = 0; j < this.drugsList.length; j++) {
          this.chemotypesDropdown.push(this.drugsList[j]);
          9;
        }
      }

      this.refreshListData();
    },

    updateViewAfterChange: function () {
      setTimeout(() => {
        for (
          var medicineNo = 0;
          medicineNo < this.userData.selectedMedicines.length;
          medicineNo++
        ) {
          if (this.userData.selectedMedicines[medicineNo] !== undefined) {
            document.getElementsByClassName(
              this.userData.selectedMedicines[medicineNo].medicineClass
            )[0].checked = true;
          }
        }
      }, 100);

      this.updateArrowsAndMedicines();

      if (this.userData.selectedMedicines.length === 0) {
        this.OverrideText = "Override?";
      }
    },

    undoOverride: function (checkboxid) {
      this.OverrideText = "Override";
      document.getElementById(checkboxid).checked = false;
      document.getElementById(checkboxid).disabled = true;
      var lastMedIndex = this.userData.selectedMedicines.length - 1;
      for (var i = 0; i < this.drugsList.length; i++) {
        if (
          this.drugsList[i].medicineClass ===
          this.userData.selectedMedicines[lastMedIndex].medicineClass
        ) {
          this.userData.selectedMedicines[lastMedIndex].medicineRange =
            this.drugsList[i].medicineRange;
          this.userData.selectedMedicines[lastMedIndex].rangeOverride = false;
        }
      }
      this.updateViewAfterChange();
    },
  },
};
</script>


<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";

.chemo {
  font-family: "Arial Narrow", Arial, sans-serif !important;
}

.custom-dropdown {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 48px;
  line-height: 47px;
  padding: 0;
  border: none;
  border-radius: 4px;
  background-color: transparent;
}

.heading {
  color: #499623;
  font-size: 21px;
  font-weight: 700;
  padding: 28px 28px 23px;
  @media (max-width: $screen-sm-max) {
    padding: 28px 15px 23px;
  }
  @media (max-width: $screen-xs-max) {
    padding: 18px 8px 15px;
    line-height: 1.2;
  }
}

.alphabet-A {
  color: #0078ae;
  font-size: 21px;
  padding-left: 28px;
  display: inline-block;
  @media (max-width: $screen-md-max) {
    padding-left: 20px;
  }
  @media (max-width: $screen-md-max) {
    padding-left: 15px;
  }
  @media (max-width: $screen-xs-max) {
    padding-left: 8px;
    font-size: 20px;
  }
}
.Select-agents {
  font-size: 21px;
  display: inline-block;
  color: #0078ae;
  padding-left: 11px;
  line-height: 1.2;
  width: 546px;
  @media (max-width: $screen-md-max) {
    width: auto;
    margin: -28px 15px 0 30px;
  }
  @media (max-width: $screen-xs-max) {
    padding-left: 5px;
    padding-right: 10px;
    font-size: 20px;
    margin: -27px 0 0 26px;
    line-height: 1.2;
  }
}

.input-field {
  width: 496px;
  height: 48px;
  background-color: #ebecec;
  border: 1px solid #0078ae;
  border-radius: 4px;
  margin: 20px 0 0 44px;
}
.cycle {
  padding: 25px 17px 20px 28px;
  margin-top: 0px;
  display: inline-block;
  @media (max-width: $screen-md-max) {
    padding: 25px 17px 47px 20px;
  }
  @media (max-width: $screen-sm-max) {
    margin-top: 20px;
    padding: 25px 17px 47px 15px;
  }
  @media (max-width: $screen-xs-max) {
    padding: 20px 8px 17px;
    width: 100%;
  }
}

.b-text {
  width: 265px;
  display: inline-block;
  vertical-align: middle;
  @media (max-width: $screen-xs-max) {
    width: 235px;
  }
}
.select-container {
  display: inline-block;
  vertical-align: middle;
  width: 134px;
  background-color: transparent;
  height: 48px;
  @media (max-width: $screen-sm-max) {
    margin: 15px auto 15px 0;
  }
  @media (max-width: $screen-xs-max) {
    margin: 21px 10px 31px 31px;
  }
}

.alphabet-B {
  font-size: 21px;
  color: #0078ae;
  padding-left: 28px;
  display: inline-block;
  @media (max-width: $screen-xs-max) {
    font-size: 20px;
    padding-left: 8px;
  }
}
.cycle-number {
  display: inline-block;
  color: #0078ae;
  font-size: 21px;
  padding-left: 11px;
  @media (max-width: $screen-xs-max) {
    padding-left: 5px;
  }
}
.input-field-2 {
  margin-top: 55px;
  width: 134px;
  height: 48px;
  background-color: #ebecec;
  border: 1px solid #0078ae;
  border-radius: 4px;
}

.five-divs {
  margin-left: 24px;
  @media (max-width: $screen-md-max) {
    display: block;
    margin: 125px 0px 40px 200px;
  }
  @media (max-width: $screen-sm-max) {
    width: 295px;
    margin: 125px auto 12px auto;
  }
  @media (max-width: $screen-xs-max) {
    margin: 125px auto 12px auto;
  }
}

.left-text {
  width: 155px;
}
.number {
  float: right;
  font-size: 24px;
  font-weight: 600;
  margin-top: -35px;
}

.guideline {
  height: 86px;
  color: #0078ae;
  background-color: #cce4ef;
  padding: 20px !important;
  font-size: 20px !important;
  line-height: 1.2;
  text-align: center;
}

.color-chart-section {
  font-family: $Primary-fontName;
  width: 295px;
  padding: 15px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 4px;
}
.high {
  background-color: #ff0000;
  color: #fff;
  height: 73px;
}
.moderate {
  background-color: #e5ac00;
  color: #fff;
  height: 73px;
}
.low {
  background-color: #ffff00;
  color: #000;
  height: 73px;
}

.minimal {
  background-color: #499623;
  color: #fff;
  height: 73px;
  margin-bottom: 0 !important;
}

.red-opacity {
  background-color: #ffcccc;
  color: #9d9fa2;
}

.orange-opacity {
  background-color: #f6e2a6;
  color: #9d9fa2;
}

.yellow-opacity {
  background-color: #ffffa6;
  color: #9d9fa2;
}

.green-opacity {
  background-color: #dbead3;
  color: #9d9fa2;
}

.your-selection {
  font-size: 21px;
  color: #0078ae;
  font-weight: 700;
  width: 164px;
  height: 112px;
  background-color: #cce4ef;
  padding: 24px 22px;

  @media (max-width: $screen-md-max) {
    height: 156px;
    padding-top: 45px;
  }
  @media (max-width: $screen-sm-max) {
    height: 206px;
    padding-top: 75px;
  }
  @media (max-width: $screen-xs-max) {
    font-size: 21px;
    width: 100% !important;
    padding: 0px;
    height: 50px;
    text-align: center;
    padding: 10px;
  }
}
.result-area {
  background-color: #ebecec;
  width: 794px;
  height: 112px;
  @media (max-width: $screen-md-max) {
    height: 156px;
    width: 554px;
    margin-bottom: 20px;
  }
  @media (max-width: $screen-sm-max) {
    height: 206px;
    margin-bottom: 0;
    width: 374px;
  }

  @media (max-width: $screen-xs-max) {
    height: 216px;
    width: 100% !important;
  }
}
.result-area-inner {
  height: 88px;
  @media (max-width: $screen-md-max) {
    height: 116px;
  }
  @media (max-width: $screen-sm-max) {
    height: 165px;
  }
}
.selection-bottom-text {
  color: #4d4d4d;
  font-size: 13px;
  font-style: italic;
  height: 24px;
  padding: 0 13px 16px;
  margin-top: -8px;
  line-height: 1.2;
  @media (max-width: $screen-md-max) {
    margin-top: 0;
  }
}

.select-button {
  width: 496px;
  font-size: 23px;
  padding: 10px 0 0 42px;
  @media (max-width: $screen-xs-max) {
    width: 317px;
    padding: 10px 8px 0 36px;
  }
}

.custom-dropdown {
  border-radius: 4px !important;
}

.custom-dropdown .selected-item {
  background-color: #ebecec;
  border: 1px solid #0078ae;
  border-radius: 4px !important;
  color: #000;
  font-size: 23px;
  padding-right: 55px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 !important;
  text-align: center;
  @media (max-width: $screen-sm-max) {
    font-size: 16px;
  }
}

.custom-dropdown.grey-text .selected-item {
  font-size: 20px;
}

.grey-box {
  background-color: #0078ae;
  border-radius: 0 4px 4px 0;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
}

.custom-dropdown .selected-item:after {
  position: absolute;
  top: 22px;
  right: 16px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-color: #fff transparent transparent transparent;
}
.custom-dropdown .dropdown-menu {
  border-radius: 0px 0px 4px 4px;
  overflow: hidden;
  border: 1px solid #0078ae;
  position: absolute;
  background-color: #fff;
  left: 0;
  margin-top: -1px;
  right: 0;
  z-index: 1;
  width: 100%;
}
.custom-dropdown .dropdown-menu div {
  color: black;
  cursor: pointer;
  font-family: "Arial", Helvetica, sans-serif !important;
  user-select: none;
}
.custom-dropdown .dropdown-menu div:hover {
  border: none;
  background-color: #0078ae;
  color: #fff;
}

$input-border-radius: 6px;
@mixin show-dropdown {
  z-index: 101;
  display: block;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.input-inline-button {
  display: inline-block;
  position: absolute;
  z-index: 4;
  right: 0;
  top: 0;
  text-decoration: none;
  padding: 0.5em;
  color: #999;
}
.list-group-item {
  &.active {
    background: #eee;
    border-color: #eee;
    color: #333;
  }
}
.icon-plus {
  &:after {
    content: "➕";
  }
}

.typeahead {
  position: relative;
  .input-group-addon {
    &:after {
      content: ":";
      display: inline;
    }
  }
  &-dropdown {
    display: none;
    // border-top: 1px solid #aaa;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: -2px;
    transition-delay: 0.75s;
    transition: display 1s;
    &:hover {
      display: block;
    }
    > .list-group-item {
      &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        &:not(:last-child) {
          border-radius: 0;
        }
      }
    }
    &:focus {
      @include show-dropdown;
    }
  }
  &-input {
    z-index: 1;
    position: relative;
    &.form-control {
      border-top-right-radius: $input-border-radius !important;
      border-bottom-right-radius: $input-border-radius !important;
    }
    &:focus {
      z-index: 3;
      padding-bottom: 8px;
      height: 36px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0 !important;
      + .typeahead-dropdown {
        @include show-dropdown;
      }
    }
  }
}

.hover-reveal-group {
  .hover-reveal {
    visibility: hidden;
  }
  &:hover {
    .hover-reveal {
      visibility: visible;
    }
  }
}

.md-display {
  display: none;
  @media (max-width: $screen-md-max) {
    display: block;
  }
}

.lg-display {
  display: block;
  @media (max-width: $screen-md-max) {
    display: none;
  }
}

.error-outer {
  min-height: 67px;
  padding-top: 29px;
  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
}

.error-outer.med-error-outer {
  padding-top: 33px;
  @media (max-width: $screen-md-max) {
    padding-left: 42px;
  }
  @media (max-width: $screen-sm-max) {
    padding-left: 12px;
  }
  @media (max-width: $screen-xs-max) {
    padding-left: 0;
  }
}

.error-outer.days-error-outer {
  display: inline-block;
  vertical-align: middle;
  padding-top: 20px;
  @media (max-width: $screen-sm-max) {
    padding: 0 0 20px 31px;
    min-height: 20px;
    margin-top: -37px;
  }
  @media (max-width: $screen-xs-max) {
    padding-left: 0;
  }
}

.error-msg {
  color: #ff0000;
  font-size: 17px;
  min-height: 25px;
}

.days-error {
  margin: 0 0 0 14px;
  @media (max-width: $screen-sm-max) {
    margin: 0 0 0 28px;
  }
  @media (max-width: $screen-xs-max) {
    margin: 0 0 0 33px;
  }
}

.medicine-error {
  margin: 0 0 0 60px;
  @media (max-width: $screen-md-max) {
    margin: 0 0 0 71px;
  }
  @media (max-width: $screen-sm-max) {
    margin: 0 0 0 60px;
  }
  @media (max-width: $screen-xs-max) {
    margin: 0 0 0 31px;
  }
}

.arrows-div {
  width: 200px;
  height: 200px;
  margin-left: -200px;
  @media (max-width: $screen-md-max) {
    display: none;
  }
}

.arrows {
  position: sticky;
  background-repeat: no-repeat;
  right: 468px;
  width: 111px;
}

.red-arrow {
  background-image: url("../images/icon-svg/red_arrow.svg");
  height: 96px;
  margin-top: 95px;
  width: 115px;
  margin-left: 111px;
}

.orange-arrow {
  background-image: url("../images/icon-svg/orange_arrow.svg");
  height: 63px;
  margin-top: 174px;
  width: 115px;
  margin-left: 111px;
}

.yellow-arrow {
  background-image: url("../images/icon-svg/yellow_arrow.svg");
  height: 134px;
  margin-top: 178px;
  width: 115px;
  margin-left: 111px;
}

.green-arrow {
  background-image: url("../images/icon-svg/green_arrow.svg");
  height: 211px;
  margin-top: 177px;
  width: 115px;
  margin-left: 111px;
}

// ************************************ drugs combo box***********************************

.drugs-section {
  width: 100%;
}

.done-button {
  color: #0078ae;
  cursor: pointer;
  float: right;
  font-size: 20px;
  font-weight: 700;
  margin-right: 16px;
  margin-top: -16px;
  position: relative;
  z-index: 10;

  @media (max-width: $screen-xs-max) {
    font-size: 15px;
    margin-right: 40px;
    margin-top: -34px;
  }
}

.dropdown-div {
  margin: 25px auto 0 57px;
  width: 496px;
  max-height: 26px;

  @media (max-width: $screen-md-max) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: $screen-sm-max) {
    padding-left: 0;
    width: 399px;
  }
  @media (max-width: $screen-xs-max) {
    margin: 20px auto 0 0;
    padding-left: 31px;
    width: 314px;
  }
}

.dropdown-textbox-div {
  height: 26px;
  padding-right: 50px;
  width: 496px;

  @media (max-width: $screen-sm-max) {
    width: 399px;
  }

  @media (max-width: $screen-xs-max) {
    height: 49px;
    width: 282px;
  }
}

.dropdown-textbox,
.dropdown-textbox:hover,
.dropdown-textbox:focus {
  font-family: "Arial Narrow", Arial, sans-serif !important;
  border: 1px #0078ae solid;
  border-radius: 4px;
  background-color: #ebecec;
  color: #000 !important;
  font-size: 20px;
  height: 49px;
  padding-left: 12px;
  padding-right: 50px;
  width: 496px;
  outline: 0 !important;
  box-shadow: none !important;

  @media (max-width: $screen-sm-max) {
    font-size: 16px;
    width: 399px;
  }
  @media (max-width: $screen-xs-max) {
    padding-right: 54px;
    width: 282px;
  }
}

::-webkit-input-placeholder {
  font-family: "Arial Narrow", Arial, sans-serif !important;
  color: #787878 !important;
  @media (max-width: $screen-xs-max) {
    font-size: 15px;
  }
}
:-moz-placeholder {
  font-family: "Arial Narrow", Arial, sans-serif !important;
  color: #787878 !important;
  @media (max-width: $screen-xs-max) {
    font-size: 15px;
  }
}
::-moz-placeholder {
  font-family: "Arial Narrow", Arial, sans-serif !important;
  color: #787878 !important;
  @media (max-width: $screen-xs-max) {
    font-size: 15px;
  }
}
:-ms-input-placeholder {
  font-family: "Arial Narrow", Arial, sans-serif !important;
  color: #787878 !important;
  @media (max-width: $screen-xs-max) {
    font-size: 15px;
  }
}

.dropdown-textbox:-ms-input-placeholder {
  font-family: "Arial Narrow", Arial, sans-serif !important;
  color: #787878 !important;
  @media (max-width: $screen-xs-max) {
    font-size: 15px;
  }
}

.dropdown-button {
  background-image: url("../images/icon-svg/drugs_dropdown_icon.svg");
  background-repeat: no-repeat;
  float: right;
  height: 49px;
  margin-right: 1px;
  margin-top: -26px;
  position: relative;
  width: 49px;
  @media (max-width: $screen-sm-max) {
    margin-right: -1px;
  }
  @media (max-width: $screen-xs-max) {
    background-image: url("../images/icon-svg/drugs_dropdown_icon_xs.svg");
    margin-right: 0;
    margin-top: -49px;
    width: 36px;
  }
}

.dropdown-list::-webkit-scrollbar {
  border: 1px #0078ae solid;
  border-bottom: 0;
  border-right: 0;
  border-top: 0;
  width: 28px;
}

.dropdown-listview::-webkit-scrollbar-button,
.dropdown-list::-webkit-scrollbar-button:start {
  background-color: #ccc;
  background-image: url("../images/icon-svg/scroll_btn_up.svg");
  background-repeat: no-repeat;
  border-bottom: 1px #0078ae solid;
  height: 29px;
  margin-top: 0;
  width: 29px;
}

.dropdown-list::-webkit-scrollbar-button:end {
  background-color: transparent;
  background-image: url("../images/icon-svg/scroll_btn_down.svg");
  background-repeat: no-repeat;
  height: 28px;
  width: 28px;
}

.dropdown-list::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-left: 1px #00f solid;
  width: 27px;
}

.dropdown-listview {
  margin: 21px 0 0 57px;
  max-height: 255px;
  width: 516px;
  position: absolute;
  z-index: 10;

  @media (max-width: $screen-md-max) {
    max-height: 150px;
    left: 50%;
    margin-left: -248px;
  }
  @media (max-width: $screen-sm-max) {
    width: 399px;
    margin: 8px auto;
    margin-left: -199px;
  }

  @media (max-width: $screen-xs-max) {
    padding-left: 0;
    left: 31px;
    margin-left: 0;
    width: 282px;
  }
}

.dropdown-listview .dropdown-list {
  background-color: #fff;
  border: 1px #0078ae solid;
  border-radius: 0 0 5px 5px;
  list-style-type: none;
  padding-left: 10px;
  max-height: 255px;
  overflow-x: none;
  overflow-y: scroll;
  width: 447px;
  position: absolute;
  z-index: 100;

  @media (max-width: $screen-md-max) {
    max-height: 150px;
    left: 0;
  }
  @media (max-width: $screen-sm-max) {
    width: 351px;
    margin: 13px 0;
  }

  @media (max-width: $screen-xs-max) {
    padding-left: 4px;
    width: 282px;
  }
}

.dropdown-listview label {
  color: #000;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 3px;
  margin-top: 2px;
  position: relative;
  width: 100%;
  z-index: 1;
  display: inline-block;

  @media (max-width: $screen-sm-max) {
    margin-right: 0;
  }
  @media (max-width: $screen-xs-max) {
    font-size: 14px;
    height: 31px;
  }

  .lbl-text {
    display: inline-block;
    line-height: 1.07;
    vertical-align: middle;
    width: 370px;

    @media (max-width: $screen-sm-max) {
      width: 275px;
    }

    @media (max-width: $screen-xs-max) {
      padding-left: 5px;
      width: 220px;
    }
  }
}

.dropdown-listview label:before {
  background-image: url("../images/icon-svg/drugs_checkbox.svg");
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 24px;
  left: 0;
  position: relative;
  top: 7px;
  width: 24px;
  z-index: -1;
  margin-right: 6px;
  //   vertical-align: middle;
  @media (max-width: $screen-xs-max) {
    left: 0;
    margin-right: 0;
  }
}

.dropdown-listview input[type="checkbox"]:checked + label:before {
  background-image: url("../images/icon-svg/drugs_checkbox_checked.svg");
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
}

.dropdown-listview input[type="checkbox"]:checked + label {
  font-weight: 700;
}

.del-icon {
  background-image: url("../images/icon-svg/cross.svg");
  background-repeat: no-repeat;
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.drug-name {
  font-size: 20px;
  @media (max-width: $screen-md-max) {
    font-size: 16px;
  }
}

.result-area .drugs-div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.result-area .flex-container {
  display: inline-block;
  vertical-align: middle;
  padding-right: 5px;
  margin: 2px 13px;
  @media (max-width: $screen-sm-max) {
    margin: 3px 13px;
  }
}
.result-area .flex-item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 10px;

  @media (max-width: $screen-sm-max) {
    margin: 0 0 0 7px;
  }
}
.result-area .del-icon.flex-item {
  margin: 0;
}

input[type="checkbox"] {
  display: none !important;
  opacity: 0;
}

.dropdown-textbox::placeholder {
  font-family: "Arial Narrow", Arial, sans-serif !important;
  color: #787878 !important;
  font-weight: 400;
  font-size: 20px;

  @media (max-width: $screen-xs-max) {
    font-size: 15px;
  }
}
</style>
