import Vuex from 'vuex'
import UserData from '@/store/userdata.js';

import {createStore} from 'vuex';

export default createStore({
  modules: {
    userData: new UserData(),
  }
})
