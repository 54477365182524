<template>

  <v-row class="recommendation" no-gutters>
    <v-col class="tool-border reduce-padding-all" cols="12">
      <v-col class="reduce-padding-all" cols="12">
        <cinv-header :page-title="pageTitle" :display-info="true"></cinv-header>
      </v-col>
      <v-col class="page-background reduce-padding-all" cols="12">
        <v-row class="page-content" no-gutters>
          <v-col class="reduce-padding" cols="12">
            <v-row class="body-sec" no-gutters>
              <v-col class="reduce-padding" cols="12">
                <div class="predispose">
                  Based on <b>MASCC/ESMO</b> emetogenicity level & CINV risk assessment, your patient has additional risk factors which predispose them to CINV. These should be considered when selecting their antiemetic treatment.
                </div>
              </v-col>
              <v-col class="reduce-padding" cols="12">
                <v-row no-gutters>
                  <v-col class="dial-col reduce-padding" lg="6" cols="12">
                    <v-row no-gutters>
                      <div class="DOP">
                        <b>
                          <span v-if="userData.highestPriority == 1">No routine prophylaxis is needed.</span>
                          <span v-if="userData.highestPriority == 2">5-HT<sub>3</sub> or DEX or DOP</span>
                          <span v-if="userData.highestPriority == 3 && (this.userData.priorityDial != 4)">5-HT<sub>3</sub> + DEX</span>
                          <span v-if="userData.highestPriority == 4 || (this.userData.priorityDial == 4)">5-HT<sub>3</sub> + DEX + NK<sub>1</sub></span>
                          <span v-if="userData.highestPriority == 5">5-HT<sub>3</sub> + DEX + NK<sub>1</sub> + OLZ</span>
                        </b>
                      </div>
                    </v-row>
                    <hr class="hr-lines"></hr>
                    <v-row no-gutters>
                      <div class="Chemotherapy"><b>Chemotherapy Emetogenicity Level*</b></div>
                      <div class="emetogenic">
                        <span v-if="userData.highestPriority == 1" class="min-emet">&lt;10%: Minimal Emetogenic</span>
                        <span v-if="userData.highestPriority == 2" class="low-emet">10 - 30%: Low Emetogenic</span>
                        <span v-if="userData.highestPriority == 3 || userData.highestPriority == 4" class="mod-emet">30 - 90%: Moderate Emetogenic</span>
                        <span v-if="userData.highestPriority == 5" class="high-emet">&gt;90%: High Emetogenic</span>
                      </div>
                      <div class="dial-div">
                        <risk-dial :risk="userData.priorityDial"></risk-dial>
                      </div>
                      <div class="guideline-text">Based on MASCC/ESMO Guidelines, the emetogenicity level of the regimen is related to the highest emetogenic agent selected.</div>
                      <div class="drugs-sec">
                        <b>Chemotherapy:</b> {{drugList}}
                      </div>
                    </v-row>
                  </v-col>

                  <div class="plus-image"></div>

                  <v-col class="graph-col reduce-padding" lg="6" cols="12">
                    <v-row no-gutters>
                      <div class="antiemetic-text" :class="{'high-risk-txt-only': userData.risk>43.6}">
                        <b>
                          <span v-if="userData.risk<43.7">No additional antiemetic is needed.</span>
                          <span class="add-antiemet" v-if="userData.risk>43.6">Consider adding a further antiemetic due to the patient's risk level.</span>
                        </b>
                      </div>
                    </v-row>
                    <hr class="hr-lines"></hr>
                    <v-row no-gutters>
                      <div class="Risk-Profile"><b>Patient Emetogenicity Risk Profile</b></div>
                      <div class="risk-div xs-div">
                        <div class="risk-text">
                          <div class="risk-perc" :class="{'high-perc': userData.risk>43.6}">{{userData.risk}}%</div>
                          <div class="risk-level">
                            <span v-if="userData.risk>43.6" class="high-level">High Risk</span>
                            <span v-if="userData.risk<43.7" class="low-level">Low Risk</span>
                          </div>
                        </div>
                      </div>
                      <div class="bars-div">
                        <risk-bars :risk="calcRisk"></risk-bars>
                      </div>
                      <div class="risk-div lg-div">
                        <div class="risk-text">
                          <div class="risk-perc" :class="{'high-perc': userData.risk>43.6}">{{userData.risk}}%</div>
                          <div class="risk-level">
                            <span v-if="userData.risk>43.6" class="high-level">High Risk</span>
                            <span v-if="userData.risk<43.7" class="low-level">Low Risk</span>
                          </div>
                        </div>
                      </div>

                      <div class="list">
                        <ul>
                          <li v-if="userData.riskProfile.age<60">Age is under 60</li>
                          <li v-if="userData.riskProfile.cinvDevelopmentRisk == 'Yes'">Expecting to develop CINV</li>
                          <li v-if="userData.riskProfile.morningSickness == 'Yes'">Morning sickness history</li>
                          <li v-if="userData.riskProfile.sleepTime == 'No'">&lt;7 hours sleep before chemotherapy</li>
                          <li v-if="userData.riskProfile.nonPrescribedDrugs == 'Yes'">Non-prescribed treatments taken after previous cycle</li>
                          <li v-if="userData.riskProfile.nauseaVomiting == 'Yes'">Vomiting episode in prior cycle</li>
                          <li v-if="userData.riskProfile.platinumBased == 'Yes'">Anthracycline or platinum chemotherapy</li>
                        </ul>
                      </div>
                    </v-row>
                  </v-col>

                </v-row>
              </v-col>
            </v-row>
            <cinv-navigation :nav-buttons="['back', 'links2', 'risk1' ,'next']" :to-screen="['riskProfile','patientsReport']" :color-bg="true" :blue-txt="true"></cinv-navigation>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
    </v-row>


</template>

<script>
/* eslint-disable */
import taggingMixin from '../mixins/taggingMixin';
import scrollMixin from "../mixins/scrollMixin";
import riskDial from '../components/riskDial.vue';
import riskBars from '../components/riskBars.vue';
import cinvHeader from '../components/cinvHeader.vue';
import cinvNavigation from '../components/cinvNavigation.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "recommendation",
  mixins: [taggingMixin, scrollMixin],
  components: {
    'cinvHeader': cinvHeader,
    'riskDial': riskDial,
    'riskBars': riskBars,
    'cinvNavigation': cinvNavigation,
  },

 data() {
    return {
      pageTitle: 'Recommendation',
      drugList: '',
      calcRisk: 0,
    };
  },

  computed: {
    ...mapGetters('userData', ['userData']),
  },

  mounted: function () {
    this.scrollToTop();

    var a = this.userData.riskProfile.age<60 ? 1 : 0;
    var b = this.userData.chemoDays == '2' ? 1 : 0;
    var c = this.userData.chemoDays == '7+' ? 1 : ( parseInt(this.userData.chemoDays)>2 ? 1 : 0);
    var d = this.userData.riskProfile.cinvDevelopmentRisk == 'Yes' ? 1 : 0;
    var f = this.userData.riskProfile.morningSickness == 'Yes' ? 1 : 0;
    var e = (this.userData.riskProfile.sleepTime == 'No') ? 1 : 0;
    var h = this.userData.riskProfile.nonPrescribedDrugs == 'Yes' ? 1: 0;
    var i = this.userData.riskProfile.nauseaVomiting == 'Yes' ? 1: 0;
    var g = this.userData.riskProfile.platinumBased == 'Yes' ? 1: 0;

    this.calcRisk = Math.round(1000 / ( 1 + Math.exp(-1*( 0 - 1.28 + a*(0.34) - b*(1.76) - c*(1.91) + d*(0.35) + e*(0.30) + f*(0.27) + g*(0.66) + h*(0.99) + i*(1.64) ) ) ) )/10;

    var emetRecom = this.userData.highestPriority == 1? 'No routine prophylaxis is needed.' : ( this.userData.highestPriority == 2? '5-HT3 RA or DEX or DOP' :
    (this.userData.highestPriority == 3 ? '5-HT3 RA + DEX' : (this.userData.highestPriority == 4? '5-HT3 RA + DEX + NK1 RA' : (this.userData.highestPriority == 5? '5-HT3 RA + DEX + NK1 RA +/- OLZ' : '') )));

    var emet = this.userData.highestPriority == 1? '<10%: Minimal Emetogenic' : ( this.userData.highestPriority == 2? '10 - 30%: Low Emetogenic' :
    ( (this.userData.highestPriority == 3 || this.userData.highestPriority == 4) ? '30 - 90%: Moderate Emetogenic' : (this.userData.highestPriority == 5? '>90%: High Emetogenic' : '') ));

    var riskLevel  = this.userData.risk>43.6? 'High Risk' : 'Low Risk';

    this.drugList = '';
    for (var j = 0; j < this.userData.selectedMedicines.length; j++) {
        this.drugList = this.drugList + this.userData.selectedMedicines[j].medicine;
        if ( j < (this.userData.selectedMedicines.length-1) ) {
          this.drugList = this.drugList + ', ';
        }
        if ((this.userData.selectedMedicines[j].medicine === 'Carboplatin'
        || this.userData.selectedMedicines[j].medicine === 'Oxaliplatin'
        || this.userData.selectedMedicines[j].medicine === 'Irinotecan')) {
          this.chemoHighRisk(this.userData.selectedMedicines[j].medicine,  riskLevel, this.calcRisk);
          this.userData.priorityDial = (this.userData.highestPriority == 3
          && this.userData.selectedMedicines[j].medicine === 'Oxaliplatin'
          && this.userData.riskProfile.gender == 'Female'
          &&  this.userData.riskProfile.age <51) ? 4 : this.userData.highestPriority ;
        }
        else {this.userData.priorityDial =  this.userData.highestPriority ;}
    }

    var riskRecom = this.userData.risk>43.6? "Consider adding a further antiemetic due to the patient's risk level." : "No additional antiemetic is needed."

    this.chemoRiskVsPatientRisk(emet, riskLevel, this.userData.risk);

     this.tagUserInput('risk', 'recommendation', this.calcRisk);
     this.tagUserInput('emet', 'recommendation', emet);
     this.tagUserInput('riskLevel', 'recommendation', riskLevel);
     this.tagUserInput('emetRecom', 'recommendation', emetRecom);
     this.tagUserInput('riskRecom', 'recommendation', riskRecom);


    this.userData.risk = this.calcRisk;
    this.userData.drugList = this.drugList;
  },
  methods: {
      ...mapMutations('userData', ['saveUserData']),
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";

  .recommendation {
    font-family: "Arial Narrow", Arial, sans-serif !important;
  }
    .cinv-navigation {
        margin-top: -57px;
        @media (max-width: $screen-md-max) {
            margin-top: 0;
        }
    }

    .plus-image {
        background-image: url("../images/icon-svg/plus.svg");
        background-repeat: no-repeat;
        height: 62px;
        margin-top: 10px;
        left: 50%;
        margin-left: -25px;
        position: absolute;
        width: 51px;
        z-index: 10;

        @media (max-width: $screen-md-max) {
            top: 0;
            position: relative;
            left: auto;
            margin: -31px auto;
        }
    }

    .xs-div {
        display: none;
        @media (max-width: $screen-xs-max) {
            display: block;
        }
    }

    .lg-div {
        display: block;
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }

    .dial-div {
        float: none;
        height: 157px;
        margin: 8px auto 0;
        width: 305px;

        @media (max-width: $screen-xs-max) {
            height: 144px;
            margin: 15px auto 0;
            width: 280px;
        }
    }

    .risk-dial {
        @media (max-width: $screen-xs-max) {
            height: 144px;
            width: 280px;
        }
    }

    .dial-col {
        background-color: #E5F1F7;
        padding: 17px !important;
        height: 485px;
        @media (max-width: $screen-md-max) {
            height: auto;
            padding: 30px 15px 60px !important;
        }
        @media (max-width: $screen-xs-max) {
            padding: 30px 8px 60px !important;
        }
    }
    .graph-col {
        background-color: #EBECEC;
        padding: 17px !important;
        @media (max-width: $screen-md-max) {
            height: 450px;
            padding: 54px 20px !important;
        }
        @media (max-width: $screen-sm-max) {
            height: 438px;
        }
        @media (max-width: $screen-xs-max) {
            height: auto;
            padding: 50px 8px 15px !important;
        }
    }


    .bars-div {
        float: none;
        height: 71px;
        margin: 9px 0 10px 45px;
        width: 262px;
        @media (max-width: $screen-md-max) {
            width: 50% !important;
        margin: 9px 0 10px 79px;
        }

        @media (max-width: $screen-xs-max) {
            margin: 15px auto 10px auto;
           flex: 0 0 100%;
        }
    }

    .predispose {
        font-size: 21px;
        padding: 19px 20px 19px;
        text-align: center;
        line-height: 1.29;
        @media (max-width: $screen-xs-max) {
            width: 100% !important;
            font-size: 18px;
        }
    }
    .risk-bars {
        @media (max-width: $screen-md-max) {
            margin-left: auto;
            float: right;
        }
        @media (max-width: $screen-sm-max) {
            margin-left: 30px;
        }
        @media (max-width: $screen-xs-max) {
            float: none;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .DOP {
        font-size: 25px;
        margin: 3px auto 7px auto;
        @media (max-width: $screen-xs-max) {
            font-size: 22px;
        }
    }
    .hr-lines {
        width: 396px;
        margin: 13px auto 0;
        height: 1px;
        background-color: #999;
        @media (max-width: $screen-xs-max) {
            margin: 7px 8px 0;
            width: auto;
        }
    }
    .Chemotherapy {
        font-size: 21px;
        margin: auto;
        padding: 10px 10px 0;
        text-align: center;
        width: 100%;
        @media (max-width: $screen-xs-max) {
            font-size: 18px;
        }
    }
    .emetogenic {
        font-size: 21px;
        font-weight: 700;
        margin: auto;
        padding: 0;
        text-align: center;
        width: 100%;
        @media (max-width: $screen-md-max) {
            margin-right: 10px;
        }
        @media (max-width: $screen-xs-max) {
            font-size: 18px;
        }
    }
    .min-emet {
        color: #499623;
    }
    .low-emet {
        color: #ccbf00;
    }
    .mod-emet {
        color: #e59600;
    }
    .high-emet {
        color: #ff0000;
    }
    .drugs-sec {
        font-size: 17px;
        margin: auto;
        width: 100%;
        padding: 8px 8px 5px;
        text-align: center;
        line-height: 1.24;
        @media (max-width: $screen-md-max) {
            margin-right: 10px;
        }
        @media (max-width: $screen-xs-max) {
            margin: 0 auto;
            font-size: 15px;
        }
    }
    .antiemetic-text {
        font-size: 25px;
        width: 380px;
        margin: 11px auto 11px;
        text-align: center;
        line-height: 1;
        @media (max-width: $screen-md-max) {
            font-size: 22px;
            margin: 0 auto 13px;
        }
        @media (max-width: $screen-xs-max) {
          width: auto;
        }

        .add-antiemet {
            font-size: 23px;
            line-height: 1.2;
            @media (max-width: $screen-md-max) {
                font-size: 20px;
                margin: 0 auto;
            }
        }

    }

    .high-risk-txt-only {
        width: 367px ;
        margin: -5px auto -2px;
         @media (max-width: $screen-md-max) {
            margin: 10px auto 5px;
         }
         @media (max-width: $screen-xs-max) {
            width: auto;
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
    .Risk-Profile {
        font-size: 21px;
        margin: auto;
        padding: 10px;
        width: 100%;
        text-align: center;
        @media (max-width: $screen-md-max) {
            padding: 13px 10px 10px;
           }
        @media (max-width: $screen-xs-max) {
            font-size: 18px;
            width:auto;
        }
    }
    .list {
        font-size: 18px;
        margin: 17px auto 0 62px;
        padding: 0px;
        line-height: 1.2;

        @media (max-width: $screen-md-max) {
            margin: 17px auto 0 177px;
        }
        @media (max-width: $screen-sm-max) {
            margin: 17px auto 0 89px;
        }
        @media (max-width: $screen-xs-max) {
            margin: 17px auto 0;
            font-size: 16px;
            padding-left: 10px;
        }
    }

    .risk-div {
        padding: 22px 0 0;
        width: 120px;
        @media (max-width: $screen-xs-max) {
            flex: 0 0 100%;
        }
    }

    .risk-text {
        font-family: $Primary-fontName;
        font-size: 22px;
        font-weight: 700;
        text-align: center;
        line-height: 1;
    }

    .risk-perc,
    .low-level {
        color: #499623;
    }

    .high-perc,
    .high-level {
        color: #ff0000;
    }

    .guideline-text {
        color: #4d4d4d;
        font-size: 13px;
        font-style: normal;
        line-height: 1.2;
        padding: 4px 60px;
        text-align: center;
    }

</style>


