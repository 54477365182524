<template>
  <v-row class="moreInfo" no-gutters>
    <v-col class="tool-border" cols="12">
      <v-col class="reduce-padding-all" cols="12">
        <cinv-header :page-title="pageTitle"></cinv-header>
      </v-col>
      <v-col class="page-background reduce-padding" cols="12">
        <v-row class="page-content" no-gutters>
          <v-col class="" cols="12">
            <v-row class="body-sec" no-gutters>
              <v-col class="" cols="12">
                <div class="scroll">
                  <div class="box">
                    <div class="heading"><b>About this Risk Assessment</b></div>
                    <p class="paragraph-1">
                      This tool predicts the risk of developing grade II CINV or
                      more.{{ "\xa0" }} It considers both the chemotherapy
                      emetogenicity level (based on the
                      <a
                        href="https://www.esmoopen.com/article/S2059-7029(23)01436-9/fulltext"
                        @click="tagNav('mascc')"
                        target="_blank"
                        ><u
                          >2023 MASCC/ESMO international guidelines
                          classification</u
                        ></a
                      >) and a patient’s emetogenicity risk profile.
                      {{ "\xa0" }}The CINV risk factors and outcomes data were
                      obtained from 1198 patients receiving a total of 4197
                      cycles of chemotherapy (see
                      <i><u>Dranitsaris 2017</u></i> and
                      <i><u>Scotté 2017</u></i
                      >). {{ "\xa0" }} It has undergone internal validation and
                      it has to be prospectively validated in a new sample of
                      patients. {{ "\xa0" }}According to NCI-CTC, grade II CINV
                      is defined as 3-5 emesis events (separated by at least 5
                      minutes).
                    </p>
                    <div class="references"><b>References:</b></div>
                    <p class="paragraph-2">
                      Dranitsaris G, Molassiotis A, et al. The development of a
                      prediction tool to identify cancer patients at high risk
                      for chemotherapy-induced nausea and vomiting. Ann Oncol.
                      2017; 28(6):1260-1267.
                    </p>
                    <p class="paragraph-2">
                      Herrstedt J, Clark-Snow R, Molassiotis A, et al. 2023
                      MASCC and ESMO guideline update for the prevention of
                      chemotherapy and radiotherapy-induced nausea and vomiting.
                      ESMO Open. 2023:1-18.
                    </p>
                    <p class="paragraph-2 common-text no-underline">
                      NCI Common Terminology Criteria Adverse Events (CTCAE)
                      Version 4.02 USDHHS. September, 2009.
                      <br />
                      <a
                        class=""
                        href="http://evs.nci.nih.gov/ftp1/CTCAE/Archive/CTCAE_4.02_2009-09-15_QuickReference_8.5x11.pdf"
                        target="_blank"
                        >http://evs.nci.nih.gov/ftp1/CTCAE/Archive/CTCAE_4.02_2009-09-15_QuickReference_8.5x11.pdf,
                        accessed 6.1.2016.</a
                      >
                    </p>
                    <p class="predictive">
                      Scotté F. Identifying predictive factors of
                      chemotherapy-induced nausea and vomiting (CINV): a novel
                      approach. Ann Oncol. 2017; 28(6): 1165-1167.
                    </p>
                    <div class="Medical">
                      <b><i>Medical Review Board:</i></b>
                    </div>
                    <p class="Matli">
                      <i
                        >Matti Aapro, Karin Jordan, David Warr, Eric Roeland,
                        Alex Molassiotis, Pascale Dielenseger, George
                        Dranitsaris, Lee Schwartzberg, Annie Young</i
                      >
                    </p>

                    <div class="heading-two">
                      <b
                        >About Chemotherapy Induced Nausea and Vomiting
                        (CINV)</b
                      >
                    </div>
                    <p class="complication">
                      CINV is a debilitating complication that is common in
                      patients undergoing chemotherapy. {{ "\xa0" }}If CINV is
                      poorly controlled, it can lead to dehydration, electrolyte
                      imbalances, weight loss and malnourishment, impaired
                      quality of life, and possible dose reductions or delays in
                      potentially life-saving chemotherapy.
                    </p>
                    <p class="Acute">
                      “Acute Nausea and Vomiting” is that which occurs within
                      the first 24 hours following chemotherapy administration,
                      while “Delayed Nausea and Vomiting” occurs ≥ 24 hours
                      post-chemotherapy and may continue for up to 5 days.
                    </p>
                    <p class="prophylaxis">
                      With appropriate antiemetic prophylaxis, CINV can be
                      prevented in the majority (70-80%) of patients.
                    </p>
                    <p class="health-care">
                      Delayed CINV is more difficult to control than Acute CINV
                      and tends to be underreported by patients and
                      underestimated by health care providers. Even when CINV
                      can be effectively controlled in the acute phase, it may
                      still occur in the delayed phase. Delayed CINV presents
                      more than 24 hours after chemotherapy administration, up
                      through 120 hours.
                    </p>
                    <div class="references"><b>References:</b></div>
                    <p class="paragraph-2 link-para">
                      NIH NCI. Nausea and vomiting related to cancer
                      (PDQ<sup>®</sup>) - health professional version. 2023 Jul
                      20.
                      http://www.cancer.gov/about-cancer/treatment/side-effects/nausea/
                      nausea-hp-pdq.
                    </p>
                    <p class="paragraph-2">
                      Rapoport BL, Herrstedt J, Clark Snow R, et al, et al. 2023
                      updated MASCC/ESMO consensus recommendations: prevention
                      of nausea and vomiting following multiple-day
                      chemotherapy, high-dose chemotherapy, and breakthrough
                      nausea and vomiting.Supportive Care in Cancer. 2024:32-36.
                    </p>

                    <div class="heading-three">
                      <b>Breakthrough Nausea and Vomiting</b>
                    </div>
                    <p class="Prevention">
                      Although antiemetics are most effective when used
                      prophylactically, some patients will experience
                      breakthrough CINV, depending on the emetogenicity of the
                      drugs administered. {{ "\xa0" }}The MASCC/ESMO Guidelines
                      recommend to prescribe the maximally effective antiemetics
                      as first-line therapy rather than withholding more
                      effective antiemetics for later use at the time of
                      antiemetic failure.
                    </p>
                    <div class="references2"><b>References:</b></div>
                    <p class="Molassiotis esmo-mol">
                      Herrstedt J, Clark-Snow R, Molassiotis A, et al. 2023
                      MASCC and ESMO guideline update for the prevention of
                      chemotherapy and radiotherapy-induced nausea and vomiting.
                      ESMO Open. 2023:1-18
                    </p>

                    <div class="heading-three">
                      <b
                        >Importance of Prevention (Rather than Treatment) of
                        CINV</b
                      >
                    </div>
                    <p class="Prevention">
                      Prevention of CINV in the first cycle of chemotherapy is
                      critical, as the risk for CINV substantially increases in
                      subsequest cycles if it is not prevented up front
                      <i>(Molassiotis 2016)</i>.
                    </p>
                    <p class="study">
                      A study <i>(Basch 2017)</i> confirmed the importance of
                      monitoring for CINV. {{ "\xa0" }}It showed that diligent
                      web-based monitoring of side effects such as nausea
                      improved health-related quality of life, decreased
                      emergency room visits and hospitalizations, and improved
                      survival for adult patients undergoing chemotherapy.
                    </p>
                    <div class="references2"><b>References:</b></div>
                    <p class="Molassiotis grey-txt">
                      Molassiotis A, Lee PH, Burke TA, Dicato M, Gascon P, Roila
                      F, Aapro M. Anticipatory Nausea, Risk Factors, and Its
                      Impact on Chemotherapy-Induced Nausea and Vomiting:
                      Results From the Pan European Emesis Registry Study. J
                      Pain Symptom Manage. 2016 Jun;51(6):987-93. doi:
                      10.1016/j.jpainsymman.2015.12.317. Epub 2016 Feb 16.
                      PubMed PMID: 26891606.
                    </p>
                    <br />
                    <p class="Basch-E grey-txt">
                      Basch E, Deal AM, Dueck AC, Scher HI, Kris MG, Hudis C,
                      Schrag D. Overall Survival Results of a Trial Assessing
                      Patient-Reported Outcomes for Symptom Monitoring During
                      Routine Cancer Treatment. JAMA. 2017 Jul
                      11;318(2):197-198. doi: 10.1001/jama.2017.7156. PubMed
                      PMID: 28586821.
                    </p>
                    <div class="heading-four">
                      <b>Antiemetic Guidelines and Recommendations</b>
                    </div>
                    <p class="guidelines">
                      Antiemetic guidelines (<u>MASCC/ESMO</u>) have established
                      standard emetic risk categories based on the inherent
                      emetogenicity of chemotherapy agent(s). {{ "\xa0" }}The
                      ever-evolving evidence-based guideline recommendations are
                      based on this emetogenicity and associated risk.
                    </p>
                    <br />
                    <p class="categories">
                      <b>The emetogenic risk categories include:</b>
                    </p>
                    <div class="list">
                      <p class="points">
                        • Highly Emetogenic Chemotherapy (HEC: could elicit
                        emesis in >90% of untreated patients)
                      </p>
                      <p class="points">
                        • Moderately Emetogenic Chemotherapy (MEC: 30 – 90% of
                        patients)
                      </p>
                      <p class="points">
                        • Low Emetogenic Chemotherapy (10 – 30% of patients)
                      </p>
                      <p class="points">
                        • Minimally Emetogenic Chemotherapy (&lt;10% of
                        patients)
                      </p>
                    </div>
                    <p class="pathways">
                      For patients receiving MEC or HEC, the guidelines
                      consistently recommend double, triple or quadruple
                      combinations of antiemetic agents/classes that targe
                      multiple molecular pathways involved in CINV.
                    </p>
                    <div class="heading-five"><b>Abbreviations:</b></div>
                    <p class="common-margin receptor">
                      <b>5-HT<sub class="sub-text">3</sub></b> = serotonin<sub
                        class="sub-text"
                        >3</sub
                      >
                      receptor antagonist
                    </p>
                    <p class="common-margin DEX"><b>DEX</b> = DEXAMETHASON</p>

                    <p class="common-margin NK1">
                      <b>NK<sub class="sub-text">1</sub></b> = neurokinin<sub
                        class="sub-text"
                        >1</sub
                      >
                      receptor antagonist such as APREPITANT or FOSAPREPITANT or
                      ROLAPITANT or NEPA (combination of netupitant and
                      palonosetron)
                    </p>
                    <p class="common-margin OLZ">
                      <b>OLZ</b> = OLANZAPINE (may be added particularly if
                      nausea is a concern)
                    </p>
                    <p class="common-margin DOP">
                      <b>DOP</b> = dopamine receptor antagonist
                    </p>
                    <p class="note">
                      <b>NOTE:</b>
                      If the NK receptor antagonist is not available for AC
                      chemotherapy, palonosetron is the preferred 5-HT<sub
                        class="sub-text"
                        >3</sub
                      >
                      receptor antagonist.
                    </p>
                    <!-- <p class="Roila grey-txt">
                      (Roila F, Molassiotis A, et al. 2016 MASCC and ESMO
                      guideline update for the prevention of chemotherapy-and
                      radiotherapy-induced nausea and vomiting and of nausea and
                      vomiting in advanced cancer patients. Ann Oncol.
                      2016;27(Suppl 5)V119-133.)
                    </p> -->
                    <div class="abb-rec references4"><b>References:</b></div>
                    <p class="MASCC grey-txt">MASCC/ESMO:</p>
                    <p class="Roila grey-txt">
                      Herrstedt J, Clark-Snow R, Molassiotis A, et al. 2023
                      MASCC and ESMO guideline update for the prevention of
                      chemotherapy and radiotherapy-induced nausea and vomiting.
                      ESMO Open. 2023:1-18.
                    </p>
                    <p class="Aapro grey-txt">
                      Aapro M, Gralla RJ, et al. MASCC/ESMO Antiemetic Guideline
                      2016 (with Updates in 2019).
                      https://www.mascc.org/assets/Guidelines-Tools/
                      mascc_antiemetic_guidelines_english_v.1.5SEPT29.2019.pdf
                    </p>
                    <!-- <p class="Aapro2 grey-txt">Link</p> -->

                    <!-- <p class="MASCC2 grey-txt">ASCO:</p> -->
                    <!-- <p class="Aapro2 grey-txt"> -->
                    <!-- Hekseth PJ, Kris MG, et al. Antiemetics: ASCO Guideline
                      Update. J Clin Oncology. 2020;38:2782-2709. Link
                    </p> -->

                    <!-- <p class="MASCC2 grey-txt">NCCN:</p>
                    <p class="Aapro2 grey-txt">
                      NCCN Clinical Guidelines for Patients V1 2021 (December
                      23, 2020). Link
                    </p> -->
                    <div class="heading-six">
                      <b>Importance of Patient-Related Risk Factors </b>
                    </div>
                    <p class="key">
                      Although chemotherapy emetogenicity is key to
                      understanding a patient’s potential risk for developing
                      CINV, additional patient-related risk factors can also
                      contribute to emetic risk (Dranitsaris 2017).{{
                        "\xa0"
                      }}
                      While many of these potential factors are well-known (eg,
                      younger age, history of morning/motion sickness,
                      anticipatory or prior CINV), there are limitations to the
                      studies performed with no clear consensus on all potential
                      patient-related risk factors and the extent to which they
                      can contribute to a patient’s risk.{{ "\xa0" }} Additional
                      studies are underway. Other potential risk factors for
                      developing CINV may exist (such as preexisting
                      comorbidities or types of cancer), however there is not
                      sufficient evidence to include these in this tool.
                    </p>
                    <div class="references3"><b>References:</b></div>
                    <p class="Dranitsaris grey-txt">
                      Dranitsaris G, Molassiotis A, Clemons M, Roeland E,
                      Schwartzberg L, Dielenseger P, Jordan K, Young A, Aapro M.
                      The development of a prediction tool to identify cancer
                      patients at high risk for chemotherapy-induced nausea and
                      vomiting. Ann Oncol. 2017 Jun 1;28(6):1260-1267. doi:
                      10.1093/annonc/mdx100. PubMed PMID: 28398530; PubMed
                      Central PMCID: PMC5452068.
                    </p>
                    <p class="chemopharm grey-txt">
                      Mosa ASM, et al. Patient-related risk factors for
                      chemotherapy – induced nausea and vomiting: a systematic
                      review. Front Pharm 2020;11(329):1-15.
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <cinv-navigation :nav-buttons="['previous']"></cinv-navigation>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import cinvHeader from "../components/cinvHeader.vue";
import cinvNavigation from "../components/cinvNavigation.vue";

export default {
  name: "moreInfo",
  // mixins: [taggingMixin, scrollMixin],
  components: {
    cinvHeader: cinvHeader,
    cinvNavigation: cinvNavigation,
  },

  data() {
    return {
      pageTitle: "More Information",
    };
  },

  computed: {},

  mounted: function () {
    // this.scrollToTop();
  },
  methods: {
    tagNav: function (action) {
      //         this.tagNavigation(action, 'moreInfo');
    },
  },
};
</script>


<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";

.moreInfo {
  font-family: "Arial Narrow", Arial, sans-serif !important;
  color: #000;
  .no-underline a {
    text-decoration: none;
  }
  .box {
    padding: 0px 22px 20px 24px;

    @media (max-width: $screen-md-max) {
      padding: 0px 7px 20px 0;
    }
  }

  .common-margin {
    margin-bottom: 5px !important;
  }

  .heading {
    font-size: 20px;
    color: #0078ae;
    line-height: 1;
    @media (max-width: $screen-xs-max) {
      font-size: 18px;
    }
  }
  .heading-two {
    font-size: 20px;
    color: #0078ae;
    line-height: 1;
    margin-top: 33px;
    @media (max-width: $screen-xs-max) {
      font-size: 18px;
    }
  }
  .heading-three {
    font-size: 20px;
    color: #0078ae;
    line-height: 1;
    margin-top: 33px;
    @media (max-width: $screen-xs-max) {
      font-size: 18px;
    }
  }
  .sub-text {
    font-size: 10px;
    bottom: -0.4em !important;
  }
  .heading-four {
    font-size: 20px;
    color: #0078ae;
    line-height: 1;
    margin-top: 33px;
    @media (max-width: $screen-xs-max) {
      font-size: 18px;
    }
  }
  .heading-five {
    font-size: 16px;
    margin-bottom: 6px;
    line-height: 1;
    margin-top: 20px;
  }
  .heading-six {
    font-size: 20px;
    line-height: 1;
    margin-top: 40px;
    color: #0078ae;
    @media (max-width: $screen-xs-max) {
      font-size: 18px;
    }
  }

  .paragraph-1 {
    font-size: 16px;
    line-height: 1.2;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }

  a,
  a:hover,
  a:focus,
  a:active {
    color: inherit;
  }

  .references {
    font-size: 13px;
    line-height: 1;
    color: #333;
    margin-top: 20px;
    margin-bottom: 3px;
  }

  .references2 {
    font-size: 14px;
    line-height: 1;
    color: #333;
    margin-top: 19px;
    margin-bottom: 3px;
  }
  .references3 {
    font-size: 14px;
    line-height: 1;
    color: #333;
    margin-top: 20px;
    margin-bottom: 3px;
  }

  .references4 {
    font-size: 13px;
    line-height: 1;
    color: #333;
    margin-top: 22px;
    margin-bottom: 3px;
  }

  .points {
    margin-top: 2px;
  }
  .paragraph-2 {
    line-height: 1.15 !important;
    font-size: 13px;
    margin-bottom: 15px !important;

    @media (min-width: $screen-lg-min) {
      width: 830px;
    }
  }

  .link-para {
    max-width: 810px;
  }

  .common-text {
    font-size: 14px;
    max-width: 601px;
    line-height: 1.15 !important;
  }
  .predictive {
    font-size: 14px;
  }
  .Medical {
    font-size: 14px;
    line-height: 1;
    margin-top: 19px;
  }
  .Matli {
    font-size: 14px;
  }
  .scroll {
    height: 449px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-right: 36px;
    margin-top: 33px;
    @media (max-width: $screen-md-max) {
      margin: 23px 15px 15px;
    }

    @media (max-width: $screen-sm-max) {
      margin: 23px 10px 15px;
    }
    @media (max-width: $screen-xs-max) {
      margin: 23px 7px 15px;
    }
  }
  .-webkit-scroll-thumb {
    background: grey;
    border-radius: 3px;
  }

  .complication {
    font-size: 16px;
    line-height: 1;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .Acute {
    font-size: 16px;
    line-height: 1;
    margin-top: 20px;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .prophylaxis {
    font-size: 16px;
    line-height: 1;
    margin-top: 21px;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .health-care {
    font-size: 16px;
    line-height: 1;
    margin-top: 20px;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .Prevention {
    font-size: 16px;
    line-height: 1;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .study {
    font-size: 16px;
    line-height: 1;
    margin-top: 17px;
    @media (min-width: $screen-lg-min) {
      width: 854px;
    }
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .Molassiotis {
    font-size: 13px;
    line-height: 1;
  }

  .esmo-mol {
    @media (min-width: $screen-lg-min) {
      padding-right: 20px;
    }
  }
  .Feb-16 {
    font-size: 14px;
    margin-top: 30px;
  }
  .Basch-E {
    font-size: 13px;
    @media (min-width: $screen-lg-min) {
      width: 852px;
    }
    line-height: 1;
  }
  .guidelines {
    font-size: 16px;
    line-height: 1;
    @media (min-width: $screen-lg-min) {
      width: 832px;
    }
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .categories {
    font-size: 16px;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .list {
    font-size: 16px;
    line-height: 1;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .pathways {
    font-size: 16px;
    line-height: 1;
    margin-top: 15px;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .receptor {
    font-size: 16px;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .DEX {
    font-size: 16px;
    line-height: 1;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }

  .NK1 {
    font-size: 16px;
    line-height: 1;
    @media (min-width: $screen-lg-min) {
      width: 836px;
    }
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .OLZ {
    font-size: 16px;
    line-height: 1;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .DOP {
    font-size: 16px;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }

  p {
    line-height: 1.2 !important;
  }

  .abb-rec {
    margin-top: 19px;
    margin-bottom: 6px;
  }

  .note {
    font-size: 16px;
    margin-top: 18px;
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
    }
  }
  .MASCC {
    font-size: 13px;
    margin-top: -2px;
  }
  .MASCC2 {
    font-size: 13px;
    margin-top: 0;
  }

  .grey-txt {
    color: #333;
  }
  .Roila {
    font-size: 13px;
    line-height: 1;
    @media (min-width: $screen-lg-min) {
      width: 828px;
    }
    margin-top: 0;
  }
  .Aapro {
    font-size: 13px;
    line-height: 1;
  }
  .page-background {
    padding: 0 !important;
  }

  .Aapro2 {
    font-size: 14px;
    line-height: 1;
    margin-top: 0;
  }
  .key {
    font-size: 16px;
    @media (min-width: $screen-lg-min) {
      width: 842px;
    }
  }
  .Dranitsaris {
    font-size: 13px;
    line-height: 1;
    @media (min-width: $screen-lg-min) {
      width: 858px;
    }
  }

  .chemopharm {
    font-size: 13px;
    line-height: 1;
    margin-top: 15px;
    @media (min-width: $screen-lg-min) {
      width: 832px;
    }
  }
}
</style>
