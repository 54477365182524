<template>
  <v-row class="risk" no-gutters>
    <v-col class="tool-border" cols="12">
      <v-col class="reduce-padding-all" cols="12">
        <infoPopup v-if="userData.showInfoModal"></infoPopup>
        <cinvHeader :pageTitle="pageTitle" :displayInfo="true"></cinvHeader>
      </v-col>
      <v-col class="page-background reduce-padding-all" cols="12">
        <v-row class="page-content" no-gutters>
          <v-col class="reduce-padding-all" cols="12">
            <v-row class="body-sec" no-gutters>
              <v-col cols="12">
                <div class="heading">
                  Tell us about your patient and their chemotherapy.
                </div>
              </v-col>
              <v-col cols="12" class="cont-sec">
                <v-row no-gutters>
                  <v-col lg="6" md="6" cols="12">
                    <div class="four-cinv">
                      <v-row
                        no-gutters
                        class="develop-cinv gender-row"
                        :class="{ haserror: errorGender }"
                      >
                        <v-col md="7" cols="12">
                          <v-row class="gender" :class="{'haserror' :'errorGender'}" no-gutters>
                            <v-col cols="12">
                              <div class="numbers">1.</div>
                              <div class="question-text">
                                What is the patient's gender?
                              </div>
                              <div class="valid-text">
                                <p v-if="errorGender">{{ errorGender }}</p>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col md="5" cols="12" class="common-padding">
                          <div class="select-button2">
                            <cinvSelect1
                              :options="GenderOptions"
                              v-model="selectedGender"
                              @change="handleGenderChange"
                            ></cinvSelect1>
                          </div>
                          <div class="valid-text-sm">
                            <p v-if="errorGender">{{ errorGender }}</p>
                          </div>
                        </v-col>
                      </v-row>
                      <!--QUES 2-->
                      <v-row
                        class="develop-cinv" :class="{'haserror' :'ageError'}"
                        no-gutters
                      >
                        <v-col md="7" cols="12">
                          <v-row class="age" no-gutters>
                            <div class="numbers">2.</div>
                            <div class="question-text">
                              What is the patient's age?
                            </div>
                            <div class="valid-text">
                              <div class="age-error" v-if="ageError">
                                The analysis on risk factors<br />considered
                                multiple DBs with a population<br />in the age
                                range of 28-100 years old.
                              </div>
                            </div>
                          </v-row>
                        </v-col>
                        <v-col md="5" class="common-padding">
                          <v-form ref="form">
                            <v-text-field
                              v-model.number="userData.riskProfile.age"
                              @blur="handleAge"
                              class="custom-text-field"
                              required
                            ></v-text-field>
                          </v-form>
                          <div class="valid-text-sm">
                            <div v-if="ageError">
                              The analysis on risk factors considered multiple
                              DBs with a population in the age range of 28-100
                              years old.
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <!--QUES 3-->

                       <v-row class="develop-cinv3"
                        no-gutters
                        :class="{'haserror': 'errorDevelop'}"
                      >
                        <v-col md="7" cols="12">
                          <v-row class="expect" no-gutters>
                            <div class="numbers">3.</div>
                            <div class="double-line-expect">
                              Does the patient expect to develop CINV?
                            </div>
                            <div class="valid-text">
                              <div v-if="errorDevelop">
                                Valid entry is required.
                              </div>
                            </div>
                          </v-row>
                        </v-col>
                        <v-col md="5" cols="12" class="common-padding">
                          <div class="select-button">
                            <cinvSelect1
                              :options="optionsDevelop"
                              v-model="selectedDevelop"
                              @change="handleDevelopChange()"
                            ></cinvSelect1>
                          </div>
                          <div class="valid-text-sm">
                            <div v-if="errorDevelop">
                              Valid entry is required.
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <!--QUES 4-->
                      <v-row
                        class="develop-cinv"
                        no-gutters
                        :class="{'haserror': errorSickness && selectedGender !== 'Male' }"
                      >
                        <v-col md="7" cols="12">
                          <v-row class="sickness" no-gutters>
                            <div class="numbers">4.</div>
                            <div class="double-line-sickness">
                              Did the patient have morning sickness during a
                              prior pregnancy?
                              <div class="female-patients-only">
                                (for female patients only)
                              </div>
                            </div>

                            <div class="valid-text">
                              <div
                                v-if="
                                  errorSickness && selectedGender !== 'Male'
                                "
                              >
                                Valid entry is required.
                              </div>
                            </div>
                          </v-row>
                        </v-col>
                        <v-col md="5" cols="12" class="common-padding">
                          <div class="select-button">
                            <cinvSelect1
                              :class="{
                                'opaque-disable': selectedGender == 'Male',
                              }"
                              :options="optionsSickness"
                              v-model="selectedSickness"
                              @change="handleSicknessChange()"
                            ></cinvSelect1>
                            <div
                              class="disable-div"
                              v-if="selectedGender == 'Male'"
                            ></div>
                          </div>
                          <div class="valid-text-sm">
                            <div
                              v-if="errorSickness && selectedGender !== 'Male'"
                            >
                              Valid entry is required.
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                    <div class="v1">
                      <div class="vertical-line"></div>
                    </div>
                  </v-col>

                  <v-col lg="6" md="6" cols="12">
                    <div class="four-cinv-right">
                      <!--QUES 5-->
                      <v-row
                        class="develop-cinv"
                        no-gutters
                        :class="{
                          'haserror': 'errorSleep'
                        }"
                      >
                        <v-col lg="9" md="8" cols="12">
                          <v-row class="sleep" no-gutters>
                            <div class="numbers">5.</div>
                            <div class="double-line">
                              Did the patient sleep 7 or more hours the night
                              before chemotherapy?
                            </div>
                            <div class="valid-text">
                              <div v-if="errorSleep">
                                Valid entry is required.
                              </div>
                            </div>
                          </v-row>
                        </v-col>
                        <v-col lg="3" md="4" cols="12" class="common-padding">
                          <div class="select-button">
                            <cinvSelect1
                              :options="optionsSleep"
                              v-model="selectedSleep"
                              @change="handleSleepChange()"
                            ></cinvSelect1>
                          </div>
                          <div class="valid-text-sm">
                            <div v-if="errorSleep">
                              Valid entry is required.
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <!--QUES 6-->
                      <v-row
                        class="develop-cinv6"
                        no-gutters
                        :class="{
                          'haserror': errorPrescribed && userData.chemoDays !== '1'
                        }"
                      >
                        <v-col lg="9" md="8" cols="12">
                          <v-row class="non-prescribed" no-gutters>
                            <div class="numbers">6.</div>
                            <div class="double-line-6">
                              After the previous cycle of chemotherapy (if
                              applicable), did the patient take non-prescribed
                              antiemetics at home?<span
                                class="info-button"
                                @click="showInfo"
                              ></span>
                            </div>

                            <div class="valid-text">
                              <div
                                v-if="
                                  errorPrescribed && userData.chemoDays !== '1'
                                "
                              >
                                Valid entry is required.
                              </div>
                            </div>
                          </v-row>
                        </v-col>
                        <v-col lg="3" md="4" cols="12" class="common-padding">
                          <div class="select-button">
                            <cinvSelect1
                              :class="{
                                'opaque-disable': userData.chemoDays == '1',
                              }"
                              :options="optionsPrescribed"
                              v-model="selectedPrescribed"
                              @change="handlePrescribedChange()"
                            ></cinvSelect1>
                            <div
                              class="disable-div"
                              v-if="userData.chemoDays == '1'"
                            ></div>
                          </div>
                          <div class="valid-text-sm">
                            <div
                              v-if="errorVomiting && userData.chemoDays !== '1'"
                            >
                              Valid entry is required.
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <!--QUES 7-->
                      <v-row
                        class="develop-cinv"
                        no-gutters
                        :class="{
                          'haserror': errorVomiting && userData.chemoDays !== '1'
                        }"
                      >
                        <v-col lg="9" md="8" cols="12">
                          <v-row class="vomiting" no-gutters>
                            <div class="numbers">7.</div>
                            <div class="double-line">
                              Has the patient had any nausea or a vomiting
                              episode in the prior cycle?
                            </div>
                            <div class="valid-text">
                              <div
                                v-if="
                                  errorVomiting && userData.chemoDays !== '1'
                                "
                              >
                                Valid entry is required.
                              </div>
                            </div>
                          </v-row>
                        </v-col>
                        <v-col lg="3" md="4" cols="12" class="common-padding">
                          <div class="select-button">
                            <cinvSelect1
                              :class="{
                                'opaque-disable': userData.chemoDays == '1',
                              }"
                              :options="optionsVomiting"
                              v-model="selectedVomiting"
                              @change="handleVomitingChange()"
                            ></cinvSelect1>
                            <div
                              class="disable-div"
                              v-if="userData.chemoDays == '1'"
                            ></div>
                          </div>
                          <div class="valid-text-sm">
                            <div
                              v-if="errorVomiting && userData.chemoDays !== '1'"
                            >
                              Valid entry is required.
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <!--QUES 8-->
                      <v-row class="develop-cinv" :class="{
                          'haserror': 'errorFlag'
                        }" no-gutters>
                        <v-col lg="9" md="8" cols="12">
                          <v-row class="Anthracycline" no-gutters>
                            <div class="numbers">8.</div>
                            <div class="double-line">
                              Is the chemotherapy Anthracycline or Platinum
                              based?
                            </div>
                            <div class="valid-text">
                              <div v-if="errorFlag">
                                Valid entry is required.
                              </div>
                            </div>
                          </v-row>
                        </v-col>
                        <v-col lg="3" md="4" cols="12" class="common-padding">
                          <div class="select-button">
                            <cinvSelect1
                              :class="{ 'drop-disable': isDisabled }"
                              :options="optionsAnthracycline"
                              v-model="selectedAnthracycline"
                            ></cinvSelect1>
                            <div class="disable-div" v-if="isDisabled"></div>
                          </div>
                          <div class="valid-text-sm">
                            <div v-if="errorFlag">Valid entry is required.</div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <cinvNavigation
          :navButtons="['back', 'links', 'next']"
          :toScreen="['chemoEmtoLevel', 'recommendation']"
          :btnClickHandler="handleSubmit"
        ></cinvNavigation>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import cinvHeader from "../components/cinvHeader.vue";
import cinvNavigation from "../components/cinvNavigation.vue";
import cinvSelect1 from "../components/cinvSelect1.vue";
import infoPopup from "@/components/infoPopup.vue";
import { computed, onMounted } from "vue";
import { ref, reactive } from "vue";
import { useStore } from "vuex";

export default {
  // mixins: [scrollMixin],
  setup() {
    const ageError = ref(false);
    const store = useStore();
    const pageTitle = "Patient Emetogenicity Risk Profile";
    const GenderOptions = ["Male", "Female"];
    const selectedGender = ref(null);
    const errorGender = ref(null);

    const submitted = ref(false);

    const optionsDevelop = ["Yes", "No", "N/A"];
    const selectedDevelop = ref(null);
    const errorDevelop = ref(null);

    const optionsSickness = ["Yes", "No", "N/A"];
    const selectedSickness = ref(null);
    const errorSickness = ref(null);

    const optionsSleep = ["Yes", "No", "N/A"];
    const selectedSleep = ref(null);
    const errorSleep = ref(null);

    const optionsPrescribed = ["Yes", "No", "N/A"];
    const selectedPrescribed = ref(null);
    const errorPrescribed = ref(null);

    const optionsVomiting = ["Yes", "No", "N/A"];
    const selectedVomiting = ref(null);
    const errorVomiting = ref(null);

    const optionsAnthracycline = ["Yes", "No", "N/A"];
    const selectedAnthracycline = ref(null);
    const errorAnthracycline = ref(null);

    const errorFlag = ref(false);
    const isDisabled = ref(false);
    const displayErrorMsg = ref(false);
    const nonNumeric = ref(false);

    // Define reactive object
    const ageMsgReq = reactive({
      one: false,
      two: false,
    });



    // Define a computed property
    const userData = computed(() => store.getters["userData/userData"]);

    onMounted(() => {
      setTimeout(() => {
        if ('parentIFrame' in window) {
          parentIFrame.scrollToOffset(0, 0);
        }
         window.document.getElementById('app').scrollIntoView();
      }, 50);
      loadData();
      getMedicine();

      if (userData.value.riskProfile.age == "") {
        ageError.value = false;
      } else {
        handleAge();
      }


    });

    const loadData = () => {
      selectedGender.value = userData.value.riskProfile.gender;
      console.log('Selected option:', selectedGender.value);
      selectedDevelop.value = userData.value.riskProfile.cinvDevelopmentRisk;
      selectedSickness.value = userData.value.riskProfile.morningSickness;
      selectedSleep.value = userData.value.riskProfile.sleepTime;
      selectedPrescribed.value = userData.value.riskProfile.nonPrescribedDrugs;
      selectedVomiting.value = userData.value.riskProfile.nauseaVomiting;
      selectedAnthracycline.value = userData.value.riskProfile.platinumBased;
};

    // Method to get medicine data
    const getMedicine = () => {
      if (userData.value.selectedMedicines.length > 0) isDisabled.value = true;

      let index = userData.value.selectedMedicines.findIndex(
        (x) =>
          x.medicine === "Anthracycline/cyclophosphamide combination" ||
          x.medicine === "Anthracycline" ||
          x.medicine === "Carboplatin" ||
          x.medicine === "Cisplatin" ||
          x.medicine === "Daunorubicin" ||
          x.medicine === "Doxorubicin" ||
          x.medicine === "Epirubicin" ||
          x.medicine === "Idarubicin" ||
          x.medicine === "Oxaliplatin"
      );
      if (index !== -1) {
        selectedAnthracycline.value = "Yes";
      } else {
        selectedAnthracycline.value = "No";
      }

      if (userData.value.chemoDays === "1") {
        selectedPrescribed.value = null;
        selectedVomiting.value = null;
      }
    };

    // Method to show info
    const showInfo = () => {
      userData.value.showInfoModal = true;
    };

const handleAge = (option) => {
      if (isNaN(userData.value.riskProfile.age) || userData.value.riskProfile.age<28 || userData.value.riskProfile.age>100) {
        ageError.value = true;
      } else {
        ageError.value = false;

      }
    };

    const handleGenderChange = (option) => {
      if (!selectedGender.value) {
        errorGender.value = "Valid entry is required.";
      } else {
        errorGender.value = null;
        if (selectedGender.value == "Male") {
          selectedSickness.value = "N/A";
        }
      }
    };

    const handleDevelopChange = (option) => {
      if (!selectedDevelop.value) {
        errorDevelop.value = "Valid entry is required.";
      } else {
        errorDevelop.value = null;
      }
    };

    const handleSicknessChange = (option) => {
      if (!selectedSickness.value) {
        errorSickness.value = "Valid entry is required.";
      } else {
        errorSickness.value = null;
      }
    };

    const handleSleepChange = (option) => {
      if (!selectedSleep.value) {
        errorSleep.value = "Valid entry is required.";
      } else {
        errorSleep.value = null;
      }
    };

    const handlePrescribedChange = (option) => {
      if (!selectedPrescribed.value) {
        errorPrescribed.value = "Valid entry is required.";
      } else {
        errorPrescribed.value = null;
      }
    };

    const handleVomitingChange = (option) => {
      if (!selectedVomiting.value) {
        errorVomiting.value = "Valid entry is required.";
      } else {
        errorVomiting.value = null;
      }
    };

    const handleAnthracyclineChange = (option) => {
      if (!selectedAnthracycline.value) {
        errorAnthracycline.value = "Valid entry is required.";
      } else {
        errorAnthracycline.value = null;
      }
    };


const saveData = () => {
  userData.value.riskProfile.gender = selectedGender.value;
   userData.value.riskProfile.cinvDevelopmentRisk = selectedDevelop.value;
   userData.value.riskProfile.morningSickness = selectedSickness.value;
   userData.value.riskProfile.sleepTime = selectedSleep.value;
      userData.value.riskProfile.nonPrescribedDrugs = selectedPrescribed.value;
      userData.value.riskProfile.nauseaVomiting = selectedVomiting.value ;
      userData.value.riskProfile.platinumBased = selectedAnthracycline.value;

};

const tagUserInput = (action, value) => {
    dataLayer.push({
                'event' : 'e_userdata',
                'screen' : 'Patient Emetogenicity Risk Profile',
                'DataField' : action,
                'DataValue' : value,
                });
};

    const handleSubmit = () => {
      if (
        !selectedGender.value ||
        !selectedDevelop.value ||
        (!selectedSickness.value && selectedGender.value != "Male") ||
        (!selectedPrescribed.value && userData.value.chemoDays != "1") ||
        !selectedSleep.value ||
        (!selectedVomiting.value && userData.value.chemoDays != "1") ||
        !selectedAnthracycline.value ||
        isNaN(userData.value.riskProfile.age) ||  userData.value.riskProfile.age>100

      ) {
        handleGenderChange();
        handleDevelopChange();
        handleSicknessChange();
        handleSleepChange();
        handlePrescribedChange();
        handleVomitingChange();
        handleAnthracyclineChange();
        handleAge();
        setTimeout(() => {
          console.log("in scroll to error");
            document.querySelector('.haserror').scrollIntoView();
          // scroll to error for iphones
          if ('parentIFrame' in window) {
            var elmTop = document.querySelector('.haserror').getBoundingClientRect().top;
            if (elmTop > 0) {
              parentIFrame.scrollToOffset(0, elmTop - 200);
            }
          }
        }, 50);
        return false;
      } else {
          if (userData.value.riskProfile.gender !== selectedGender.value) {
            tagUserInput('Gender', selectedGender.value)};
    tagUserInput('Age', userData.value.riskProfile.age);
     if(userData.value.riskProfile.cinvDevelopmentRisk !== selectedDevelop.value){
      tagUserInput('Expect to develop CINV', selectedDevelop.value)};
    if(userData.value.riskProfile.morningSickness !== selectedSickness.value){
      tagUserInput('Morning sickness', selectedSickness.value)};
    if(userData.value.riskProfile.sleepTime !== selectedSleep.value){
      tagUserInput('7 or more hours sleep', selectedSleep.value)};
    if(userData.value.riskProfile.nonPrescribedDrugs !== selectedPrescribed.value){
      tagUserInput('Non-prescribed antiemetics', selectedPrescribed.value)};
    if(userData.value.riskProfile.nauseaVomiting !== selectedVomiting.value){
      tagUserInput('Nausea or a vomiting episode in the prior cycle', selectedVomiting.value)};
    if(userData.value.riskProfile.platinumBased !== selectedAnthracycline.value){
      tagUserInput('Anthracycline or Platinum based chemotherapy', selectedAnthracycline.value)};
        saveData();
        return true;
      }
    };
    return {
      showInfo,
      submitted,
      pageTitle,
      handleAge,
      ageError,
      GenderOptions,
      selectedGender,
      errorGender,
      handleGenderChange,
      handleSubmit,
      userData,
      errorFlag,
      isDisabled,
      displayErrorMsg,
      nonNumeric,
      ageMsgReq,
      optionsDevelop,
      selectedDevelop,
      errorDevelop,
      handleDevelopChange,
      optionsSickness,
      selectedSickness,
      errorSickness,
      handleSicknessChange,
      optionsSleep,
      selectedSleep,
      errorSleep,
      handleSleepChange,
      optionsPrescribed,
      selectedPrescribed,
      errorPrescribed,
      handleVomitingChange,
      optionsVomiting,
      selectedVomiting,
      errorVomiting,
      handlePrescribedChange,
      optionsAnthracycline,
      selectedAnthracycline,
      errorAnthracycline,
      handleAnthracyclineChange,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/_variables.scss";

.risk {
  font-family: "Arial Narrow", Arial, sans-serif !important;
  text-transform: none !important;
}

  .v-input__control {
    border: 0;
    outline: 0;
    background-color: transparent;
    color: transparent;
  }

  .v-field__field {
    color: #000;
  }
  .v-input--density-default .v-field__input {
    row-gap: 5px;
    padding: 0 10px 0;
    text-align: center;
  }
  .custom-text-field {
    max-width: 65px;
    opacity: 1;
    border: 1px solid #0078ae;
    background-color: #ebecec;
    border-radius: 4px;
    max-height: 49px;
    padding: 0 !important;
  }


  .custom-text-field {
    .v-text-field {
      input {
        background-color: #ebecec !important;
        border-style: none;
        height: 56px;
        outline: none !important;
        border: 0 !important;
        color: #000;
      }
    }
  }

  .custom-text-field .v-messages__message {
    margin-left: -256px !important;
    font-size: 16px !important;
    color: red;
  }
  .custom-text-field:focus,
  .custom-text-field:active {
    max-width: 65px;
    opacity: 1;
    border: 1px solid #0078ae;
    background-color: #ebecec;
    max-height: 49px;

    border-radius: 4px;
  }
  .heading {
    font-size: 24px;
    color: white;
    text-align: center;
    background-color: #499623;
    height: 53px;
    padding: 14px;
    line-height: 1;

    @media (max-width: $screen-sm-max) {
      font-size: 19px;
      padding: 15px 5px;
    }

    @media (max-width: $screen-xs-max) {
      font-size: 19px;
      padding: 15px 5px;
      height: auto;
    }
  }

  .cont-sec {
    border-top: 0;
    font-size: 20px;

    font-weight: 400;
    height: auto; //467px;
    padding: 0 30px 0 26px !important;
    margin-top: 28px;
    @media (max-width: $screen-md-max) {
      font-size: 17px;
      font-weight: 400;
      height: auto;
      padding: 0 15px !important;
    }
  }
  .numbers {
    display: inline-block;
    float: left;
    color: #000;
    font-weight: 500;
  }

  .develop-cinv {
    width: auto;
    height: 100px;
    @media (max-width: $screen-md-max) {
      width: auto;
    }
    @media (max-width: $screen-sm-max) {
      height: auto;
    }
    @media (max-width: $screen-xs-max) {
      // padding: 20px 8px;
    }
  }
  .develop-cinv3 {
    width: auto;
    height: 133px;
    @media (max-width: $screen-sm-max) {
      height: auto;
    }
  }


  .develop-cinv6 {
    width: auto;
    height: 147px;
    @media (max-width: $screen-md-max) {
      height: 135px;
    }
    @media (max-width: $screen-sm-max) {
      height: auto;
    }
  }
  .select-container {
    width: 130px;
    background-color: transparent;
  }
  .select-button {
    width: 114px;
    font-size: 23px;

    @media (max-width: $screen-xs-max) {

    }
  }

  .select-button2 {
    width: 130px;
    font-size: 23px;
  }

  .selected-item {
    font-size: 20px !important;
  }

  .gender-row {
    .selected {
      width: 82px !important;
    }
  }
  .gender {
    margin-top: 14px;
    line-height: 1;

    .question-text {
      margin-left: 18px;
    }

    @media (max-width: $screen-sm-max) {
      margin-top: 0;
    }
  }

  .common-padding {
    padding-left: 8px !important;
    @media (max-width: $screen-sm-max) {
      padding-left: 16px !important;
      margin-top: 10px;
    }
  }
  .age {
    line-height: 1;
    margin-top: 12px;
    @media (max-width: $screen-md-max) {
      height: 50px;
    }

    @media (max-width: $screen-sm-max) {
      height: auto;
      margin-top: 0;
    }

    .question-text {
        margin-left: 4px;
    }
  }

  .risk .expect {
    line-height: 1;
    margin-top: 3px;
}

  .grey-box {
    background-color: #0078ae;
    border-radius: 0 4px 4px 0;
    height: 48px;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
  }

  .custom-select .selected:after {
    position: absolute;
    top: 22px;
    right: 16px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-color: #fff transparent transparent transparent;
  }
  .custom-select .items {
    border-radius: 0px 0px 4px 4px;
    overflow: hidden;
    border: 1px solid #0078ae;
    position: absolute;
    background-color: #fff;
    left: 0;
    margin-top: -1px;
    right: 0;
    z-index: 1;
    width: 100%;
  }

  .custom-select .items div {
    color: black;
    cursor: pointer;
    user-select: none;
  }
  .custom-select .items div:hover {
    border: none;
    background-color: #0078ae;
    color: #fff;
  }
  .valid-text {
    position: relative;
    color: red;
    font-size: 16px;
    padding-left: 18px;
    line-height: 1.1;
    padding-top: 8px;
    @media (max-width: $screen-sm-max) {
      display: none;
    }
  }

.vertical-line {
  border: none;
  height: 397px;
  width: 1px;
  margin-top: 0;
  color: #0078ae;
  background-color: #0078ae;
  @media (max-width: $screen-md-max) {
    margin: 0 0 0 -1px;
  }
}
.info {
  cursor: pointer;
  float: right;

  height: 19px;
  margin: 0px 72px 0 -20px;
  width: 19px;
}
.double-line-expect {
  width: 220px;
  margin: -2px 0 0 5px;
  line-height: 1.1;
  @media (max-width: $screen-md-max) {
    margin: -1px 0 0 4px;
    width: 161px;
  }

  @media (max-width: $screen-sm-max) {
    margin: -1px 0 0 4px;
    width: auto;
  }
}

.sickness {
    display: inline-block;
    line-height: 1;
}

.double-line-sickness {
  width: 220px;
  margin: -2px 0 0 18px;
  line-height: 1.1;
  text-indent: -1px;
  @media (max-width: $screen-md-max) {
    margin: -1px 0 0 15px;
    width: 200px;
  }
  @media (max-width: $screen-sm-max) {
    margin: -1px 0 0 14px;
    width: auto;
    text-align: left;
  }
}

.sleep {
  display: inline-block;
  height: 72px;
  float: left;
  @media (max-width: $screen-md-max) {
    height: 50px;
  }
  @media (max-width: $screen-sm-max) {
    height: auto;
  }
  @media (max-width: $screen-xs-max) {
    height: auto;
  }
}
.double-line {
  margin: 3px 38px 0 17px;
  line-height: 1.2;
  @media (max-width: $screen-md-max) {
    margin: 1px 0 0 15px !important;
    width: 196px;
  }
  @media (max-width: $screen-sm-max) {
    width: auto;
  }
}
.input-field {
  width: 68px;
  height: 48px;
  background-color: #ebecec;
  border: 1px solid #0078ae;
  border-radius: 4px;
  text-align: center;
}

.double-line-6 {
  line-height: 1.1;
  margin: 0 0 0 18px;
  width: 254px;
  text-indent: 0px;
  @media (max-width: $screen-md-max) {
    margin: -2px 0 0 16px;
    width: 183px;
  }
  @media (max-width: $screen-sm-max) {
    margin: -1px 0 0 16px;
    width: auto;
  }
}
.non-prescribed {
  display: inline-block;
  line-height: 1;
  @media (max-width: $screen-md-max) {
    height: 75px;
  }
  @media (max-width: $screen-sm-max) {
    height: auto;
  }
}
.vomiting {
  display: inline-block;
  float: left;
  height: 70px;
  @media (max-width: $screen-md-max) {
    height: 50px;
  }
  @media (max-width: $screen-sm-max) {
    height: auto;
  }
}
.Anthracycline {
  display: inline-block;
  float: left;
  margin-top: 3px;
  width: 322px;
  @media (max-width: $screen-md-max) {
    width: auto;

    height: 50px;
  }
  @media (max-width: $screen-sm-max) {
    height: auto;
  }
}
.v1 {
  display: inline-block;
  width: 25px;
  height: auto;
  vertical-align: top;
  @media (max-width: $screen-md-max) {
    width: auto;
  }
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}
.four-cinv {
  display: inline-block;
  min-width: 425px;
  max-width: 425px;
  margin-top: 3px;
  @media (max-width: $screen-md-max) {
    min-width: 343px;
    max-width: 343px;
    padding-right: 10px;
    margin-top: 0;
  }
  @media (max-width: $screen-sm-max) {
    min-width: 100%;
    max-width: 100%;
  }
}

.four-cinv-right {
  margin-left: 16px;
  margin-top: 3px;
  @media (max-width: $screen-md-max) {
    margin-left: 8px;
    margin-top: 0;
  }
  @media (max-width: $screen-sm-max) {
    margin-left: 0;
  }

  .select-button {
    margin-left: -7px;
    @media (max-width: $screen-sm-max) {
      margin-left: 0;
    }
  }
}
.female-patients-only {
  font-size: 14px;
  display: inline;
}

.age-error {
  width: 252px;
  margin-top: -3px;
}

.opaque-disable {
  opacity: 0.6;
  background-color: grey;
}

.disable-div {
  height: 53px;
  width: 120px;
  margin: -50px auto 0;
  position: absolute;

}

.drop-disable {
  background-color: grey;
}
.custom-select .selected {
  width: 67px;
  background-color: #ebecec;
  border-radius: 4px;
  border: 1px solid #0078ae;
  color: black;
  font-size: 20px;
  padding-left: 0;
  height: 48px;
  border-top-right-radius: 0;
  cursor: pointer;
  border-bottom-right-radius: 0;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 !important;
}

.valid-text-sm {
  display: none;
  @media (max-width: $screen-sm-max) {
    color: red;
    font-size: 16px;
    padding-left: 1px;
    line-height: 1.1;
    padding-top: 6px;
    display: block;
    height: 54px;
  }
  @media (max-width: $screen-xs-max) {
    height: 68px;
  }
}

.info-button {
  background-image: url("../images/icon-svg/questionaire-info.svg");
  background-repeat: no-repeat;
  cursor: pointer;
  height: 19px;
  width: 19px;
  display: inline-block;
  margin: 2px 0 -3px 6px;
  @media (max-width: $screen-sm-max) {
    margin: 2px 0 -5px 6px;
  }
}
.p-holder {
  color: #000 !important;
}

</style>
