import { createRouter,createWebHashHistory} from 'vue-router'

import Home from '@/components/Home.vue';
import ChemoEmtoLevel from '@/components/chemoEmtoLevel.vue';
import RiskProfile from '@/components/riskProfile.vue';
import Recommendation from '@/components/recommendation.vue';
import PatientsReport from '@/components/patientsReport.vue';
import Email from '@/components/email.vue';
import MoreInfo from '@/components/moreInfo.vue';


export default createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: '/', name: 'home', component: Home },
    { path: '/chemoEmtoLevel', name: 'chemoEmtoLevel', component: ChemoEmtoLevel },
    { path: '/riskProfile', name: 'riskProfile', component: RiskProfile },
    { path: '/recommendation', name: 'recommendation', component: Recommendation },
    { path: '/patientsReport', name: 'patientsReport', component: PatientsReport },
    { path: '/email', name: 'email', component: Email },
    { path: '/moreInfo', name: 'moreInfo', component: MoreInfo },
]
})
