<template>
  <div class="custom-dropdown">
    <div class="selected-item" @click="toggleDropdown">
    <span class="p-holder" v-if="!modelValue">{{ placeholder }}</span>
      <span>{{ modelValue }}</span>
      <div class="grey-box"></div>
    </div>
    <ul class="dropdown-menu" v-if="isOpen">
      <li v-for="option in options" :key="option" @click="selectOption(option)">
        {{ option }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref } from 'vue';

// Define props and emits
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  modelValue: { // Define modelValue prop
    type: String,
    required: true,
  },
  placeholder: { // Define placeholder prop
    type: String,
    default: '...', // Default placeholder text
  }
});



const emits = defineEmits(['update:modelValue', 'change']);

const isOpen = ref(false);
const selectedOption = ref(null);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const selectOption = (option) => {
  selectedOption.value = option;
  isOpen.value = false;
  emits('update:modelValue', option); // Emit 'update:modelValue' event with selected option
  emits('change', option); // Emit 'change' event with selected option
};




</script>


<style scoped>
.custom-dropdown {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 48px;
  line-height: 47px;
  padding: 0;
  border: none;
  border-radius: 4px;
  background-color: transparent;
}

.custom-dropdown:focus {
  border-color: transparent;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0 rgb(0 123 255 / 25%);
  box-shadow: 0 0 0 0 rgb(0 123 255 / 25%);
}

.selected-item {
height: 49px;
  background-color: #ebecec;
  border: 1px solid #0078ae;
  border-radius: 4px !important;
  color: #000;
  font-size: 20px;
  padding-right: 47px;
  padding-left: 2px;
  cursor: pointer;
  margin: 0 !important;
  text-align: center;
}


.dropdown-menu li {
  color: #000;
  padding: 0 0 0 9px;
  height: 36px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Arial", Helvetica, ns-serif !important;
  line-height: 37px;
  user-select: none;
}


.grey-box {
  background-color: #0078ae;
  border-radius: 0 4px 4px 0;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
}

.custom-dropdown .selected-item.opened {
  border: 1px solid #e6e7e8;
  border-radius: 4px 4px 0px 0px;
}

.custom-dropdown .selected-item:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 16px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-dropdown .dropdown-menu {
  color: #0078ae;
  border-radius: 0px 0px 4px 4px;
  overflow: hidden;
  border-right: 1px solid #0078ae;
  border-left: 1px solid #0078ae;
  border-top: 1px solid #0078ae;
  border-bottom: 1px solid #0078ae;
  position: absolute;
  background-color: #fff;
  left: 0;
  margin-top: -1px;
  right: 0;
  z-index: 1;
}

.custom-dropdown .dropdown-menu .do-not-display div {
  display: none;
}

.custom-dropdown.empty-display div {
}

.custom-dropdown .dropdown-menu .inner-select {
  color: #000;
  padding: 0 0 0 9px;
  height: 36px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Arial", Helvetica, ns-serif !important;
  line-height: 37px;
  user-select: none;
}

.custom-dropdown .dropdown-menu div:hover {
  background-color: #0078ae;
  color: #fff;
  border: none;
}

.selectHide {
  display: none;
}

.custom-dropdown.grey-text .selected-item {
  color: #787878 !important;
}

.p-holder {
  color: #787878 !important;
}
</style>
