<template>
  <v-row class="risk-bars" no-gutters>
    <v-col cols="12">
      <svg version="1.1" id="barGraph" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 262 71" style="enable-background:new 0 0 262 71;" xml:space="preserve">

      <g id="bar1">
        <path id="outline_1a" style="fill:#fff;stroke:none;stroke-width:0.5293;stroke-miterlimit:10;" d="M24.71,69.54H2.66V51.13c0-2.21,1.79-4,4-4h14.06c2.21,0,4,1.79,4,4V69.54z"/>
        <g id="fill_1">
          <defs>
            <path id="CLIP_1" d="M24.71,69.54H2.66V51.13c0-2.21,1.79-4,4-4h14.06c2.21,0,4,1.79,4,4V69.54z"/>
          </defs>
          <clipPath id="CPath_1">
            <use xlink:href="#CLIP_1"  style="overflow:visible;"/>
          </clipPath>
          <rect v-if="risk>=10" id="b1_10" x="2.66" y="47.13" style="clip-path:url(#CPath_1);fill:#499623;stroke:#499623;" width="22.06" height="2.24"/>

          <rect v-if="risk>=9" id="b1_9" x="2.66" y="49.38" style="clip-path:url(#CPath_1);fill:#499623;stroke:#499623;" width="22.06" height="2.24"/>

          <rect v-if="risk>=8" id="b1_8" x="2.66" y="51.62" style="clip-path:url(#CPath_1);fill:#499623;stroke:#499623;" width="22.06" height="2.24"/>

          <rect v-if="risk>=7" id="b1_7" x="2.66" y="53.86" style="clip-path:url(#CPath_1);fill:#499623;stroke:#499623;" width="22.06" height="2.24"/>

          <rect v-if="risk>=6" id="b1_6" x="2.66" y="56.1" style="clip-path:url(#CPath_1);fill:#499623;stroke:#499623;" width="22.06" height="2.24"/>

          <rect v-if="risk>=5" id="b1_5" x="2.66" y="58.34" style="clip-path:url(#CPath_1);fill:#499623;stroke:#499623;" width="22.06" height="2.24"/>

          <rect v-if="risk>=4" id="b1_4" x="2.66" y="60.58" style="clip-path:url(#CPath_1);fill:#499623;stroke:#499623;" width="22.06" height="2.24"/>

          <rect v-if="risk>=3" id="b1_3" x="2.66" y="62.82" style="clip-path:url(#CPath_1);fill:#499623;stroke:#499623;" width="22.06" height="2.24"/>

          <rect v-if="risk>=2" id="b1_2" x="2.66" y="65.06" style="clip-path:url(#CPath_1);fill:#499623;stroke:#499623;" width="22.06" height="2.24"/>

          <rect v-if="risk>=1" id="b1_1" x="2.66" y="67.3" style="clip-path:url(#CPath_1);fill:#499623;stroke:#499623;" width="22.06" height="2.24"/>
        </g>
        <path id="outline_1" style="fill:none;stroke:#333333;stroke-width:0.5293;stroke-miterlimit:10;" d="M24.71,69.54H2.66V51.13c0-2.21,1.79-4,4-4h14.06c2.21,0,4,1.79,4,4V69.54z"/>
      </g>

      <g id="bar2">
         <path id="outline_2a" style="fill:#fff;stroke:none;stroke-width:0.5;stroke-miterlimit:10;" d="M50.85,69.54h-22V46.79c0-2.21,1.79-4,4-4h14c2.21,0,4,1.79,4,4V69.54z"/>
        <g id="fill_2">
          <defs>
            <path id="CLIP_2" d="M50.85,69.54h-22V46.79c0-2.21,1.79-4,4-4h14c2.21,0,4,1.79,4,4V69.54z"/>
          </defs>
          <clipPath id="CPath_2">
            <use xlink:href="#CLIP_2"  style="overflow:visible;"/>
          </clipPath>
          <rect v-if="risk>=20" id="b2_10" x="28.85" y="42.79" style="clip-path:url(#CPath_2);fill:#499623;stroke:#499623;" width="22" height="2.67"/>

          <rect v-if="risk>=14" id="b2_4" x="28.85" y="58.84" style="clip-path:url(#CPath_2);fill:#499623;stroke:#499623;" width="22" height="2.67"/>

          <rect v-if="risk>=19" id="b2_9" x="28.85" y="45.47" style="clip-path:url(#CPath_2);fill:#499623;stroke:#499623;" width="22" height="2.67"/>

          <rect v-if="risk>=11" id="b2_1" x="28.85" y="66.87" style="clip-path:url(#CPath_2);fill:#499623;stroke:#499623;" width="22" height="2.67"/>

          <rect v-if="risk>=18" id="b2_8" x="28.85" y="48.14" style="clip-path:url(#CPath_2);fill:#499623;stroke:#499623;" width="22" height="2.67"/>

          <rect v-if="risk>=17" id="b2_7" x="28.85" y="50.82" style="clip-path:url(#CPath_2);fill:#499623;stroke:#499623;" width="22" height="2.67"/>

          <rect v-if="risk>=16" id="b2_6" x="28.85" y="53.49" style="clip-path:url(#CPath_2);fill:#499623;stroke:#499623;" width="22" height="2.67"/>

          <rect v-if="risk>=15" id="b2_5" x="28.85" y="56.17" style="clip-path:url(#CPath_2);fill:#499623;stroke:#499623;" width="22" height="2.67"/>

          <rect v-if="risk>=12" id="b2_2" x="28.85" y="64.19" style="clip-path:url(#CPath_2);fill:#499623;stroke:#499623;" width="22" height="2.67"/>

          <rect v-if="risk>=13" id="b2_3" x="28.85" y="61.52" style="clip-path:url(#CPath_2);fill:#499623;stroke:#499623;" width="22" height="2.67"/>
        </g>
        <path id="outline_2" style="fill:none;stroke:#333333;stroke-width:0.5;stroke-miterlimit:10;" d="M50.85,69.54h-22V46.79c0-2.21,1.79-4,4-4h14c2.21,0,4,1.79,4,4V69.54z"/>
      </g>

      <g id="bar3">
        <path id="outline_3a" style="fill:#fff;stroke:none;stroke-width:0.5;stroke-miterlimit:10;" d="M77.02,69.54h-22V42.13c0-2.21,1.79-4,4-4h14c2.21,0,4,1.79,4,4V69.54z"/>
        <g id="fill_3">
          <defs>
            <path id="CLIP_3" d="M77.02,69.46h-22V42.05c0-2.21,1.79-4,4-4h14
          c2.21,0,4,1.79,4,4V69.46z"/>
          </defs>
          <clipPath id="CPath_3">
            <use xlink:href="#CLIP_3"  style="overflow:visible;"/>
          </clipPath>
          <rect v-if="risk>=30" id="b3_10" x="55.02" y="38.05" style="clip-path:url(#CPath_3);fill:#499623;stroke:#499623;" width="22" height="3.14"/>

          <rect v-if="risk>=29" id="b3_9" x="55.02" y="41.19" style="clip-path:url(#CPath_3);fill:#499623;stroke:#499623;" width="22" height="3.14"/>

          <rect v-if="risk>=28" id="b3_8" x="55.02" y="44.33" style="clip-path:url(#CPath_3);fill:#499623;stroke:#499623;" width="22" height="3.14"/>

          <rect v-if="risk>=27" id="b3_7" x="55.02" y="47.47" style="clip-path:url(#CPath_3);fill:#499623;stroke:#499623;" width="22" height="3.14"/>

          <rect v-if="risk>=26" id="b3_6" x="55.02" y="50.61" style="clip-path:url(#CPath_3);fill:#499623;stroke:#499623;" width="22" height="3.14"/>

          <rect v-if="risk>=25" id="b3_5" x="55.02" y="53.76" style="clip-path:url(#CPath_3);fill:#499623;stroke:#499623;" width="22" height="3.14"/>

          <rect v-if="risk>=24" id="b3_4" x="55.02" y="56.9" style="clip-path:url(#CPath_3);fill:#499623;stroke:#499623;" width="22" height="3.14"/>

          <rect v-if="risk>=23" id="b3_3" x="55.02" y="60.04" style="clip-path:url(#CPath_3);fill:#499623;stroke:#499623;" width="22" height="3.14"/>

          <rect v-if="risk>=22" id="b3_2" x="55.02" y="63.18" style="clip-path:url(#CPath_3);fill:#499623;stroke:#499623;" width="22" height="3.14"/>

          <rect v-if="risk>=21" id="b3_1" x="55.02" y="66.32" style="clip-path:url(#CPath_3);fill:#499623;stroke:#499623;" width="22" height="3.14"/>
        </g>

        <path id="outline_3" style="fill:none;stroke:#333333;stroke-width:0.5;stroke-miterlimit:10;" d="M77.02,69.54h-22V42.13c0-2.21,1.79-4,4-4h14c2.21,0,4,1.79,4,4V69.54z"/>
      </g>

      <g id="bar4">
        <path id="outline_4a" style="fill:#fff;stroke:none;stroke-width:0.5;stroke-miterlimit:10;"
        d="M103.17,69.54H81.2V36.73c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
        <g id="fill_4">
          <defs>
            <path id="CLIP_4" d="M103.17,69.54H81.2V36.73c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
          </defs>
          <clipPath id="CPath_4">
            <use xlink:href="#CLIP_4"  style="overflow:visible;"/>
          </clipPath>
          <rect v-if="risk>=40" id="b4_10" x="81.2" y="32.73" style="clip-path:url(#CPath_4);fill:#499623;stroke:#499623;" width="21.97" height="3.68"/>

          <rect v-if="risk>=39" id="b4_9" x="81.2" y="36.41" style="clip-path:url(#CPath_4);fill:#499623;stroke:#499623;" width="21.97" height="3.68"/>

          <rect v-if="risk>=38" id="b4_8" x="81.2" y="40.09" style="clip-path:url(#CPath_4);fill:#499623;stroke:#499623;" width="21.97" height="3.68"/>

          <rect v-if="risk>=37" id="b4_7" x="81.2" y="43.77" style="clip-path:url(#CPath_4);fill:#499623;stroke:#499623;" width="21.97" height="3.68"/>

          <rect v-if="risk>=36" id="b4_6" x="81.2" y="47.45" style="clip-path:url(#CPath_4);fill:#499623;stroke:#499623;" width="21.97" height="3.68"/>

          <rect v-if="risk>=35" id="b4_5" x="81.2" y="51.13" style="clip-path:url(#CPath_4);fill:#499623;stroke:#499623;" width="21.97" height="3.68"/>

          <rect v-if="risk>=34" id="b4_4" x="81.2" y="54.82" style="clip-path:url(#CPath_4);fill:#499623;stroke:#499623;" width="21.97" height="3.68"/>

          <rect v-if="risk>=33" id="b4_3" x="81.2" y="58.5" style="clip-path:url(#CPath_4);fill:#499623;stroke:#499623;" width="21.97" height="3.68"/>

          <rect v-if="risk>=32" id="b4_2" x="81.2" y="62.18" style="clip-path:url(#CPath_4);fill:#499623;stroke:#499623;" width="21.97" height="3.68"/>

          <rect v-if="risk>=31" id="b4_1" x="81.2" y="65.86" style="clip-path:url(#CPath_4);fill:#499623;stroke:#499623;" width="21.97" height="3.68"/>
        </g>

        <path id="outline_4" style="fill:none;stroke:#333333;stroke-width:0.5;stroke-miterlimit:10;"
        d="M103.17,69.54H81.2V36.73c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
      </g>

      <g id="bar5">
        <path id="outline_5a" style="fill:#fff;stroke:none;stroke-width:0.5;stroke-miterlimit:10;"
        d="M129.34,69.54h-21.97V30.6c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
        <g id="fill2_5">
          <defs>
            <path id="CLIP_5" d="M129.34,69.54h-21.97V30.6c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
          </defs>
          <clipPath id="CPath_5">
            <use xlink:href="#CLIP_5"  style="overflow:visible;"/>
          </clipPath>

          <rect v-if="risk>=50" id="b5_10" x="107.37" y="26.6" style="clip-path:url(#CPath_5);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.29"/>

          <rect v-if="risk>=49" id="b5_9" x="107.37" y="30.89" style="clip-path:url(#CPath_5);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.29"/>

          <rect v-if="risk>=48" id="b5_8" x="107.37" y="35.19" style="clip-path:url(#CPath_5);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.29"/>

          <rect v-if="risk>=47" id="b5_7" x="107.37" y="39.48" style="clip-path:url(#CPath_5);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.29"/>

          <rect v-if="risk>=46" id="b5_6" x="107.37" y="43.77" style="clip-path:url(#CPath_5);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.29"/>

          <rect v-if="risk>=45" id="b5_5" x="107.37" y="48.07" style="clip-path:url(#CPath_5);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.29"/>
        </g>

        <g id="fill1">
          <rect v-if="risk>=44" id="b5_4" x="107.37" y="52.36" style="fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.29"/>

          <rect v-if="risk>43.6" id="b5_3" x="107.37" y="56.66" style="fill:#FF0000;stroke:#FF0000;" width="21.97" height="1.72"/>
          <rect v-if="risk>=43" id="b5_3" x="107.37" y="58.38" style="fill:#499623;stroke:#499623;" width="21.97" height="2.57"/>

          <rect v-if="risk>=42" id="b5_2" x="107.37" y="60.95" style="fill:#499623;stroke:#499623;" width="21.97" height="4.29"/>

          <rect v-if="risk>=41" id="b5_1" x="107.37" y="65.25" style="fill:#499623;stroke:#499623;" width="21.97" height="4.29"/>
        </g>
        <path id="outline_5" style="fill:none;stroke:#333333;stroke-width:0.5;stroke-miterlimit:10;"
        d="M129.34,69.54h-21.97V30.6c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
      </g>

      <g id="bar6">
        <path id="outline_6" style="fill:#fff;stroke:none;stroke-width:0.5;stroke-miterlimit:10;" d="
      M155.51,69.54h-21.97V25.49c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
      <g id="fill_6">
      <defs>
      <path id="SVGID_00000018929620225480073060000004238548877068327843_" d="M155.51,69.54h-21.97V25.49c0-2.21,1.79-4,4-4h13.97
      c2.21,0,4,1.79,4,4V69.54z"/>
      </defs>
      <clipPath id="SVGID_00000062900401189906772910000015157417510608671395_">
      <use xlink:href="#SVGID_00000018929620225480073060000004238548877068327843_"  style="overflow:visible;"/>
      </clipPath>

      <rect v-if="risk>=60" id="_x31_0_00000067947791578085031280000006371054747153760644_" x="133.54" y="21.49" style="clip-path:url(#SVGID_00000062900401189906772910000015157417510608671395_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.81"/>

      <rect v-if="risk>=59" id="_x39__00000182522754826226634560000016135705269226354613_" x="133.54" y="26.3" style="clip-path:url(#SVGID_00000062900401189906772910000015157417510608671395_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.81"/>

      <rect v-if="risk>=58" id="_x38__00000005989414298508933270000004855957312750094508_" x="133.54" y="31.1" style="clip-path:url(#SVGID_00000062900401189906772910000015157417510608671395_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.81"/>

      <rect v-if="risk>=57" id="_x37__00000085946584200010390030000002174439867907088024_" x="133.54" y="35.91" style="clip-path:url(#SVGID_00000062900401189906772910000015157417510608671395_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.81"/>

      <rect v-if="risk>=56" id="_x36__00000122706339581186568080000012898984443449865345_" x="133.54" y="40.71" style="clip-path:url(#SVGID_00000062900401189906772910000015157417510608671395_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.81"/>

      <rect v-if="risk>=55" id="_x35__00000113337977022093309870000005252396458523177874_" x="133.54" y="45.52" style="clip-path:url(#SVGID_00000062900401189906772910000015157417510608671395_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.81"/>

      <rect v-if="risk>=54" id="_x34__00000154409336483139435290000005048879529091385758_" x="133.54" y="50.32" style="clip-path:url(#SVGID_00000062900401189906772910000015157417510608671395_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.81"/>

      <rect v-if="risk>=53" id="_x33__00000088841862267099520790000012619983237123246990_" x="133.54" y="55.13" style="clip-path:url(#SVGID_00000062900401189906772910000015157417510608671395_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.81"/>

      <rect v-if="risk>=52" id="_x32__00000110465367573314784710000001446560540607691197_" x="133.54" y="59.93" style="clip-path:url(#SVGID_00000062900401189906772910000015157417510608671395_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.81"/>

      <rect v-if="risk>=51" id="_x31__00000090976372307537203910000007479808526002149277_" x="133.54" y="64.74" style="clip-path:url(#SVGID_00000062900401189906772910000015157417510608671395_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="4.81"/>
      </g>

      <path id="outline_6" style="fill:none;stroke:#333333;stroke-width:0.5;stroke-miterlimit:10;" d="
      M155.51,69.54h-21.97V25.49c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
      </g>

      <g id="bar7">
        <path id="outline_7a" style="fill:#fff;stroke:none;stroke-width:0.5;stroke-miterlimit:10;" d="
      M181.67,69.54h-21.97V20.38c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
      <g id="fill_00000176016840104800436550000002826145076648096157_">
      <defs>
      <path id="SVGID_00000165939873898929754250000009078557266660433288_" d="M181.67,69.54h-21.97V20.38c0-2.21,1.79-4,4-4h13.97
      c2.21,0,4,1.79,4,4V69.54z"/>
      </defs>
      <clipPath id="SVGID_00000121261506722071473370000010332249193400522940_">
      <use xlink:href="#SVGID_00000165939873898929754250000009078557266660433288_"  style="overflow:visible;"/>
      </clipPath>

      <rect v-if="risk>=70" id="_x31_0_00000009552254928625221220000000230897653598702526_" x="159.71" y="16.38" style="clip-path:url(#SVGID_00000121261506722071473370000010332249193400522940_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.32"/>

      <rect v-if="risk>=69" id="_x39__00000147201687512516557810000013917824561729990273_" x="159.71" y="21.7" style="clip-path:url(#SVGID_00000121261506722071473370000010332249193400522940_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.32"/>

      <rect v-if="risk>=68" id="_x38__00000106143453483276479040000009157081801237626292_" x="159.71" y="27.02" style="clip-path:url(#SVGID_00000121261506722071473370000010332249193400522940_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.32"/>

      <rect v-if="risk>=67" id="_x37__00000101808310943776736820000000494803909874834361_" x="159.71" y="32.33" style="clip-path:url(#SVGID_00000121261506722071473370000010332249193400522940_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.32"/>

      <rect v-if="risk>=66" id="_x36__00000080207729307683134510000004786351762301485486_" x="159.71" y="37.65" style="clip-path:url(#SVGID_00000121261506722071473370000010332249193400522940_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.32"/>

      <rect v-if="risk>=65" id="_x35__00000117671564763978974720000004266181507413477032_" x="159.71" y="42.96" style="clip-path:url(#SVGID_00000121261506722071473370000010332249193400522940_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.32"/>

      <rect v-if="risk>=64" id="_x34__00000157278063285093461930000000939116888833900682_" x="159.71" y="48.28" style="clip-path:url(#SVGID_00000121261506722071473370000010332249193400522940_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.32"/>

      <rect v-if="risk>=63" id="_x33__00000141446066681353838240000010106181537275056043_" x="159.71" y="53.6" style="clip-path:url(#SVGID_00000121261506722071473370000010332249193400522940_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.32"/>

      <rect v-if="risk>=62" id="_x32__00000058572029563332625430000011967936555244637583_" x="159.71" y="58.91" style="clip-path:url(#SVGID_00000121261506722071473370000010332249193400522940_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.32"/>

      <rect v-if="risk>=61" id="_x31__00000103257412039924977260000008149121316759471257_" x="159.71" y="64.23" style="clip-path:url(#SVGID_00000121261506722071473370000010332249193400522940_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.32"/>
      </g>

      <path id="outline_00000140712031223144650660000018309699643960017567_" style="fill:none;stroke:#333333;stroke-width:0.5;stroke-miterlimit:10;" d="
      M181.67,69.54h-21.97V20.38c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
      </g>

      <g id="bar8">
        <path id="outline_8a" style="fill:#fff;stroke:none;stroke-width:0.5;stroke-miterlimit:10;" d="
      M207.84,69.54h-21.97V15.28c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
      <g id="fill_00000109009238008762744060000002427399996494033563_">
      <defs>
      <path id="SVGID_00000124147268739746260210000012986600491848977597_" d="M207.84,69.54h-21.97V15.28c0-2.21,1.79-4,4-4h13.97
      c2.21,0,4,1.79,4,4V69.54z"/>
      </defs>
      <clipPath id="SVGID_00000067932100209238055760000014902966868463813555_">
      <use xlink:href="#SVGID_00000124147268739746260210000012986600491848977597_"  style="overflow:visible;"/>
      </clipPath>

      <rect v-if="risk>=80" id="_x31_0_00000173847003442892375220000012118544578964069822_" x="185.88" y="11.28" style="clip-path:url(#SVGID_00000067932100209238055760000014902966868463813555_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.83"/>

      <rect v-if="risk>=79" id="_x39__00000126289075562824076020000018196031186771688356_" x="185.88" y="17.1" style="clip-path:url(#SVGID_00000067932100209238055760000014902966868463813555_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.83"/>

      <rect v-if="risk>=78" id="_x38__00000158028095218896952730000009218023964893123728_" x="185.88" y="22.93" style="clip-path:url(#SVGID_00000067932100209238055760000014902966868463813555_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.83"/>

      <rect v-if="risk>=77" id="_x37__00000146477188806876605080000016801645995412791210_" x="185.88" y="28.76" style="clip-path:url(#SVGID_00000067932100209238055760000014902966868463813555_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.83"/>

      <rect v-if="risk>=76" id="_x36__00000006698704111661988160000011728401166195484304_" x="185.88" y="34.58" style="clip-path:url(#SVGID_00000067932100209238055760000014902966868463813555_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.83"/>

      <rect v-if="risk>=75" id="_x35__00000054261068295555989640000002113894686111513778_" x="185.88" y="40.41" style="clip-path:url(#SVGID_00000067932100209238055760000014902966868463813555_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.83"/>

      <rect v-if="risk>=74" id="_x34__00000088119728922033908190000005581053614678459796_" x="185.88" y="46.24" style="clip-path:url(#SVGID_00000067932100209238055760000014902966868463813555_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.83"/>

      <rect v-if="risk>=73" id="_x33__00000029030342712067099850000005529312954510843819_" x="185.88" y="52.06" style="clip-path:url(#SVGID_00000067932100209238055760000014902966868463813555_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.83"/>

      <rect v-if="risk>=72" id="_x32__00000045584220349296472850000015214801011750484367_" x="185.88" y="57.89" style="clip-path:url(#SVGID_00000067932100209238055760000014902966868463813555_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.83"/>

      <rect v-if="risk>=71" id="_x31__00000088133620880535345670000008362526054975485874_" x="185.88" y="63.72" style="clip-path:url(#SVGID_00000067932100209238055760000014902966868463813555_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="5.83"/>
      </g>

      <path id="outline_8" style="fill:none;stroke:#333333;stroke-width:0.5;stroke-miterlimit:10;" d="
      M207.84,69.54h-21.97V15.28c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
      </g>

      <g id="bar9">
        <path id="outline_9a" style="fill:#fff;stroke:none;stroke-width:0.5;stroke-miterlimit:10;" d="
      M234.01,69.54h-21.97V11.42c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
      <g id="fill_00000106111409151688137930000017653429313825178545_">
      <defs>
      <path id="SVGID_00000127023990594611468850000006726514824768562329_" d="M234.01,69.54h-21.97V11.42c0-2.21,1.79-4,4-4h13.97
      c2.21,0,4,1.79,4,4V69.54z"/>
      </defs>
      <clipPath id="SVGID_00000072978997137326936600000004997895245217591226_">
      <use xlink:href="#SVGID_00000127023990594611468850000006726514824768562329_"  style="overflow:visible;"/>
      </clipPath>

      <rect v-if="risk>=90" id="_x31_0_00000042730556142789333460000008108415328388825499_" x="212.04" y="7.42" style="clip-path:url(#SVGID_00000072978997137326936600000004997895245217591226_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.21"/>

      <rect v-if="risk>=89" id="_x39__00000052796025311508397170000017418495185653454228_" x="212.04" y="13.63" style="clip-path:url(#SVGID_00000072978997137326936600000004997895245217591226_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.21"/>

      <rect v-if="risk>=88" id="_x38__00000150068699222465609240000015165366654122816400_" x="212.04" y="19.84" style="clip-path:url(#SVGID_00000072978997137326936600000004997895245217591226_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.21"/>

      <rect v-if="risk>=87" id="_x37__00000105425958899047946190000014470700210183111340_" x="212.04" y="26.06" style="clip-path:url(#SVGID_00000072978997137326936600000004997895245217591226_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.21"/>

      <rect v-if="risk>=86" id="_x36__00000016795871481919432190000017029355474844681346_" x="212.04" y="32.27" style="clip-path:url(#SVGID_00000072978997137326936600000004997895245217591226_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.21"/>

      <rect v-if="risk>=85" id="_x35__00000059293915438606183610000011406718945531778751_" x="212.04" y="38.48" style="clip-path:url(#SVGID_00000072978997137326936600000004997895245217591226_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.21"/>

      <rect v-if="risk>=84" id="_x34__00000079483989513233435370000009529618849509600649_" x="212.04" y="44.69" style="clip-path:url(#SVGID_00000072978997137326936600000004997895245217591226_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.21"/>

      <rect v-if="risk>=83" id="_x33__00000065039441109903558640000016557396648449109914_" x="212.04" y="50.91" style="clip-path:url(#SVGID_00000072978997137326936600000004997895245217591226_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.21"/>

      <rect v-if="risk>=82" id="_x32__00000010998540195824830580000009722573161903769474_" x="212.04" y="57.12" style="clip-path:url(#SVGID_00000072978997137326936600000004997895245217591226_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.21"/>

      <rect v-if="risk>=81" id="_x31__00000000198889749815876270000002318227743769265583_" x="212.04" y="63.33" style="clip-path:url(#SVGID_00000072978997137326936600000004997895245217591226_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.21"/>
      </g>
      <path id="outline_9" style="fill:none;stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;" d="
      M234.01,69.54h-21.97V11.42c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
      </g>

      <g id="bar10">
        <path id="outline_10a" style="fill:#fff;stroke:none;stroke-width:0.5293;stroke-miterlimit:10;" d="M260.18,69.54h-21.97V6.33c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
        <g id="fill_00000157276587264971425730000003193338491435386506_">
        <defs>
        <path id="SVGID_00000165931319664299532350000013618607388054472374_" d="M260.18,69.54h-21.97V6.33c0-2.21,1.79-4,4-4h13.97
        c2.21,0,4,1.79,4,4V69.54z"/>
        </defs>
        <clipPath id="SVGID_00000094590204978843965630000001962070605210563717_">
        <use xlink:href="#SVGID_00000165931319664299532350000013618607388054472374_"  style="overflow:visible;"/>
        </clipPath>

        <rect v-if="risk>=100" id="b10_1" x="238.21" y="2.33" style="clip-path:url(#SVGID_00000094590204978843965630000001962070605210563717_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.72"/>

        <rect v-if="risk>=99" id="b10_2" x="238.21" y="9.05" style="clip-path:url(#SVGID_00000094590204978843965630000001962070605210563717_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.72"/>

        <rect v-if="risk>=98" id="b10_3" x="238.21" y="15.77" style="clip-path:url(#SVGID_00000094590204978843965630000001962070605210563717_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.72"/>

        <rect v-if="risk>=97" id="b10_4" x="238.21" y="22.49" style="clip-path:url(#SVGID_00000094590204978843965630000001962070605210563717_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.72"/>

        <rect v-if="risk>=96" id="b10_5" x="238.21" y="29.21" style="clip-path:url(#SVGID_00000094590204978843965630000001962070605210563717_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.72"/>

        <rect v-if="risk>=95" id="b10_6" x="238.21" y="35.94" style="clip-path:url(#SVGID_00000094590204978843965630000001962070605210563717_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.72"/>

        <rect v-if="risk>=94" id="b10_7" x="238.21" y="42.66" style="clip-path:url(#SVGID_00000094590204978843965630000001962070605210563717_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.72"/>

        <rect v-if="risk>=93" id="b10_8" x="238.21" y="49.38" style="clip-path:url(#SVGID_00000094590204978843965630000001962070605210563717_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.72"/>

        <rect v-if="risk>=92" id="b10_9" x="238.21" y="56.1" style="clip-path:url(#SVGID_00000094590204978843965630000001962070605210563717_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.72"/>

        <rect v-if="risk>=91" id="b10_10" x="238.21" y="62.82" style="clip-path:url(#SVGID_00000094590204978843965630000001962070605210563717_);fill:#FF0000;stroke:#FF0000;" width="21.97" height="6.72"/>
        </g>
        <path id="outline_10" style="fill:none;stroke:#333333;stroke-width:0.5;stroke-miterlimit:10;" d="
        M260.18,69.54h-21.97V6.33c0-2.21,1.79-4,4-4h13.97c2.21,0,4,1.79,4,4V69.54z"/>
      </g>

      </svg>

    </v-col>
  </v-row>
</template>

<script>
// import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "riskBars",
  mixins: [],
  components: {  },

 data() {
    return {

    };
  },

   props: {
      risk: {
          type: Number,
          default: 0
      },
  },
  computed: {
    // ...mapGetters('userData', ['userData']),
  },

  mounted: function () {
    // this.markSectionComplete({section:'cancer'});
  },

  methods: {

    show: function(buttonsArray, button) {
        return !(buttonsArray.indexOf(button) == -1);
    },

  },

};
</script>


<style lang="scss" scoped>

.risk-bars {
  height: 71px;
  width: 262px;
}

</style>
