<template>
  <v-row class="email" no-gutters>
    <v-col class="tool-border" cols="12">
      <v-col class="reduce-padding-all email-modal" cols="12">
        <email-sent-popup v-if="userData.isEmailSent"></email-sent-popup>
        <cinv-header :pageTitle="pageTitle"></cinv-header>
      </v-col>
      <v-col class="page-background reduce-padding-all" cols="12">
        <v-row class="page-content" no-gutters>
          <v-col class="reduce-padding" cols="12">
            <v-row class="body-sec" no-gutters>
              <div class="rightsection">
                <div class="field-text">
                  <b
                    >Complete the field below to have your CINV Risk Assessment
                    summary emailed.</b
                  >
                </div>
                <div class="summary-text">
                  As a reminder, the assessment summary may contain sensitive
                  health information about your condition. Click “Submit” to
                  continue or “Back” to return to your summary.
                </div>
                <div class="box_picture"></div>
                <div class="text-email" :class="{'haserror' :'submitted && !isValid'}"><b>Email Address:</b></div>

                <div class="input-feilds">
                  <v-row no-gutters>
                    <v-col cols="12" lg="6" class="reduce-padding">
                      <div class="field-size">
                        <input
                          class="common-field form-control"
                          id="email"
                          v-model.trim="email"
                          type="email"
                          :placeholder="placeholderEmail"
                          required
                          @blur="validateEmail"
                        />
                      </div>
                    </v-col>
                    <v-col cols="12" lg="6" class="reduce-padding">
                      <div v-if="submitted && !isValid" class="valid-text">
                        Valid entry is required.
                      </div>
                    </v-col>
                  </v-row>
                </div>

                <v-row class="" no-gutters>
                  <v-col class="reduce-padding" cols="12">
                    <div class="button-row">
                      <v-btn
                        class="submit-button"
                        @click.prevent="handleSubmit($event, email, userData)"
                      >
                        <b>SUBMIT</b>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-row class="" no-gutters>
                <v-col class="reduce-padding" cols="12">
                  <p class="note-text">
                    Please Note: We will not use the email address you have
                    provided here for any purpose other than to send this email.
                  </p>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
        </v-row>
        <cinv-navigation :navButtons="['previous']"></cinv-navigation>
      </v-col>
    </v-col>
  </v-row>
</template>



<script>
/* eslint-disable */
import taggingMixin from "../mixins/taggingMixin";
import scrollMixin from "../mixins/scrollMixin";
import cinvHeader from "../components/cinvHeader.vue";
import cinvNavigation from "../components/cinvNavigation.vue";
import { mapGetters } from "vuex";
import emailSentPopup from "@/components/emailSentPopup.vue";

import LZString from "lz-string";
export default {
  name: "email",
  mixins: [scrollMixin, taggingMixin],
  components: {
    cinvHeader: cinvHeader,
    cinvNavigation: cinvNavigation,
    emailSentPopup: emailSentPopup,
  },

  data() {
    return {
      placeholderEmail: "Type Here...",
      pageTitle: "Email Your Patient's Summary",
      email: "",
      submitted: false,
      isValid: true,
    };
  },

  computed: {
    ...mapGetters("userData", ["userData"]),
  },

  mounted: function () {
    this.scrollToTop();
    this.userData.isEmailSent = false;
  },
  methods: {
    validateEmail() {
      // regular expression to validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(this.email)) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
      return;
    },
    handleSubmit(e, email, userData) {
      this.submitted = true;
      this.validateEmail();
      if (!this.isValid) {
        return false;
      } else {
        const data = LZString.compressToEncodedURIComponent(
          JSON.stringify(userData)
        );
        const serviceUrl = import.meta.env.VITE_API_URL;
        const apiKey = import.meta.env.VITE_API_KEY;
        const url = window.location.href;
        const parts = url.split("://");
        const schema = parts[0];
        const uri = parts[1].replace(/#.*$/, "").replace(/\/[^\/]*$/, "");
        const appUrl = `${schema}://${uri}/index.html`;
        const emailUrl = `${schema}://${uri}/share.html.tpl`;
        var reportUrl = `${schema}://${uri}/report.html?u=${data}`;
        console.log(reportUrl);

        const renderConfig = {
          url: reportUrl,
          paper_size: {
            width: 8.5,
            height: 11,
          },
          margin: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          print_background: true,
        };

        const requestOpts = {
          method: "POST",
          headers: { "Content-Type": "application/json", "X-App-ID": apiKey },
          body: JSON.stringify({
            to: email,
            subject: "CINV Risk Assessment Summary Report PDF",
            template: {
              url: emailUrl,
              params: {
                Name: "",
                URL: appUrl,
              },
            },
            render: renderConfig,
          }),
        };

        fetch(`${serviceUrl}/v1/email/render`, requestOpts)
          .then((response) => {
            console.log("in response ", response);
             this.tagNavigation("submit", 'email');
             this.userData.isEmailSent = true;
          })
          .catch((e) => {
            // TODO display error on page.
            console.log("error", e);
            this.scrollToError();
          });
      }
    },
  },
};
</script>


<style lang="scss" >
@import "../assets/styles/_variables.scss";
.email {
  font-family: "Arial Narrow", Arial, sans-serif !important;
}

input::placeholder {
  color: #000 !important;
  font-size: 22px;
  opacity: 1;
}

.page-content {
  height: 508px;
  @media (max-width: $screen-md-max) {
    height: auto;
  }
}

.v-field--error:not(.v-field--disabled) .v-field__outline {
  color: transparent !important;
}
.custom-email,
.custom-email:focus,
.custom-email:active {
  max-width: 410px;
  border: 1px solid #000;
  background-color: #fff;
  max-height: 61px;
  margin-left: 65px;
  border-radius: 7px;
  padding: 3px;

  @media (max-width: $screen-md-max) {
    margin-left: 22px;
  }

  @media (max-width: $screen-sm-max) {
    margin-left: 10px;
  }

  @media (max-width: $screen-xs-max) {
    max-width: inherit;
    width: 95%;
  }
}
.custom-email {
  input {
    background-color: #fff !important;
    border-style: none;
    height: 56px;
    outline: none !important;
    border: 0 !important;
  }
}
.submit-button {
  width: 136px;
  height: 52px !important;
  background-color: #0078ae;
  color: #ffffff;
  margin: 50px 0px 0px 66px;
  border-radius: 6px;
  font-size: 26px;
  padding: 8px;
  border: 0;
  letter-spacing: 0px;

  @media (max-width: $screen-md-max) {
    margin: 33px 0px 0px 25px;
  }

  @media (max-width: $screen-sm-max) {
    margin: 0 auto;
  }
}

.button-row {
  @media (max-width: $screen-sm-max) {
    margin: 46px auto 43px;
    width: 136px;
  }
}

input[type="email"],
.form-control {
  height: 61px;
  width: 410px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid black;
  margin: 0px 5px 5px 65px;
  font-size: 22px;
  padding: 10px 15px;
  outline: 0 !important;
  box-shadow: none !important;
  color: #000;
  display: inline-block;
  position: relative;

  @media (max-width: $screen-md-max) {
    width: 410px;
    margin: 0px 5px 5px 25px;
  }
  @media (max-width: $screen-sm-max) {
    width: 96%;
    margin: 0 0 0 10px;
     font-size: 16px;
  }
  @media (max-width: $screen-xs-max) {
    margin: 0 0 0 7px;
  }
}

.field-text {
  font-size: 22px;
  text-align: center;
  margin-top: 23px;
  color: #0078ae;

  @media (max-width: $screen-sm-max) {
    margin: 0 auto;
    padding: 25px 10px 7px;
    line-height: 22px;
    @media (max-width: $screen-xs-max) {
      font-size: 19px;
    }
  }
}
.summary-text {
  font-size: 22px;
  text-align: center;
  padding: 0px 48px 18px;
  line-height: 1.2;

  @media (max-width: $screen-md-max) {
    padding: 0px 21px 20px;
  }

  @media (max-width: $screen-sm-max) {
    font-size: 19px;
    text-align: center;
    padding: 0px 10px 15px;
    line-height: 1.1;
  }
  @media (max-width: $screen-xs-max) {
    font-size: 17px;
  }
}
.text-email {
  color: #0078ae;
  font-size: 22px;
  padding: 31px 15px 13px 65px;
  @media (max-width: $screen-md-max) {
    padding: 15px 15px 15px 24px;
  }

  @media (max-width: $screen-sm-max) {
    padding: 15px 15px 5px 10px;
    font-size: 19px;
  }
  @media (max-width: $screen-xs-max) {
    padding: 15px 0 5px 7px;
  }
}

.checkbox-div {
  @media (max-width: $screen-sm-max) {
    width: 100%;
  }
}

.box_picture {
  width: 440px;
  height: 270px;
  background-image: url("../images/icons/box_picture.png");
  margin: 9px auto 20px;
  position: absolute;
  background-repeat: no-repeat;
  left: 37%;
  background-repeat: no-repeat;

  @media (max-width: $screen-md-max) {
    width: 400px;
    height: 270px;
    background-image: url("../images/icons/box_picture.png");
    margin: auto auto auto 37%;
    background-repeat: no-repeat;
    left: -4%;
    position: absolute;
    @media (max-width: $screen-sm-max) {
      display: none;
    }
  }
}
.note-text {
  font-size: 18px;
  text-align: left;
  padding: 0 0 0 65px;
  line-height: 1;
  margin: 56px 0 0;
  font-style: italic;
  @media (max-width: $screen-md-max) {
    line-height: 18px;
    padding: 22px;
  }
  @media (max-width: $screen-sm-max) {
    margin: 0 0 0;

    padding: 0 10px 14px;
  }
  @media (max-width: $screen-xs-max) {
    padding: 0 7px 14px;
    font-size: 16px;
  }
}

.valid-text {
  font-size: 17px;
  position: absolute;
  display: inline-block;
  color: red;
  padding: 18px 0px 0px 10px;
  @media (max-width: $screen-md-max) {
    font-size: 17px;
    padding: 18px 0px 0px 5px;
  }
  @media (max-width: $screen-sm-max) {
    display: block;
    padding: 7px 0px 0px 10px;
  }
  @media (max-width: $screen-xs-max) {
    display: block;
    padding: 7px 0px 0px 7px;
  }
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  width: 960px;
  top: 50%;
  left: 50%;
  margin: 0 auto !important;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  //height: 100%;
  background-color: rgba(255, 255, 255, 0.61); //#e6e6e6a1;
  display: table;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  height: 100%;

  @media (max-width: $screen-md-max) {
    width: 720px;
    margin: 0 auto !important; // transform: translate(-50%, -50%);
  }
  @media (max-width: $screen-sm-max) {
    width: 540px;
  }

  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;

  @media (max-width: $screen-md-max) {
    display: block;
    vertical-align: middle;
    margin-top: 234px;
  }
}

.modal-wrapper-info2 {
  display: block;
  margin-top: 167px;
  @media (max-width: $screen-md-max) {
    margin-top: 185px;
  }

  @media (max-width: $screen-sm-max) {
    margin-top: 165px;
  }
  @media (max-width: $screen-xs-max) {
    margin-left: 7px;
    margin-right: 7px;
  }
}

.heading-main-modal {
  color: #0078ae;
  font-family: arial;
  font-size: 27px;
  text-align: center;
  font-weight: bold;
  line-height: 32px;
  width: 100%;

  @media (max-width: $screen-xs-max) {
    font-size: 21px;
    line-height: 1.2;
  }
}

.modal-wrapper-info {
  display: block;
  margin-top: 102px;
  @media (max-width: $screen-sm-max) {
    margin-top: 68px;
  }
}

.continue-btn {
  background-color: #0078ae;
  width: 176px;
  height: 53px;
  text-align: center;
  font-family: arial;
  color: #fff;
  font-size: 22px;
  margin: 30px auto 0 !important;
  border-radius: 6px;
  font-weight: bold;
  padding: 10px 5px;
  cursor: pointer;
}





::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000 !important;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 1;

  color: #000 !important;
}
</style>
